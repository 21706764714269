const Progressbar = ({ currentQuestion }) => {
  return (
    <ul className="step-progress-bar ps-0">
      <li
        className={`stepBullet ${
          currentQuestion === 1 ? 'active' : currentQuestion > 1 ? 'done' : null
        }`}
      ></li>

      <li
        className={`stepBullet ${
          currentQuestion === 2 ? 'active' : currentQuestion > 2 ? 'done' : null
        }`}
      ></li>
      <li
        className={`stepBullet ${
          currentQuestion === 3 ? 'active' : currentQuestion > 3 ? 'done' : null
        }`}
      ></li>
      <li
        className={`stepBullet ${
          currentQuestion === 4 ? 'active' : currentQuestion > 4 ? 'done' : null
        }`}
      ></li>
    </ul>
  );
};

export default Progressbar;
