import { motion } from 'framer-motion';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { fadeInOut, containerVariants, child } from 'utils/animation.js';
import Calculating from '../components/Calculating';
import { RWebShare } from 'react-web-share';
import React from 'react';
import { importAll } from '../utils/importAll';

//import quizProducts from 'data/apiProducts.json';

const ShowResults = ({ showIntro, filterProducts, startQuiz, t, quizProducts }) => {
  const images = importAll(
    require.context('../assets/images/kam-pa-kam/icons', false, /\.(png|jpe?g|svg)$/)
  );

  const onShare = () => {
    const title = 'Pametni vodič: Kam pa kam po Kranjski Gori';
    const url = 'https://kam-pa-kam.escapebox.si/';
    const text =
      'Reši interaktivni kviz in se odpravi na izlet, ki ti ga predlaga naš spletni vodič.';

    window.plausible('social_share');

    if (navigator.share) {
      navigator
        .share({ title: `${title}`, url: `${url}`, text: `${text}` })
        .then(() => {
          //console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      window.open(`https://www.facebook.com/sharer.php?u=${url.trim()}&quote=${text}`, '_blank');
    }
  }; //onShare ends here

  const startAgain = () => {
    startQuiz();
    window.plausible('play_again');
  };

  const destAdrenalin = [
    t('Športno plezanje'),
    t('Jadralno padalstvo'),
    t('Vetrovnik planica'),
    t('Zipline planica'),
    t('Ferata Dobršnik'),
    t('Ferata Mojstrana'),
    t('Ferata Hvadnik'),
    t('Mojstrovka'),
  ];

  const destRomantic = [
    t('Naravni rezervat Zelenci'),
    t('Martuljški slapovi'),
    t('Dolina Vrata'),
    t('Slemenova špica'),
    t('Grančišče'),
    t('Kolesarska pot Radovna'),
    t('Kolesarska pot Vrata'),
    t('Park miru'),
    t('Rajže po potem Triglavskih pravljic'),
  ];

  const destKosilo = [
    t('Dolina Tamar'),
    t('Dolina Krnica'),
    t('Ruska kapelica na Vršiču'),
    t('Escape muzej Kajžnkova hiša'),
    t('Poletno sankanje'),
    t('Daljinska kolesarska pot D2'),
    t('Prostor doživetij – Pobeg v bivak'),
    t('Triglavski izziv'),
    t('Kekčeva dežela'),
  ];

  const destPsi = [
    t('Jezero Jasna'),
    t('Slap Peričnik'),
    t('Dolina Krma'),
    t('Tromeja'),
    t('Srednji Vrh'),
    t('Dovška baba'),
    t('Vitranc'),
    t('Juliana Trail'),
    t('Pot Triglavske Bistrice'),
  ];

  const shuffleAdrenalin = getRandomItem(destAdrenalin);
  const shuffleRomantic = getRandomItem(destRomantic);
  const shuffleKosilo = getRandomItem(destKosilo);
  const shufflePsi = getRandomItem(destPsi);

  const filteredAdrenalin = quizProducts.filter((item) => {
    console.log('ADRENALIN', item.productName, shuffleAdrenalin);
    return item.productName === shuffleAdrenalin;
  });

  const filteredRomantic = quizProducts.filter((item) => {
    console.log('ROMANTIC', item.productName, shuffleRomantic);
    return item.productName === shuffleRomantic;
  });

  const filteredKosilo = quizProducts.filter((item) => {
    console.log('KOSILO', item.productName, shuffleKosilo);
    return item.productName === shuffleKosilo;
  });

  const filteredPsi = quizProducts.filter((item) => {
    console.log('PSI', item.productName, shufflePsi);
    return item.productName === shufflePsi;
  });

  console.log(shuffleAdrenalin);

  return (
    <>
      {showIntro ? (
        <motion.div
          variants={fadeInOut}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={`wrapper results h-100 w-100 p-2 d-flex justify-content-center  align-items-sm-center bg-transparent`}
        >
          <div className="d-flex align-items-center justify-content-center h-100">
            <Calculating />
          </div>
        </motion.div>
      ) : (
        <motion.div
          variants={fadeInOut}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={`wrapper results h-100 w-100 p-2 d-flex justify-content-center  align-items-sm-center bg-transparent`}
        >
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="inner-wrapper inner-wrapper__wide position-relative text-center p-1 "
          >
            <h1 className="mt-6 mb-4 mb-md-5">{t('Idealna destinacija za vaš izlet je ...')}</h1>
            <div>
              {filterProducts()
                .sort((a, b) => {
                  if (b.score - a.score === 0) {
                    return 0.5 - Math.random();
                  }
                  return b.score - a.score;
                })
                .filter((item, index) => index === 0)
                .map((item, index) => {
                  const {
                    idealno,
                    psi,
                    insta,
                    restavracija,
                    zahtevnost,
                    kje_poteka,
                    romanticna,
                    invalidi,
                    otroci,
                  } = item.productInfo;
                  return (
                    <motion.div
                      className="row g-5 mb-5 text-center text-sm-start d-flex align-items-center"
                      key={index}
                      variants={child}
                    >
                      <div className="col-sm-6 order-2 order-md-1">
                        <h2 className="mt-0  mb-3">{item.productName}</h2>
                        <p className="lead">{item.productDesc}</p>

                        <h4 className="my-4">{t('Zakaj na to destinacijo?')}</h4>

                        <table class="table location-info">
                          <tbody>
                            <tr>
                              <th scope="row">
                                {t('Idealno za?')}
                                <img
                                  style={{ paddingTop: '4px' }}
                                  src={images[`icon-info.svg`]}
                                  id="idealno"
                                />
                              </th>
                              <td className="idealno">
                                <strong>
                                  {idealno instanceof Array ? (
                                    idealno.map((item) => {
                                      return item === 'sprostiti-se' ? (
                                        <span>{t('Za sprostitev')}</span>
                                      ) : item === 'sportno-aktivni' ? (
                                        <span>{t('Športno aktivnost')}</span>
                                      ) : item === 'nekaj-novega' ? (
                                        <span>{t('Spoznati nekaj novega')}</span>
                                      ) : item === 'kulturna-dediscina' ? (
                                        <span>{t('Spoznavanje kulturne dediščine')}</span>
                                      ) : null;
                                    })
                                  ) : idealno === 'sprostiti-se' ? (
                                    <span>{t('Za sprostitev')}</span>
                                  ) : idealno === 'sportno-aktivni' ? (
                                    <span>{t('Športno aktivnost')}</span>
                                  ) : idealno === 'nekaj-novega' ? (
                                    <span>{t('Spoznati nekaj novega')}</span>
                                  ) : idealno === 'kulturna-dediscina' ? (
                                    <span>{t('Spoznavanje kulturne dediščine')}</span>
                                  ) : null}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">
                                {t('Primerno za?')}
                                <img
                                  style={{ paddingTop: '4px' }}
                                  src={images[`icon-info.svg`]}
                                  id="primerno"
                                />
                              </th>
                              <td>
                                <div className="icons">
                                  {invalidi.map((item) => {
                                    return <img src={images[`icon-${item}.svg`]} id={item} />;
                                  })}

                                  {otroci.map((item) => {
                                    return <img src={images[`icon-${item}.svg`]} id={item} />;
                                  })}

                                  {romanticna && (
                                    <img
                                      src={images[`icon-za-zaljubljence-da.svg`]}
                                      id="zaljubljence"
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">
                                {t('Prijazno kužkom?')}
                                <img
                                  style={{ paddingTop: '4px' }}
                                  src={images[`icon-info.svg`]}
                                  id="kuzki"
                                />
                              </th>
                              <td colspan="2">
                                {' '}
                                <div class="progress">
                                  <div
                                    className="progress-bar bg-green rounded-2 text-start ps-2"
                                    style={{ width: `${convertToPercent(psi, 3)}%` }}
                                    role="progressbar"
                                    ariaValuenow={convertToPercent(psi, 3)}
                                    ariaValuemin="0"
                                    ariaValuemax="100"
                                  >
                                    <div className="progress-text">
                                      {psi === 0 ? (
                                        t('zelo primerno')
                                      ) : psi === 1 ? (
                                        t('primerno')
                                      ) : psi === 2 ? (
                                        t('manj primerno')
                                      ) : (
                                        <span style={{ color: '#3D8C35' }}>{t('neprimerno')}</span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">
                                {t('Gostilna v bližini?')}
                                <img
                                  style={{ paddingTop: '4px' }}
                                  src={images[`icon-info.svg`]}
                                  id="gostilna"
                                />
                              </th>
                              <td colspan="2">
                                {' '}
                                <div class="progress">
                                  <div
                                    className="progress-bar bg-green rounded-2 text-start ps-2"
                                    style={{ width: `${convertToPercent(restavracija, 2)}%` }}
                                    role="progressbar"
                                    ariaValuenow={convertToPercent(restavracija, 2)}
                                    ariaValuemin="0"
                                    ariaValuemax="100"
                                  >
                                    <div className="progress-text">
                                      {restavracija === 0 ? (
                                        t('na lokaciji')
                                      ) : restavracija === 1 ? (
                                        t('na lokaciji')
                                      ) : (
                                        <span style={{ color: '#3D8C35' }}>
                                          {t('ni v bližini')}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">
                                {t('Fotogenična lokacija?')}
                                <img
                                  style={{ paddingTop: '4px' }}
                                  src={images[`icon-info.svg`]}
                                  id="fotogencina"
                                />
                              </th>
                              <td colspan="2">
                                {' '}
                                <div class="progress">
                                  <div
                                    className="progress-bar bg-green rounded-2 text-start ps-2"
                                    style={{ width: `${convertToPercent(insta, 3)}%` }}
                                    role="progressbar"
                                    ariaValuenow={convertToPercent(insta, 3)}
                                    ariaValuemin="0"
                                    ariaValuemax="100"
                                  >
                                    <div className="progress-text">
                                      {insta === 0 ? (
                                        t('zelo fotogenična')
                                      ) : insta === 1 ? (
                                        t('fotogenična')
                                      ) : insta === 2 ? (
                                        t('manj fotogenična')
                                      ) : (
                                        <span style={{ color: '#3D8C35' }}>
                                          {t('nefotogenična')}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ReactTooltip
                          anchorId="invalidi-vozicki"
                          place="top"
                          content={t('Dostopno za invalidske vozičke.')}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="invalidi-ne"
                          place="top"
                          content={t('Ni priporočeno za invalidske vozičke.')}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="invalidi-gibalno-ovirani"
                          place="top"
                          content={t('Dostopno osebam z gibalnimi ovirami.')}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="invalidi-gibalno-ovirani-ne"
                          place="top"
                          content={t('Ni dostopno osebam z gibalnimi ovirami.')}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="otroski-vozicek-da"
                          place="top"
                          content={t(
                            'Primerno za družine z otroškim vozičkom in majhnimi otroki (do 10 let).'
                          )}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="otroski-vozicek-ne"
                          place="top"
                          content={t(
                            'Ni primerno za družine z otroškim vozičkom in majhnimi otroki (do 10 let).'
                          )}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="druzina-otroci-da"
                          place="top"
                          content={t(
                            'Primerno za odrasle. Na to destinacijo se lahko podajo tudi starejši otroci (od 10 let)'
                          )}
                          P
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="druzina-otroci-ne"
                          place="top"
                          content={t('Ni primerno za starejše otroke (od 10 let)')}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="idealno"
                          place="top"
                          content={t('Ta destinacija najbolj ustreza določeni vrsti aktivnosti.')}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="primerno"
                          place="top"
                          content={t(
                            'Ikone, ki so obarvane z rumeno prikazujejo za katere osebe je destinacija primerna.'
                          )}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="kuzki"
                          place="top"
                          content={t('Več kot je v polju zelene barve, bolj je primerno za kužke.')}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="gostilna"
                          place="top"
                          content={t(
                            'Bližje kot je gostilna vaši lokaciji, bolj je obarvano polje z zeleno.'
                          )}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="fotogencina"
                          place="top"
                          content={t(
                            'Bolj kot je polje obarvano, bolj je destinacija primerna za objavo na Instagramu.'
                          )}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <ReactTooltip
                          anchorId="zaljubljence"
                          place="top"
                          content={t('Primerno za zaljubljence.')}
                          style={{ backgroundColor: '#ffffff', color: '#222' }}
                        />

                        <a
                          className="btn btn-yellow rounded-pill text-white mt-3 me-md-2"
                          href={`${item.productLink}?utm_source=kviz&utm_medium=escapebox&utm_campaign=priporocilni-kviz`}
                          onClick={() => {
                            window.plausible('CTR');
                          }}
                          target="_blank"
                        >
                          {t('Več informacij')}
                        </a>
                        <button
                          className="btn btn-outline-yellow rounded-pill text-white mt-3"
                          onClick={() => startAgain()}
                          target="_blank"
                        >
                          {t('Ponovi')}
                        </button>
                      </div>
                      <div className="col-sm-6 order-1 order-md-2">
                        <div className="mx-auto image-cropper image-cropper__big">
                          <img
                            className="result-pic mx-auto "
                            src={require(`assets/images/kam-pa-kam/lokacije/${item.productImg}`)}
                          />
                          <div className="badges">
                            {romanticna && (
                              <div className="badge">
                                <img src={images[`icon-romanticna.svg`]} id={item} />
                                <p className="lead">{t('Romantična')}</p>
                                <p>{t('destinacija')}</p>
                              </div>
                            )}

                            <div className="badge">
                              <img src={images[`icon-lahka.svg`]} id={item} />
                              <p className="lead">
                                {zahtevnost === 0
                                  ? t('Lahka')
                                  : zahtevnost === 1
                                  ? t('Srednje zahtevna')
                                  : t('Zahtevna')}
                              </p>
                              <p>{t('pohodna pot')}</p>
                            </div>

                            <div className="badge">
                              <img src={images[`icon-${kje_poteka}.svg`]} id={item} />

                              {kje_poteka === 'na-prostem' ? (
                                <>
                                  <p className="lead">{t('Zunanja')}</p>
                                  <p>{t('aktivnost')}</p>
                                </>
                              ) : (
                                <>
                                  <p className="lead">{t('Notranja')}</p>
                                  <p>{t('aktivnost')}</p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mt-6 order-3">
                        <div>
                          <button
                            onClick={() => onShare()}
                            className="btn btn-white rounded-pill text-white mt-3 d-block px-4 mx-auto d-flex align-items-center justify-content-center"
                          >
                            <svg
                              style={{ width: '30px', fill: '#4267B2', marginRight: '10px' }}
                              role="img"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                            </svg>
                            <span style={{ color: '#4267B2' }}>{t('Deli')}</span>
                          </button>
                        </div>

                        <h2 className="mt-6 mt-sm-0 mb-4">{t('Oglejte si tudi')}</h2>

                        <div className="row">
                          <div className="mb-5 col-md-6">
                            <div className="title d-flex align-items-center">
                              <img className="pe-2" src={images[`icon-pes.svg`]} />
                              <h4>{t('Najboljša destinacija za potep s kužkom')}</h4>
                            </div>
                            <p>
                              {t(
                                'Za vse, ki srečo na izletih delijo s svojimi štirinožnimi prijatelji.'
                              )}
                            </p>

                            {filteredPsi.map((item, index) => {
                              return (
                                <motion.div variants={child} className="col-12">
                                  <div className="related">
                                    <a
                                      href={`${item.productLink}?utm_source=kviz&utm_medium=escapebox&utm_campaign=priporocilni-kviz`}
                                      onClick={() => {
                                        window.plausible('Ctr_other');
                                      }}
                                      target="_blank"
                                    >
                                      <div className="row g-2 text-start" key={index}>
                                        <div className="col-12 d-flex align-items-center">
                                          <div className="image-cropper  image-cropper__small">
                                            <img
                                              className="result-pic"
                                              src={require(`assets/images/kam-pa-kam/lokacije/${item.productImg}`)}
                                            />
                                          </div>
                                          <div className="ms-2">
                                            <p className="mb-0 lead fw-bold">{item.productName}</p>
                                            <p className="mb-0"> {item.productDesc}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </motion.div>
                              );
                            })}
                          </div>

                          <div className="mb-5 col-md-6">
                            <div className="title d-flex align-items-center">
                              <img className="pe-2" src={images[`icon-restavracija.svg`]} />
                              <h4>{t('Najboljša destinacija za dobro kosilo')}</h4>
                            </div>
                            <p>{t('Za vse, ki imajo radi poln trebušček dobrot.')}</p>

                            {filteredKosilo.map((item, index) => {
                              return (
                                <motion.div variants={child} className="col-12">
                                  <div className="related">
                                    <a
                                      href={`${item.productLink}?utm_source=kviz&utm_medium=escapebox&utm_campaign=priporocilni-kviz`}
                                      onClick={() => {
                                        window.plausible('Ctr_other');
                                      }}
                                      target="_blank"
                                    >
                                      <div className="row g-2 text-start" key={index}>
                                        <div className="col-12 d-flex align-items-center">
                                          <div className="image-cropper  image-cropper__small">
                                            <img
                                              className="result-pic"
                                              src={require(`assets/images/kam-pa-kam/lokacije/${item.productImg}`)}
                                            />
                                          </div>
                                          <div className="ms-2">
                                            <p className="mb-0 lead fw-bold">{item.productName}</p>
                                            <p className="mb-0"> {item.productDesc}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </motion.div>
                              );
                            })}
                          </div>

                          <div className="mb-5 col-md-6">
                            <div className="title d-flex align-items-center">
                              <img className="pe-2" src={images[`icon-romantika.svg`]} />
                              <h4>{t('Najboljša destinacija za romantike')}</h4>
                            </div>
                            <p>{t('Za vse, ki na izlet hodijo s svojo boljšo polovico.')}</p>

                            {filteredRomantic.map((item, index) => {
                              // Display the current item with a 50% chance
                              return (
                                <motion.div variants={child} className="col-12">
                                  <div className="related">
                                    <a
                                      href={`${item.productLink}?utm_source=kviz&utm_medium=escapebox&utm_campaign=priporocilni-kviz`}
                                      onClick={() => {
                                        window.plausible('Ctr_other');
                                      }}
                                      target="_blank"
                                    >
                                      <div className="row g-2 text-start" key={index}>
                                        <div className="col-12 d-flex align-items-center">
                                          <div className="image-cropper  image-cropper__small">
                                            <img
                                              className="result-pic"
                                              src={require(`assets/images/kam-pa-kam/lokacije/${item.productImg}`)}
                                            />
                                          </div>
                                          <div className="ms-2">
                                            <p className="mb-0 lead fw-bold">{item.productName}</p>
                                            <p className="mb-0"> {item.productDesc}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </motion.div>
                              );
                            })}
                          </div>

                          <div className="mb-5 col-md-6">
                            <div className="title d-flex align-items-center">
                              <img className="pe-2" src={images[`icon-adrenalin.svg`]} />
                              <h4>{t('Najboljša destinacija za adrenalince')}</h4>
                            </div>
                            <p>{t('Za vse, ki uživajo v vznemerljivih trenutkih.')}</p>

                            {filteredAdrenalin.map((item, index) => {
                              return (
                                <motion.div variants={child} className="col-12">
                                  <div className="related">
                                    <a
                                      href={`${item.productLink}?utm_source=kviz&utm_medium=escapebox&utm_campaign=priporocilni-kviz`}
                                      onClick={() => {
                                        window.plausible('Ctr_other');
                                      }}
                                      target="_blank"
                                    >
                                      <div className="row g-2 text-start" key={index}>
                                        <div className="col-12 d-flex align-items-center">
                                          <div className="image-cropper  image-cropper__small">
                                            <img
                                              className="result-pic"
                                              src={require(`assets/images/kam-pa-kam/lokacije/${item.productImg}`)}
                                            />
                                          </div>
                                          <div className="ms-2">
                                            <p className="mb-0 lead fw-bold">{item.productName}</p>
                                            <p className="mb-0"> {item.productDesc}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </motion.div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

function convertToPercent(num, max) {
  if (max === 3) {
    if (num === 3) {
      return 0;
    } else if (num === 2) {
      return 33;
    } else if (num === 1) {
      return 66;
    } else if (num === 0) {
      return 100;
    } else {
      return 'Invalid input';
    }
  }
  if (max === 2) {
    if (num === 2) {
      return 0;
    } else if (num === 1) {
      return 50;
    } else if (num === 0) {
      return 100;
    } else {
      return 'Invalid input';
    }
  }
}

function getRandomItem(arr) {
  // get random index value
  const randomIndex = Math.floor(Math.random() * arr.length);

  // get random item
  const item = arr[randomIndex];

  return item;
}

export default ShowResults;
