import Lottie from 'lottie-react';

const showCalculating = () => {
  return (
    <div className="loader">
      <Lottie
        animationData={{
          nm: 'walk cycling shoes',
          ddd: 0,
          h: 412,
          w: 584,
          meta: { g: '@lottiefiles/toolkit-js 0.26.1' },
          layers: [
            {
              ty: 3,
              nm: 'head ',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [911.5, 260.5, 0], ix: 1 },
                s: { a: 0, k: [200, 200, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [912.353, 480.498, 0], ix: 2 },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: -13 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-9], t: -7 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: -1 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-9], t: 5 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 11 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-9], t: 17 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 23 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-9], t: 29 },
                    { s: [0], t: 35 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              ind: 1,
              parent: 4,
            },
            {
              ty: 3,
              nm: 'position right arm',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [-71.188, -64.788, 0], ix: 2 },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [25.444], t: 0 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-20.556], t: 12 },
                    { s: [25.444], t: 24 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 0, ix: 11 },
              },
              ef: [],
              ind: 2,
              parent: 6,
            },
            {
              ty: 3,
              nm: 'right hand ',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [850, 536.5, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [-14.96, 171.766, 0], ix: 2 },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-8], t: 0 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [22], t: 6 },
                    { s: [-8], t: 12 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              ind: 3,
              parent: 2,
            },
            {
              ty: 3,
              nm: 'neck ',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [900.5, 303.5, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [11.563, -148.6, 0], ix: 2 },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [1.444], t: -17 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [4.444], t: -11 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [1.444], t: -5 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [4.444], t: 1 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [1.444], t: 7 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [4.444], t: 13 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [1.444], t: 19 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [4.444], t: 25 },
                    { s: [1.444], t: 31 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              ind: 4,
              parent: 6,
            },
            {
              ty: 3,
              nm: 'position character',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [204, -155, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 10 },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 0, ix: 11 },
              },
              ef: [],
              ind: 5,
            },
            {
              ty: 3,
              nm: 'waist ',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  s: true,
                  x: { a: 0, k: 88, ix: 3 },
                  y: {
                    a: 1,
                    k: [
                      { o: { x: 0.333, y: 0 }, i: { x: 0.471, y: 1 }, s: [119.971], t: -9 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.471, y: 1 }, s: [87.971], t: -3 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.471, y: 1 }, s: [119.971], t: 3 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.471, y: 1 }, s: [87.971], t: 9 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.471, y: 1 }, s: [119.971], t: 15 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.471, y: 1 }, s: [87.971], t: 21 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.471, y: 1 }, s: [119.971], t: 27 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.471, y: 1 }, s: [87.971], t: 33 },
                      { s: [119.971], t: 39 },
                    ],
                    ix: 4,
                  },
                  z: { a: 0, k: 0 },
                },
                r: { a: 0, k: 0, ix: 10 },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 0, ix: 11 },
              },
              ef: [],
              ind: 6,
              parent: 5,
            },
            {
              ty: 4,
              nm: 'right shoe ',
              sr: 1,
              st: -49,
              op: 1,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [826, 739.5, 0], ix: 1 },
                s: { a: 0, k: [-136, 136, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [354.487, 274.305, 0], ix: 2 },
                r: { a: 0, k: 20.626, ix: 10 },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.392, 0.272],
                                  [26.393, -0.272],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.922, -10.09],
                                  [12.423, 10.73],
                                ],
                              },
                            ],
                            t: 0,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [798.925, 767.689], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 2',
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.125, 0.322],
                                  [26.124, -0.322],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.68, -5.655],
                                  [15.975, 11.206],
                                ],
                              },
                            ],
                            t: 0,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [782.192, 744.239], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 3',
                  ix: 3,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-2.377, 11.234],
                                  [10.567, 12.991],
                                  [14.322, 1.679],
                                  [-15.242, -23.886],
                                  [-11.879, -5.029],
                                ],
                                o: [
                                  [1.168, -5.52],
                                  [-14.946, -18.376],
                                  [-17.083, -2.003],
                                  [8.982, 14.077],
                                  [11.793, 4.993],
                                ],
                                v: [
                                  [42.086, 24.313],
                                  [28.74, -2.539],
                                  [-11.407, -35.506],
                                  [-28.012, -5.555],
                                  [9.848, 32.516],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-13.135, -4.694],
                                  [8.434, 14.165],
                                  [11.304, 8.245],
                                  [-5.173, -26.185],
                                  [-13.666, -5.499],
                                ],
                                o: [
                                  [5.231, 1.869],
                                  [-8.629, -14.494],
                                  [-13.484, -9.835],
                                  [3.048, 15.432],
                                  [11.696, 4.706],
                                ],
                                v: [
                                  [42.472, 36.692],
                                  [21.102, 17.623],
                                  [-7.727, -26.64],
                                  [-34.211, -12.422],
                                  [-8.392, 17.549],
                                ],
                              },
                            ],
                            t: 0,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [787.515, 756.053], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 5',
                  ix: 4,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-2.377, 11.234],
                                  [10.567, 12.992],
                                  [13.883, 3.895],
                                  [-10.531, -32.477],
                                  [-11.88, -5.031],
                                ],
                                o: [
                                  [1.168, -5.519],
                                  [-14.946, -18.375],
                                  [-15.694, -4.405],
                                  [5.15, 15.883],
                                  [11.792, 4.993],
                                ],
                                v: [
                                  [45.333, 23.15],
                                  [31.987, -3.702],
                                  [-9.171, -40.459],
                                  [-35.969, -7.165],
                                  [12.477, 39.871],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-4.141, 11.771],
                                  [5.642, 15.491],
                                  [9.823, 9.823],
                                  [7.309, -31.28],
                                  [-11.424, -4.223],
                                ],
                                o: [
                                  [1.843, -5.239],
                                  [-7.98, -21.91],
                                  [-11.105, -11.105],
                                  [-3.574, 15.297],
                                  [11.825, 4.371],
                                ],
                                v: [
                                  [46.545, 33.995],
                                  [24.365, 12.952],
                                  [0.686, -27.815],
                                  [-38.684, -13.833],
                                  [29.783, 40.516],
                                ],
                              },
                            ],
                            t: 0,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [791.28, 749.258], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 7',
                  ix: 5,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.333, 4.667],
                                  [3.879, 1.037],
                                  [2.191, -21.923],
                                  [-13.537, 5.36],
                                  [-3.333, 8.333],
                                  [-1.833, 8.5],
                                  [6.772, 9.592],
                                ],
                                o: [
                                  [-5.333, -4.666],
                                  [-20.94, -5.594],
                                  [-1.833, 6.251],
                                  [10.074, -3.988],
                                  [1.681, -4.199],
                                  [-5.419, 2.187],
                                  [-3.598, -5.095],
                                ],
                                v: [
                                  [20.022, -28.661],
                                  [1.523, -40.344],
                                  [-45.839, -16.566],
                                  [25.598, 40.578],
                                  [42.505, 22.09],
                                  [47.672, 2.923],
                                  [38.689, -19.661],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.112, 5.636],
                                  [8.441, 5.052],
                                  [12.819, -16.641],
                                  [-13.068, 5.888],
                                  [-5.864, 6.609],
                                  [1.865, 15.754],
                                  [7.91, 2.879],
                                ],
                                o: [
                                  [-4.755, -6.519],
                                  [-11.032, -6.603],
                                  [-4.68, 4.164],
                                  [9.725, -4.381],
                                  [1.489, -1.678],
                                  [-14.001, -0.659],
                                  [-5.77, -2.1],
                                ],
                                v: [
                                  [24.114, -12.816],
                                  [9.306, -26.368],
                                  [-37.923, -18.416],
                                  [23.735, 46.979],
                                  [37.328, 42.592],
                                  [48.678, 10.914],
                                  [35.064, -8.157],
                                ],
                              },
                            ],
                            t: 0,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [802.478, 736.994], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 9',
                  ix: 6,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [-6.167, -4.666],
                                  [8.333, -1.667],
                                  [3.834, 3.5],
                                  [0, 0],
                                ],
                                o: [
                                  [13.5, 0.5],
                                  [6.166, 4.667],
                                  [-8.333, 1.666],
                                  [-3.833, -3.5],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.917, -12.083],
                                  [10.25, -0.417],
                                  [12.583, 10.417],
                                  [-12.584, 6.917],
                                  [-18.084, -0.917],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [-14.183, -7.503],
                                  [8.352, 0.481],
                                  [2.307, 4.363],
                                  [0, 0],
                                ],
                                o: [
                                  [5.238, -1.292],
                                  [9.086, 4.807],
                                  [-8.352, -0.481],
                                  [-2.307, -4.362],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.269, 3.693],
                                  [3.243, 7.234],
                                  [14.73, 27.299],
                                  [-12.294, 22.985],
                                  [-14.722, 21.651],
                                ],
                              },
                            ],
                            t: 0,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [819.917, 708.75], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 11',
                  ix: 7,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.143, 1.837],
                                  [0, 0],
                                  [-1.837, 5.142],
                                  [0, 0],
                                  [-4.049, 21.439],
                                  [-16.108, -3.455],
                                  [6.695, -12.578],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.144, -1.836],
                                  [0, 0],
                                  [1.836, -5.143],
                                  [2.471, -13.082],
                                  [7.346, 1.576],
                                  [0, 0],
                                  [-1.837, 5.143],
                                ],
                                v: [
                                  [-3.752, 13.578],
                                  [-9.171, 11.642],
                                  [-15.157, -0.995],
                                  [-16.607, -2.451],
                                  [-10.997, -6.269],
                                  [13.86, -14.796],
                                  [13.464, 11.045],
                                  [6.027, 14.231],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.799, 2.423],
                                  [0, 0],
                                  [-1.779, 4.828],
                                  [0, 0],
                                  [-4.976, 19.906],
                                  [-22.468, -10.312],
                                  [3.736, -19.521],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.683, -2.56],
                                  [0, 0],
                                  [2.235, -4.649],
                                  [4.505, -18.021],
                                  [8.81, 4.044],
                                  [0, 0],
                                  [-2.423, 4.799],
                                ],
                                v: [
                                  [-4.794, 28.105],
                                  [-12.677, 30.584],
                                  [-17.108, 18.886],
                                  [-17.928, 24.66],
                                  [-18.014, 11.591],
                                  [11.164, -0.28],
                                  [15.625, 25.394],
                                  [4.679, 29.938],
                                ],
                              },
                            ],
                            t: 0,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [822.466, 708.978], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 7,
            },
            {
              ty: 4,
              nm: 'right shoe ',
              sr: 1,
              st: -38,
              op: 24,
              ip: 22,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [826, 739.5, 0], ix: 1 },
                s: { a: 0, k: [-136, 136, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.157 },
                      i: { x: 0.833, y: 0.843 },
                      s: [384.861, 260.049, 0],
                      t: 22,
                      ti: [-2.333, -0.833, 0],
                      to: [2.333, 0.833, 0],
                    },
                    { s: [398.861, 265.049, 0], t: 23 },
                  ],
                  ix: 2,
                },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [4.708], t: 22 },
                    { s: [-3.292], t: 23 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.566, 7.262],
                                  [70.473, 12.612],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.238, 3.241],
                                  [74.555, 8.653],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.161, 12.94],
                                  [72.29, 8.896],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [798.925, 767.689], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 2',
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.579, 13.141],
                                  [68.406, 18.61],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.907, 8.865],
                                  [66.992, 13.745],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.767, 17.796],
                                  [71.634, 14.305],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [782.192, 744.239], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 3',
                  ix: 3,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.56, 6.886],
                                  [14.508, 9.939],
                                  [17.247, 1.927],
                                  [-17.223, -17.623],
                                  [-14.125, -4.33],
                                ],
                                o: [
                                  [3.872, -2.524],
                                  [-18.501, -12.674],
                                  [-20.572, -2.298],
                                  [10.15, 10.386],
                                  [14.023, 4.299],
                                ],
                                v: [
                                  [89.594, 35.433],
                                  [75.43, 15.153],
                                  [22.24, -6.742],
                                  [4.129, 8.64],
                                  [43.836, 33.589],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.03, 6.812],
                                  [13.523, 9.865],
                                  [16.206, 1.928],
                                  [-15.976, -17.483],
                                  [-13.236, -4.307],
                                ],
                                o: [
                                  [3.677, -2.497],
                                  [-17.244, -12.58],
                                  [-19.33, -2.3],
                                  [9.415, 10.303],
                                  [13.14, 4.276],
                                ],
                                v: [
                                  [88.768, 31.01],
                                  [75.705, 10.896],
                                  [25.937, -10.86],
                                  [8.689, 4.363],
                                  [45.728, 29.132],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-7.926, 7.062],
                                  [12.318, 10.683],
                                  [15.892, -0.423],
                                  [-12.281, -19.529],
                                  [-9.81, 1.212],
                                ],
                                o: [
                                  [3.233, -2.88],
                                  [-13.804, -11.972],
                                  [-18.956, 0.504],
                                  [10.612, 16.876],
                                  [14.513, -1.793],
                                ],
                                v: [
                                  [87.519, 31.855],
                                  [70.167, 5.343],
                                  [24, -15.11],
                                  [6.974, 7.949],
                                  [54.385, 38.089],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [787.515, 756.053], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 5',
                  ix: 4,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-3.488, 14.884],
                                  [10.664, 8.603],
                                  [16.653, 3.143],
                                  [-10.115, -21.802],
                                  [-13.283, -6.268],
                                ],
                                o: [
                                  [0.992, -4.235],
                                  [-17.786, -14.347],
                                  [-20.279, -3.828],
                                  [4.946, 10.662],
                                  [14.291, 6.744],
                                ],
                                v: [
                                  [94.335, 33.54],
                                  [74.543, 9.992],
                                  [22.468, -15.335],
                                  [-5.418, 6.182],
                                  [47.893, 41.455],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-4.923, 20.418],
                                  [9.922, 8.537],
                                  [15.631, 3.133],
                                  [-9.231, -21.617],
                                  [-12.418, -6.227],
                                ],
                                o: [
                                  [0.959, -3.976],
                                  [-16.549, -14.238],
                                  [-19.035, -3.815],
                                  [4.514, 10.572],
                                  [13.36, 6.699],
                                ],
                                v: [
                                  [96.984, 24.391],
                                  [73.555, 1.427],
                                  [25.552, -20.91],
                                  [-0.396, 1.982],
                                  [49.31, 36.997],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-1.871, 20.373],
                                  [9.919, 8.098],
                                  [19.811, 0.819],
                                  [-8.885, -20.35],
                                  [-12.846, -4.254],
                                ],
                                o: [
                                  [0.364, -3.968],
                                  [-13.812, -11.276],
                                  [-19.974, -0.826],
                                  [4.48, 10.262],
                                  [13.821, 4.576],
                                ],
                                v: [
                                  [93.093, 24.231],
                                  [69.795, -2.927],
                                  [26.381, -22.762],
                                  [-0.161, 8.759],
                                  [49.63, 41.511],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [791.28, 749.258], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 6',
                  ix: 5,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0.513, -1.181],
                                ],
                                o: [
                                  [8.311, -2.039],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.925, -121.849],
                                  [-113.488, -117.555],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0.503, -1.102],
                                ],
                                o: [
                                  [7.85, -1.773],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.462, -124.501],
                                  [-108.57, -121.607],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0.321, -1.135],
                                ],
                                o: [
                                  [7.294, -2.872],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.848, -121.747],
                                  [-111.174, -122.421],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [966.42, 834.392], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 4',
                  ix: 6,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-12.291, 10.152],
                                  [14.734, 1.047],
                                  [5.197, -1.444],
                                  [3.67, -1.583],
                                ],
                                o: [
                                  [24.372, -4.596],
                                  [8.786, -7.256],
                                  [-7.218, -0.513],
                                  [-3.495, 0.971],
                                  [-6.416, 2.768],
                                ],
                                v: [
                                  [-164.235, -119.162],
                                  [-111.965, -121.178],
                                  [-136.507, -129.396],
                                  [-142.37, -128.867],
                                  [-153.66, -125.202],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-11.467, 9.658],
                                  [13.844, 1.137],
                                  [5.479, -1.422],
                                  [3.437, -1.522],
                                ],
                                o: [
                                  [22.878, -4.543],
                                  [8.196, -6.903],
                                  [-5.508, -0.453],
                                  [-3.301, 0.857],
                                  [-6.009, 2.661],
                                ],
                                v: [
                                  [-157.028, -123.564],
                                  [-109.391, -124.305],
                                  [-129.079, -131.735],
                                  [-137.858, -131.498],
                                  [-148.638, -127.711],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-7.146, -2.194],
                                  [13.507, -0.803],
                                  [5.294, -1.542],
                                  [2.383, -0.967],
                                ],
                                o: [
                                  [12.953, -3.231],
                                  [8.895, -5.463],
                                  [-5.374, 0.32],
                                  [-3.445, 1.004],
                                  [-5.345, 2.168],
                                ],
                                v: [
                                  [-153.423, -122.216],
                                  [-113.913, -123.52],
                                  [-129.318, -128.535],
                                  [-142.569, -127.087],
                                  [-147.964, -124.984],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [966.864, 837.464], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 7',
                  ix: 7,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.196, 3.131],
                                  [-9.21, -0.778],
                                  [-4.36, -5.841],
                                  [-7.983, 6.972],
                                  [6.443, -10.053],
                                  [0, 0],
                                  [10.625, 0.172],
                                  [11.994, 0.219],
                                  [3.668, 0.205],
                                  [-0.08, 6.02],
                                ],
                                o: [
                                  [7.731, -0.871],
                                  [7.207, -5.474],
                                  [3.854, 5.164],
                                  [7.128, 18.259],
                                  [-0.791, 1.235],
                                  [0, 0],
                                  [-9.678, -0.157],
                                  [-23.454, -0.429],
                                  [-0.622, -5.251],
                                  [0.302, -22.67],
                                ],
                                v: [
                                  [-127.482, -142.801],
                                  [-101.218, -135.897],
                                  [-82.594, -134.459],
                                  [-66.449, -124.737],
                                  [-68.401, -69.227],
                                  [-72.796, -69.106],
                                  [-89.351, -72.879],
                                  [-117.346, -87.265],
                                  [-162.334, -91.265],
                                  [-171.294, -107.363],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.501, 3.046],
                                  [-8.668, -0.648],
                                  [-2.44, -5.613],
                                  [-7.443, 6.629],
                                  [5.968, -9.512],
                                  [0, 0],
                                  [9.994, 0.065],
                                  [11.281, 0.098],
                                  [3.451, 0.16],
                                  [-0.021, 5.661],
                                ],
                                o: [
                                  [7.262, -0.89],
                                  [6.728, -5.213],
                                  [2.416, 5.557],
                                  [3.388, 14.898],
                                  [-0.733, 1.169],
                                  [0, 0],
                                  [-9.103, -0.059],
                                  [-22.059, -0.191],
                                  [-0.633, -4.933],
                                  [0.079, -21.322],
                                ],
                                v: [
                                  [-124.03, -145.234],
                                  [-98.579, -138.931],
                                  [-81.315, -138.816],
                                  [-63.816, -127.43],
                                  [-67.38, -77.169],
                                  [-71.512, -77.015],
                                  [-87.114, -80.414],
                                  [-113.571, -93.688],
                                  [-155.913, -97.042],
                                  [-164.484, -112.098],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.294, -0.672],
                                  [-8.448, 0.565],
                                  [-3.26, -5.77],
                                  [-7.206, 6.507],
                                  [7.628, -16.717],
                                  [0, 0],
                                  [9.735, -0.004],
                                  [10.989, 0.019],
                                  [3.362, 0.133],
                                  [1.013, 5.421],
                                ],
                                o: [
                                  [6.881, -1.855],
                                  [7.802, -8.444],
                                  [2.903, 5.139],
                                  [4.634, 12.684],
                                  [-0.558, 1.222],
                                  [0, 0],
                                  [-8.867, 0.003],
                                  [-21.489, -0.037],
                                  [-0.65, -4.8],
                                  [-4.535, -24.267],
                                ],
                                v: [
                                  [-122.291, -141.376],
                                  [-97.938, -139.303],
                                  [-81.366, -134.663],
                                  [-67.457, -126.716],
                                  [-70.911, -74.465],
                                  [-72.641, -77.182],
                                  [-87.861, -80.388],
                                  [-113.721, -93.14],
                                  [-154.988, -96.121],
                                  [-163.928, -100.381],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [954.82, 850.825], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 8',
                  ix: 8,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.838, 0.546],
                                  [7.794, -0.322],
                                  [7.776, 0.215],
                                  [0, 0],
                                  [0, 0],
                                  [-15.947, -0.33],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-8.075, 0.334],
                                  [-16.7, -0.461],
                                  [-12.445, -1.544],
                                  [0, 0],
                                  [10.931, 0.226],
                                  [0, 0],
                                ],
                                v: [
                                  [-79.501, -91.085],
                                  [-89.801, -99.865],
                                  [-118.439, -99.117],
                                  [-143.603, -104.033],
                                  [-163.535, -117.226],
                                  [-110.662, -113.459],
                                  [-69.245, -105.032],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.138, 0.406],
                                  [7.327, -0.374],
                                  [7.315, 0.132],
                                  [0, 0],
                                  [0, 0],
                                  [-14.999, -0.165],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-7.59, 0.387],
                                  [-15.709, -0.282],
                                  [-11.717, -1.339],
                                  [0, 0],
                                  [10.282, 0.113],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.852, -99.166],
                                  [-87.618, -107.33],
                                  [-114.542, -106.367],
                                  [-138.25, -110.762],
                                  [-157.114, -122.987],
                                  [-107.358, -119.924],
                                  [-68.334, -112.375],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.852, 0.32],
                                  [7.134, -0.413],
                                  [7.126, 0.079],
                                  [0, 0],
                                  [0, 0],
                                  [-14.612, -0.06],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-7.391, 0.428],
                                  [-15.303, -0.169],
                                  [-11.422, -1.225],
                                  [0, 0],
                                  [10.016, 0.041],
                                  [0, 0],
                                ],
                                v: [
                                  [-78.823, -99.391],
                                  [-88.39, -107.277],
                                  [-114.609, -106.157],
                                  [-137.733, -110.279],
                                  [-156.19, -122.06],
                                  [-107.704, -119.412],
                                  [-69.641, -112.321],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [955.975, 876.533], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 9',
                  ix: 9,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [7.386, 0.194],
                                  [0, 0],
                                  [2.782, 6.584],
                                  [0, 0],
                                  [-7.367, -0.566],
                                  [0, 0],
                                  [-1.639, -6.957],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-7.27, -1.318],
                                  [0, 0],
                                  [-2.341, -6.752],
                                  [0, 0],
                                  [7.367, 0.566],
                                  [0, 0],
                                  [1.639, 6.958],
                                ],
                                v: [
                                  [-106.132, -93.938],
                                  [-116.634, -98.686],
                                  [-128.923, -115.763],
                                  [-131.032, -128.265],
                                  [-121.007, -138.221],
                                  [-104.321, -135.746],
                                  [-96.623, -117.463],
                                  [-93.236, -109.713],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.948, 0.115],
                                  [0, 0],
                                  [2.676, 6.166],
                                  [0, 0],
                                  [-6.933, -0.465],
                                  [0, 0],
                                  [-1.604, -6.528],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.848, -1.174],
                                  [0, 0],
                                  [-0.646, -6.737],
                                  [0, 0],
                                  [6.933, 0.465],
                                  [0, 0],
                                  [1.604, 6.528],
                                ],
                                v: [
                                  [-103.394, -98.978],
                                  [-113.313, -103.348],
                                  [-124.518, -119.659],
                                  [-126.837, -131.862],
                                  [-117.946, -138.667],
                                  [-101.804, -137.675],
                                  [-93.838, -122.755],
                                  [-91.409, -113.929],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.768, 0.065],
                                  [0, 0],
                                  [3.427, 5.579],
                                  [0, 0],
                                  [-6.75, 0.503],
                                  [0, 0],
                                  [-2.443, -6.075],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.679, -1.097],
                                  [0, 0],
                                  [-4.688, -13.697],
                                  [0, 0],
                                  [6.75, -0.503],
                                  [0, 0],
                                  [1.606, 6.348],
                                ],
                                v: [
                                  [-104.03, -97.895],
                                  [-113.721, -102.084],
                                  [-126.661, -117.122],
                                  [-132, -120.903],
                                  [-123.039, -134.458],
                                  [-103.93, -136.258],
                                  [-95.856, -119.53],
                                  [-92.457, -112.539],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [957.152, 832.241], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 8,
            },
            {
              ty: 4,
              nm: 'right shoe ',
              sr: 1,
              st: -48,
              op: 22,
              ip: 1,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [943.5, 857, 0], ix: 1 },
                s: { a: 0, k: [136, 136, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  s: true,
                  x: {
                    a: 1,
                    k: [
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [888.297], t: 1 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [894.289], t: 2 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [888.789], t: 5 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [891.339], t: 7 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [885.839], t: 9 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [885.771], t: 10 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [886.884], t: 11 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1.191 }, s: [902.172], t: 12 },
                      { o: { x: 0.333, y: -0.21 }, i: { x: 0.667, y: 1.188 }, s: [896.453], t: 13 },
                      {
                        o: { x: 0.333, y: -0.295 },
                        i: { x: 0.667, y: 1.097 },
                        s: [891.251],
                        t: 14,
                      },
                      { o: { x: 0.305, y: 0.054 }, i: { x: 0.651, y: 0.864 }, s: [887.927], t: 15 },
                      {
                        o: { x: 0.342, y: 0.787 },
                        i: { x: 0.687, y: -0.363 },
                        s: [893.394],
                        t: 16,
                      },
                      { o: { x: 0.418, y: -0.166 }, i: { x: 0.779, y: 1 }, s: [894.317], t: 17 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.583, y: -2.061 }, s: [884.186], t: 18 },
                      { o: { x: 0.417, y: 0.96 }, i: { x: 0.833, y: 1 }, s: [884.779], t: 19 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [886.672], t: 20 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [885.133], t: 21 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [893.963], t: 22 },
                      { s: [886.406], t: 23 },
                    ],
                    ix: 3,
                  },
                  y: {
                    a: 1,
                    k: [
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [866.799], t: 1 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [860.562], t: 2 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [860.843], t: 7 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [860.843], t: 9 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [862.998], t: 10 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [869.227], t: 11 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: -111.342 }, s: [879.787], t: 12 },
                      { o: { x: 0.333, y: 0.645 }, i: { x: 0.667, y: 0.333 }, s: [879.776], t: 13 },
                      { o: { x: 0.333, y: 0.27 }, i: { x: 0.667, y: 0.868 }, s: [877.917], t: 14 },
                      { o: { x: 0.333, y: 0.622 }, i: { x: 0.667, y: 1 }, s: [873.333], t: 15 },
                      { o: { x: 0.297, y: 0 }, i: { x: 0.659, y: 12.486 }, s: [872.358], t: 16 },
                      { o: { x: 0.425, y: 0.992 }, i: { x: 0.815, y: 1 }, s: [872.295], t: 17 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.583, y: 0.505 }, s: [873.203], t: 18 },
                      { o: { x: 0.417, y: 1.615 }, i: { x: 0.833, y: 1 }, s: [867.784], t: 19 },
                      { o: { x: 0.182, y: 0 }, i: { x: 0.564, y: 1.349 }, s: [866.122], t: 20 },
                      { o: { x: 0.323, y: -0.06 }, i: { x: 0.677, y: 1.18 }, s: [863.488], t: 21 },
                      { o: { x: 0.436, y: 0.332 }, i: { x: 0.818, y: 0.955 }, s: [852.197], t: 22 },
                      { s: [860.468], t: 23 },
                    ],
                    ix: 4,
                  },
                  z: { a: 0, k: 0 },
                },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.222, y: 0 }, i: { x: 0, y: 1 }, s: [10.525], t: 1 },
                    { o: { x: 0.659, y: 0 }, i: { x: 0.822, y: 1 }, s: [0], t: 2 },
                    { o: { x: 0.265, y: 0 }, i: { x: 0.608, y: 0.432 }, s: [0], t: 7 },
                    { o: { x: 0.318, y: 0.209 }, i: { x: 0.653, y: 1.183 }, s: [3.04], t: 9 },
                    { o: { x: 0.324, y: -0.102 }, i: { x: 0.658, y: 0.987 }, s: [6.399], t: 10 },
                    { o: { x: 0.327, y: -0.01 }, i: { x: 0.661, y: 0.889 }, s: [12.038], t: 11 },
                    { o: { x: 0.33, y: -0.204 }, i: { x: 0.664, y: 1.26 }, s: [5.376], t: 12 },
                    { o: { x: 0.333, y: -0.224 }, i: { x: 0.67, y: 1.323 }, s: [8.904], t: 13 },
                    { o: { x: 0.337, y: 2.087 }, i: { x: 0.672, y: 0.356 }, s: [17.051], t: 15 },
                    { o: { x: 0.341, y: 0.246 }, i: { x: 0.675, y: 1.346 }, s: [16.408], t: 16 },
                    { o: { x: 0.345, y: 0.111 }, i: { x: 0.679, y: 1.457 }, s: [14.66], t: 17 },
                    { o: { x: 0.351, y: 3.752 }, i: { x: 0.686, y: -0.387 }, s: [20.436], t: 18 },
                    { o: { x: 0.423, y: 0.756 }, i: { x: 0.771, y: 1.401 }, s: [19.666], t: 19 },
                    { o: { x: 0.167, y: -0.183 }, i: { x: 0.583, y: 1.909 }, s: [15.858], t: 21 },
                    { o: { x: 0.417, y: -0.109 }, i: { x: 0.833, y: 1.042 }, s: [12.817], t: 22 },
                    { s: [-12.475], t: 23 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.663, -2.93],
                                ],
                                o: [
                                  [-12.006, -3.071],
                                  [0, 0],
                                ],
                                v: [
                                  [17.125, 0.734],
                                  [-17.126, 2.337],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.663, -2.93],
                                ],
                                o: [
                                  [-12.006, -3.071],
                                  [0, 0],
                                ],
                                v: [
                                  [17.125, 0.734],
                                  [-17.126, 2.337],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [4.779, -3.849],
                                ],
                                o: [
                                  [-10.362, -2.324],
                                  [0, 0],
                                ],
                                v: [
                                  [16.422, -3.576],
                                  [-16.757, 0.1],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.624, -4.544],
                                ],
                                o: [
                                  [-9.117, -1.758],
                                  [0, 0],
                                ],
                                v: [
                                  [14.393, -7.021],
                                  [-17.974, -1.776],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [7.699, -6.251],
                                ],
                                o: [
                                  [-6.323, -0.451],
                                  [0, 0],
                                ],
                                v: [
                                  [12.137, -16.52],
                                  [-18.137, -5.501],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [9.545, -7.772],
                                ],
                                o: [
                                  [-3.34, 0.869],
                                  [0, 0],
                                ],
                                v: [
                                  [6.577, -21.569],
                                  [-20.949, -6.924],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [8.186, -9.078],
                                ],
                                o: [
                                  [-3.153, 1.639],
                                  [0, 0],
                                ],
                                v: [
                                  [3.991, -21.232],
                                  [-19.714, -1.368],
                                ],
                              },
                            ],
                            t: 13,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [6.945, -9.371],
                                ],
                                o: [
                                  [-3.539, 1.981],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.6, -18.212],
                                  [-19.083, 4.361],
                                ],
                              },
                            ],
                            t: 14,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.703, -9.665],
                                ],
                                o: [
                                  [-3.925, 2.324],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.778, -14.631],
                                  [-20.602, 11.197],
                                ],
                              },
                            ],
                            t: 15,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.395, -8.739],
                                ],
                                o: [
                                  [-5.142, 1.827],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.062, -12.138],
                                  [-20.848, 11.925],
                                ],
                              },
                            ],
                            t: 16,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.087, -7.814],
                                ],
                                o: [
                                  [-6.359, 1.331],
                                  [0, 0],
                                ],
                                v: [
                                  [3.577, -9.018],
                                  [-20.552, 12.158],
                                ],
                              },
                            ],
                            t: 17,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.087, -7.814],
                                ],
                                o: [
                                  [-6.359, 1.331],
                                  [0, 0],
                                ],
                                v: [
                                  [3.577, -9.018],
                                  [-20.552, 12.158],
                                ],
                              },
                            ],
                            t: 18,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.809, -5.257],
                                ],
                                o: [
                                  [-10, 0.123],
                                  [0, 0],
                                ],
                                v: [
                                  [11.409, -6.635],
                                  [-20.466, 9.679],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.912, -3.131],
                                ],
                                o: [
                                  [-9.133, -1.445],
                                  [0, 0],
                                ],
                                v: [
                                  [14.683, -2.034],
                                  [-19.848, 5.027],
                                ],
                              },
                            ],
                            t: 20,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.208, -1.655],
                                ],
                                o: [
                                  [-8.332, -1.972],
                                  [0, 0],
                                ],
                                v: [
                                  [16.745, 0.493],
                                  [-17.126, 2.337],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [-0.276, -1.258],
                                ],
                                o: [
                                  [-7.767, -3.604],
                                  [0, 0],
                                ],
                                v: [
                                  [24.72, 1.774],
                                  [-17.504, -3.621],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [13.275, -5.855],
                                ],
                                o: [
                                  [-5.77, -4.153],
                                  [0, 0],
                                ],
                                v: [
                                  [21.008, -8.431],
                                  [-22.226, -13.735],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [966.42, 834.392], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 4',
                  ix: 2,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [12.21, 10.257],
                                  [-14.731, 1.228],
                                  [-5.832, -1.505],
                                  [-3.243, -2.339],
                                ],
                                o: [
                                  [-24.349, -4.803],
                                  [-8.728, -7.331],
                                  [5.861, -0.488],
                                  [3.514, 0.907],
                                  [6.071, 4.383],
                                ],
                                v: [
                                  [25.89, 6.128],
                                  [-15.797, 1.36],
                                  [-11.159, -11.129],
                                  [5.448, -9.908],
                                  [15.115, -3.512],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [12.21, 10.257],
                                  [-14.731, 1.228],
                                  [-5.832, -1.505],
                                  [-3.243, -2.339],
                                ],
                                o: [
                                  [-24.349, -4.803],
                                  [-8.728, -7.331],
                                  [5.861, -0.488],
                                  [3.514, 0.907],
                                  [6.071, 4.383],
                                ],
                                v: [
                                  [25.89, 6.128],
                                  [-15.797, 1.36],
                                  [-11.159, -11.129],
                                  [5.448, -9.908],
                                  [15.115, -3.512],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.722, -0.447],
                                  [11.018, 9.056],
                                  [-8.331, 1.94],
                                  [-5.873, -1.199],
                                  [-3.358, -2.286],
                                ],
                                o: [
                                  [-21.208, -2.978],
                                  [-8.297, -6.752],
                                  [3.436, -1.058],
                                  [3.536, 0.721],
                                  [6.29, 4.283],
                                ],
                                v: [
                                  [25.256, 2.028],
                                  [-13.816, 0.482],
                                  [-17.228, -12.03],
                                  [4.534, -13.84],
                                  [14.871, -7.65],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [1.269, -0.785],
                                  [10.116, 8.147],
                                  [-3.485, 2.48],
                                  [-5.905, -0.966],
                                  [-3.446, -2.245],
                                ],
                                o: [
                                  [-18.829, -1.597],
                                  [-7.971, -6.313],
                                  [1.6, -1.489],
                                  [3.552, 0.581],
                                  [6.457, 4.206],
                                ],
                                v: [
                                  [23.28, -1.258],
                                  [-13.812, -0.364],
                                  [-23.319, -12.894],
                                  [2.346, -16.998],
                                  [13.191, -10.964],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [2.577, -1.593],
                                  [8.143, 6.129],
                                  [-1.745, 2.05],
                                  [-6.069, -0.431],
                                  [-3.705, -2.182],
                                ],
                                o: [
                                  [-13.51, 1.634],
                                  [-7.324, -5.376],
                                  [-2.702, -2.529],
                                  [3.645, 0.259],
                                  [6.946, 4.091],
                                ],
                                v: [
                                  [19.155, -11.363],
                                  [-12.161, -3.172],
                                  [-26.615, -13.755],
                                  [-0.922, -25.507],
                                  [11.339, -20.314],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [3.808, -2.354],
                                  [5.928, 3.927],
                                  [4.787, 2.415],
                                  [-6.052, 0.118],
                                  [-3.854, -2.055],
                                ],
                                o: [
                                  [-7.789, 4.816],
                                  [-6.458, -4.278],
                                  [-6.922, -3.493],
                                  [3.629, -0.071],
                                  [7.228, 3.854],
                                ],
                                v: [
                                  [13.067, -16.46],
                                  [-14.052, -5.343],
                                  [-28.769, -12.376],
                                  [-5.221, -29.577],
                                  [6.394, -25.122],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [2.895, -2.82],
                                  [6.33, 2.895],
                                  [4.174, 1.844],
                                  [-5.63, 0.949],
                                  [-4.008, -1.524],
                                ],
                                o: [
                                  [-6.823, 5.891],
                                  [-6.743, -3.185],
                                  [-6.219, -3.427],
                                  [3.498, -0.69],
                                  [8.182, 3.112],
                                ],
                                v: [
                                  [11.636, -16.135],
                                  [-11.698, -0.744],
                                  [-28.423, -7.199],
                                  [-8.816, -27.849],
                                  [2.758, -25.193],
                                ],
                              },
                            ],
                            t: 13,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [1.964, -2.804],
                                  [6.888, 2.578],
                                  [2.815, 1.802],
                                  [-5.226, 1.266],
                                  [-3.986, -1.266],
                                ],
                                o: [
                                  [-6.997, 6.116],
                                  [-6.907, -2.684],
                                  [-4.572, -3.721],
                                  [2.043, 0.525],
                                  [8.759, 2.743],
                                ],
                                v: [
                                  [10.68, -14.498],
                                  [-12.601, 5.753],
                                  [-28.898, -3.394],
                                  [-10.521, -26.478],
                                  [-0.881, -21.994],
                                ],
                              },
                            ],
                            t: 14,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [1.033, -2.787],
                                  [7.447, 2.261],
                                  [1.456, 1.76],
                                  [-4.822, 1.583],
                                  [-3.648, -1.851],
                                ],
                                o: [
                                  [-7.172, 6.34],
                                  [-7.071, -2.182],
                                  [-2.925, -4.016],
                                  [3.201, -1.307],
                                  [7.879, 3.998],
                                ],
                                v: [
                                  [10.056, -9.759],
                                  [-13.503, 12.249],
                                  [-29.373, 0.411],
                                  [-13.275, -21.44],
                                  [-1.743, -17.988],
                                ],
                              },
                            ],
                            t: 15,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.698, -2.39],
                                  [8.232, 3.212],
                                  [0.054, 2.277],
                                  [-4.869, 1.198],
                                  [-3.846, -1.206],
                                ],
                                o: [
                                  [-9.119, 5.433],
                                  [-7.309, -2.779],
                                  [-1.08, -4.155],
                                  [3.244, -1.064],
                                  [9.096, 2.796],
                                ],
                                v: [
                                  [10.119, -10.047],
                                  [-14.168, 11.884],
                                  [-28.729, 1.647],
                                  [-12.99, -19.782],
                                  [-3.913, -16.093],
                                ],
                              },
                            ],
                            t: 16,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.364, -1.992],
                                  [9.017, 4.164],
                                  [-1.348, 2.793],
                                  [-4.917, 0.813],
                                  [-3.727, -1.403],
                                ],
                                o: [
                                  [-11.066, 4.527],
                                  [-7.546, -3.376],
                                  [0.764, -4.295],
                                  [3.287, -0.821],
                                  [8.855, 3.218],
                                ],
                                v: [
                                  [13.69, -7.659],
                                  [-14.833, 11.519],
                                  [-28.085, 2.882],
                                  [-10.116, -15.456],
                                  [-0.13, -13.818],
                                ],
                              },
                            ],
                            t: 17,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.364, -1.992],
                                  [9.017, 4.164],
                                  [-1.348, 2.793],
                                  [-4.917, 0.813],
                                  [-3.727, -1.403],
                                ],
                                o: [
                                  [-11.066, 4.527],
                                  [-7.546, -3.376],
                                  [0.764, -4.295],
                                  [3.287, -0.821],
                                  [8.855, 3.218],
                                ],
                                v: [
                                  [13.69, -7.659],
                                  [-14.833, 11.519],
                                  [-28.085, 2.882],
                                  [-10.116, -15.456],
                                  [-0.13, -13.818],
                                ],
                              },
                            ],
                            t: 18,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-0.018, -0.783],
                                  [11.984, 6.261],
                                  [-8.507, 3.571],
                                  [-5.506, 0.087],
                                  [-3.661, -1.571],
                                ],
                                o: [
                                  [-18.928, 1.826],
                                  [-8.963, -4.618],
                                  [4.108, -3.069],
                                  [3.48, -0.219],
                                  [7.806, 3.22],
                                ],
                                v: [
                                  [19.952, -3.341],
                                  [-16.609, 9.239],
                                  [-22.674, -1.854],
                                  [-2.12, -12.594],
                                  [9.838, -9.897],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.043, -0.393],
                                  [11.759, 8.847],
                                  [-11.772, 2.085],
                                  [-5.659, -0.975],
                                  [-3.359, -2.143],
                                ],
                                o: [
                                  [-21.698, -2.33],
                                  [-8.592, -6.421],
                                  [5.151, -1.606],
                                  [3.501, 0.515],
                                  [6.738, 4.216],
                                ],
                                v: [
                                  [24.233, 1.313],
                                  [-16.73, 4.815],
                                  [-16.867, -7.456],
                                  [2.3, -11.141],
                                  [12.755, -6.081],
                                ],
                              },
                            ],
                            t: 20,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [12.21, 10.257],
                                  [-14.731, 1.228],
                                  [-5.832, -1.505],
                                  [-3.243, -2.339],
                                ],
                                o: [
                                  [-24.349, -4.803],
                                  [-8.728, -7.331],
                                  [5.861, -0.488],
                                  [3.514, 0.907],
                                  [6.071, 4.383],
                                ],
                                v: [
                                  [25.616, 5.134],
                                  [-15.797, 1.36],
                                  [-11.159, -11.129],
                                  [5.448, -9.908],
                                  [14.734, -3.753],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [9.905, 12.497],
                                  [-14.678, -1.754],
                                  [-5.411, -2.645],
                                  [-3.26, -2.315],
                                ],
                                o: [
                                  [-22.89, -9.59],
                                  [-7.08, -8.933],
                                  [5.84, 0.698],
                                  [3.261, 1.594],
                                  [5.7, 4.048],
                                ],
                                v: [
                                  [32.886, 6.229],
                                  [-17.848, -6.672],
                                  [4.26, -10.234],
                                  [9.888, -8.49],
                                  [23.8, -1.889],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [10.623, 1.914],
                                  [-14.782, -0.068],
                                  [-5.944, -2.655],
                                  [-3.613, -3.237],
                                ],
                                o: [
                                  [-18.074, -9.165],
                                  [-11.218, -2.021],
                                  [5.881, 0.027],
                                  [2.247, 1.004],
                                  [5.577, 4.996],
                                ],
                                v: [
                                  [28.706, -3.788],
                                  [-16.994, -13.196],
                                  [-10.373, -23.794],
                                  [11.195, -18.741],
                                  [21.493, -10.489],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [966.864, 837.464], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 6',
                  ix: 3,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.199, -10.206],
                                  [0, 0],
                                  [-10.634, -0.086],
                                  [-12.005, -0.071],
                                  [-3.674, 0.116],
                                  [3.096, 5.163],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.762, 1.254],
                                  [0, 0],
                                  [9.686, 0.078],
                                  [23.475, 0.14],
                                  [0.752, -5.235],
                                  [-6.177, -10.299],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-41.118, -19.362],
                                  [-48.009, 18.597],
                                  [-46.915, 19.127],
                                  [-28.912, 19.28],
                                  [5.175, 19.523],
                                  [50.335, 18.864],
                                  [46.385, 2.983],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.199, -10.206],
                                  [0, 0],
                                  [-10.634, -0.086],
                                  [-12.005, -0.071],
                                  [-3.674, 0.116],
                                  [3.096, 5.163],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.762, 1.254],
                                  [0, 0],
                                  [9.686, 0.078],
                                  [23.475, 0.14],
                                  [0.752, -5.235],
                                  [-6.177, -10.299],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-43.324, -18.994],
                                  [-48.009, 18.597],
                                  [-46.915, 19.127],
                                  [-28.912, 19.28],
                                  [5.175, 19.523],
                                  [50.335, 18.864],
                                  [46.385, 2.983],
                                ],
                              },
                            ],
                            t: 1,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.199, -10.206],
                                  [0, 0],
                                  [-10.634, -0.086],
                                  [-12.005, -0.071],
                                  [-3.674, 0.116],
                                  [3.096, 5.163],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.762, 1.254],
                                  [0, 0],
                                  [9.686, 0.078],
                                  [23.475, 0.14],
                                  [0.752, -5.235],
                                  [-6.177, -10.299],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-41.118, -19.362],
                                  [-48.009, 18.597],
                                  [-46.915, 19.127],
                                  [-28.912, 19.28],
                                  [5.175, 19.523],
                                  [50.335, 18.864],
                                  [46.385, 2.983],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.278, 4.176],
                                  [9.223, -0.7],
                                  [7.69, 0.892],
                                  [7.667, 9.119],
                                  [-6.169, -14.462],
                                  [-1.384, -0.664],
                                  [-10.754, -0.383],
                                  [-11.631, 0.824],
                                  [-3.633, 0.621],
                                  [5.458, 6.041],
                                ],
                                o: [
                                  [-6.643, 0.102],
                                  [-7.164, -5.535],
                                  [-6.381, -0.746],
                                  [-1.242, 13.441],
                                  [0.922, 2.279],
                                  [1.127, 0.541],
                                  [11.661, 0.883],
                                  [21.475, -1.14],
                                  [1.444, -5.152],
                                  [-6.9, -9.063],
                                ],
                                v: [
                                  [17.195, -23.738],
                                  [-6.154, -16.331],
                                  [-20.278, -26.283],
                                  [-39.821, -22.705],
                                  [-46.542, 14.148],
                                  [-42.898, 16.815],
                                  [-22.789, 18.299],
                                  [12.016, 17.343],
                                  [50.572, 13.574],
                                  [43.377, -4.444],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [9.597, 4.896],
                                  [9.223, -0.7],
                                  [7.595, 1.074],
                                  [7.555, 10.6],
                                  [-6.146, -17.685],
                                  [-2.432, -1.167],
                                  [-10.844, -0.609],
                                  [-11.348, 1.502],
                                  [-3.603, 1.004],
                                  [7.246, 6.706],
                                ],
                                o: [
                                  [-5.821, 0.889],
                                  [-7.164, -5.535],
                                  [-6.348, -0.904],
                                  [-0.457, 13.263],
                                  [1.043, 3.056],
                                  [1.981, 0.95],
                                  [13.156, 1.493],
                                  [19.96, -2.109],
                                  [1.969, -5.09],
                                  [-7.449, -8.128],
                                ],
                                v: [
                                  [15.908, -27.079],
                                  [-7.996, -18.223],
                                  [-20.776, -28.593],
                                  [-39.146, -27.421],
                                  [-45.432, 10.779],
                                  [-39.856, 15.064],
                                  [-18.152, 17.556],
                                  [17.197, 15.693],
                                  [50.751, 9.567],
                                  [40.792, -12.252],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [8.139, 6.665],
                                  [9.363, -0.711],
                                  [7.486, 1.519],
                                  [5.213, 13.618],
                                  [-0.566, -21.674],
                                  [-4.938, -2.368],
                                  [-11.221, -1.149],
                                  [-14.328, 4.584],
                                  [-3.584, 1.919],
                                  [11.568, 8.373],
                                ],
                                o: [
                                  [-3.972, 2.757],
                                  [-7.272, -5.619],
                                  [-6.366, -1.292],
                                  [-1.875, 7.482],
                                  [1.344, 4.932],
                                  [4.022, 1.929],
                                  [9.616, 1.886],
                                  [18.6, -7.084],
                                  [0.152, -6.575],
                                  [-8.852, -6.047],
                                ],
                                v: [
                                  [10.794, -37.376],
                                  [-14.776, -24.974],
                                  [-26.166, -32.227],
                                  [-40.549, -32.567],
                                  [-48.503, -0.551],
                                  [-39.772, 9.939],
                                  [-11.832, 15.752],
                                  [22.631, 8.486],
                                  [50.18, -3.131],
                                  [32.885, -25.356],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.437, 8.236],
                                  [9.223, -0.7],
                                  [7.773, 2.791],
                                  [2.515, 13.394],
                                  [-6.56, -18.84],
                                  [-7.296, -3.499],
                                  [-11.325, 1.17],
                                  [-8.063, 3.455],
                                  [-3.459, 2.778],
                                  [11.662, 5.069],
                                ],
                                o: [
                                  [-2.005, 4.542],
                                  [-7.164, -5.535],
                                  [-6.068, -2.179],
                                  [-1.548, 11.054],
                                  [2.253, 6.47],
                                  [5.943, 2.85],
                                  [13.378, -1.382],
                                  [10, -4.285],
                                  [4.403, -4.8],
                                  [-9.992, -3.786],
                                ],
                                v: [
                                  [6.624, -42.585],
                                  [-17.807, -25.827],
                                  [-30.773, -35.01],
                                  [-47.696, -42.912],
                                  [-51.992, -5.493],
                                  [-37.453, 6.302],
                                  [-3.584, 11.622],
                                  [26.283, 3.402],
                                  [43.968, -7.899],
                                  [26.247, -32.545],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [8.298, 6.56],
                                  [9.06, -1.509],
                                  [7.866, 2.32],
                                  [6.661, 10.04],
                                  [-2.758, -13.867],
                                  [-7.227, -3.601],
                                  [-11.062, -1.712],
                                  [-11.547, 6.448],
                                  [-1.709, 4.398],
                                  [12.362, 3.895],
                                ],
                                o: [
                                  [-1.277, 4.762],
                                  [-7.576, -4.84],
                                  [-6.141, -1.811],
                                  [-0.846, 10.193],
                                  [1.341, 6.74],
                                  [5.622, 2.788],
                                  [11.005, 0.355],
                                  [9.712, -6.336],
                                  [4.225, -6.053],
                                  [-10.196, -2.932],
                                ],
                                v: [
                                  [3.252, -40.942],
                                  [-17.989, -21.743],
                                  [-31.341, -31.573],
                                  [-46.7, -37.563],
                                  [-52.35, -5.664],
                                  [-38.091, 6.744],
                                  [-5.588, 15.465],
                                  [25.658, 7.417],
                                  [44.565, -8.067],
                                  [24.661, -32.05],
                                ],
                              },
                            ],
                            t: 13,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [9.686, 4.887],
                                  [8.841, -2.242],
                                  [7.94, 1.821],
                                  [6.058, 13.923],
                                  [-1.78, -8.496],
                                  [-6.709, -3.472],
                                  [-10.845, -1.664],
                                  [-10.448, 4.379],
                                  [-2.289, 3.799],
                                  [10.932, 3.614],
                                ],
                                o: [
                                  [-1.23, 4.533],
                                  [-7.897, -4.164],
                                  [-6.219, -1.423],
                                  [-0.068, 11.148],
                                  [1.298, 6.414],
                                  [4.969, 2.555],
                                  [11.66, 1.485],
                                  [11.16, -4.913],
                                  [4.525, -7.922],
                                  [-10.019, -3.061],
                                ],
                                v: [
                                  [1.852, -36.994],
                                  [-18.933, -17.918],
                                  [-33.577, -26.837],
                                  [-46.42, -33.218],
                                  [-52.266, -2.633],
                                  [-39.092, 9.608],
                                  [-8.237, 19.169],
                                  [20.13, 16.523],
                                  [41.456, -1.861],
                                  [24.915, -29.232],
                                ],
                              },
                            ],
                            t: 14,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.073, 3.215],
                                  [8.622, -2.974],
                                  [8.015, 1.321],
                                  [9.276, 13.463],
                                  [-1.573, -4.834],
                                  [-6.192, -3.343],
                                  [-10.628, -1.617],
                                  [-9.591, 3.492],
                                  [-2.869, 3.2],
                                  [9.503, 3.332],
                                ],
                                o: [
                                  [-1.182, 4.304],
                                  [-8.218, -3.488],
                                  [-6.297, -1.035],
                                  [-3.316, 9.505],
                                  [1.487, 6.046],
                                  [4.316, 2.322],
                                  [12.315, 2.616],
                                  [14.088, -6.385],
                                  [4.824, -9.792],
                                  [-9.841, -3.189],
                                ],
                                v: [
                                  [0.056, -34.312],
                                  [-19.877, -14.092],
                                  [-31.328, -22.886],
                                  [-45.973, -27.891],
                                  [-52.182, 0.397],
                                  [-40.094, 12.473],
                                  [-10.885, 22.872],
                                  [18.026, 25.46],
                                  [38.514, 4.797],
                                  [25.499, -23.312],
                                ],
                              },
                            ],
                            t: 15,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.44, 2.915],
                                  [8.694, -2.747],
                                  [8, 1.148],
                                  [9.666, 12.22],
                                  [-3.697, -9.426],
                                  [-5.28, -2.905],
                                  [-10.549, -1.421],
                                  [-9.74, 2.821],
                                  [-3.332, 3.392],
                                  [8.418, 4.219],
                                ],
                                o: [
                                  [-2.138, 3.665],
                                  [-8.121, -3.7],
                                  [-6.326, -0.903],
                                  [-0.728, 10.099],
                                  [1.382, 5.371],
                                  [3.574, 1.966],
                                  [12.257, 3.419],
                                  [16.13, -5.284],
                                  [5.089, -9.007],
                                  [-9.2, -4.618],
                                ],
                                v: [
                                  [-0.588, -32.227],
                                  [-18.849, -12.609],
                                  [-32.427, -23.172],
                                  [-49.413, -25.505],
                                  [-51.56, 3.446],
                                  [-41.152, 14.113],
                                  [-14.268, 24.984],
                                  [15.095, 26.642],
                                  [39.631, 6.379],
                                  [28.359, -21.229],
                                ],
                              },
                            ],
                            t: 16,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.806, 2.615],
                                  [8.766, -2.519],
                                  [7.985, 0.975],
                                  [9.216, 12.405],
                                  [-5.82, -14.018],
                                  [-4.368, -2.468],
                                  [-10.471, -1.224],
                                  [-9.889, 2.149],
                                  [-3.795, 3.583],
                                  [7.334, 5.107],
                                ],
                                o: [
                                  [-3.093, 3.026],
                                  [-8.024, -3.913],
                                  [-6.356, -0.771],
                                  [-2.205, 13.058],
                                  [1.276, 4.695],
                                  [2.831, 1.61],
                                  [12.198, 4.222],
                                  [18.172, -4.182],
                                  [5.355, -8.223],
                                  [-8.558, -6.047],
                                ],
                                v: [
                                  [2.275, -27.466],
                                  [-17.821, -11.125],
                                  [-30.923, -22.624],
                                  [-48.313, -25.091],
                                  [-50.937, 6.494],
                                  [-42.21, 15.753],
                                  [-17.65, 27.097],
                                  [12.165, 27.824],
                                  [40.747, 7.961],
                                  [34.726, -16.47],
                                ],
                              },
                            ],
                            t: 17,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.806, 2.615],
                                  [8.766, -2.519],
                                  [7.985, 0.975],
                                  [9.216, 12.405],
                                  [-5.82, -14.018],
                                  [-4.368, -2.468],
                                  [-10.471, -1.224],
                                  [-9.889, 2.149],
                                  [-3.795, 3.583],
                                  [7.334, 5.107],
                                ],
                                o: [
                                  [-3.093, 3.026],
                                  [-8.024, -3.913],
                                  [-6.356, -0.771],
                                  [-2.205, 13.058],
                                  [1.276, 4.695],
                                  [2.831, 1.61],
                                  [12.198, 4.222],
                                  [18.172, -4.182],
                                  [5.355, -8.223],
                                  [-8.558, -6.047],
                                ],
                                v: [
                                  [2.275, -27.466],
                                  [-17.821, -11.125],
                                  [-30.923, -22.624],
                                  [-46.624, -25.23],
                                  [-50.937, 6.494],
                                  [-42.21, 15.753],
                                  [-17.65, 27.097],
                                  [12.165, 27.824],
                                  [40.747, 7.961],
                                  [34.726, -16.47],
                                ],
                              },
                            ],
                            t: 18,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.978, 1.477],
                                  [8.827, -2.677],
                                  [7.893, 0.726],
                                  [7.952, 6.9],
                                  [-4.872, -8.703],
                                  [-1.728, -1.015],
                                  [-10.513, -0.557],
                                  [-11.022, 0.703],
                                  [-2.945, 1.306],
                                  [2.425, 3.298],
                                ],
                                o: [
                                  [-5.892, 1.484],
                                  [-8.197, -3.748],
                                  [-6.405, -0.588],
                                  [-2.558, 12.839],
                                  [0.966, 2.636],
                                  [1.044, 0.627],
                                  [10.345, 0.998],
                                  [21.844, -1.471],
                                  [0.692, -6.87],
                                  [-6.86, -9.045],
                                ],
                                v: [
                                  [10.174, -23.227],
                                  [-13.276, -8.798],
                                  [-28.057, -23.035],
                                  [-45.236, -19.925],
                                  [-49.335, 15.174],
                                  [-45.265, 19.365],
                                  [-25.4, 23.739],
                                  [2.758, 24.96],
                                  [44.627, 4.523],
                                  [40.793, -8.351],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.443, 2.956],
                                  [9.163, -0.955],
                                  [7.854, 0.689],
                                  [8.331, 8.28],
                                  [-5.108, -15.713],
                                  [-0.864, -0.508],
                                  [-10.569, -0.05],
                                  [-11.507, 0.622],
                                  [-3.379, 0.6],
                                  [3.942, 5.346],
                                ],
                                o: [
                                  [-6.881, 0.014],
                                  [-7.288, -5.31],
                                  [-6.415, -0.562],
                                  [-0.707, 9.749],
                                  [0.895, 1.925],
                                  [0.522, 0.313],
                                  [10.011, 0.291],
                                  [22.648, -1.265],
                                  [1.048, -5.99],
                                  [-6.777, -9.508],
                                ],
                                v: [
                                  [14.06, -21.259],
                                  [-9.907, -13.116],
                                  [-24.041, -24.576],
                                  [-42.788, -23.49],
                                  [-48.528, 18.092],
                                  [-45.933, 20.424],
                                  [-26.826, 22.967],
                                  [3.31, 22.671],
                                  [50.232, 14.284],
                                  [44.002, -3.57],
                                ],
                              },
                            ],
                            t: 20,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.712, -9.877],
                                  [0, 0],
                                  [-10.625, 0.457],
                                  [-11.992, 0.541],
                                  [-3.663, 0.304],
                                  [3.356, 4.998],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.825, 1.213],
                                  [0, 0],
                                  [9.678, -0.416],
                                  [23.451, -1.059],
                                  [0.484, -5.266],
                                  [-6.694, -9.97],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-41.118, -19.362],
                                  [-47.721, 21.009],
                                  [-46.602, 21.483],
                                  [-28.253, 22.194],
                                  [5.609, 21.464],
                                  [52.068, 18.721],
                                  [45.753, 0.599],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.302, 5.403],
                                  [9.176, 1.165],
                                  [5.084, -2.509],
                                  [6.353, 8.487],
                                  [-4.202, -11.178],
                                  [0, 0],
                                  [-10.435, -2.053],
                                  [-11.784, -2.293],
                                  [-3.632, -0.566],
                                  [-1.181, 5.903],
                                ],
                                o: [
                                  [-7.384, -2.469],
                                  [-5.908, -6.86],
                                  [-5.782, 2.853],
                                  [-6.698, 14.804],
                                  [0.516, 1.373],
                                  [0, 0],
                                  [9.504, 1.87],
                                  [23.043, 4.484],
                                  [1.708, -5.005],
                                  [4.448, -22.232],
                                ],
                                v: [
                                  [22.898, -22.349],
                                  [-4.25, -21.089],
                                  [-22.373, -23.905],
                                  [-44.82, -17.886],
                                  [-53.09, 35.477],
                                  [-48.815, 36.514],
                                  [-31.824, 36.285],
                                  [-1.416, 28.071],
                                  [43.449, 33.565],
                                  [55.586, 19.697],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.852, 4.192],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.996, -9.678],
                                  [-1.219, -1.481],
                                  [-8.167, 4.103],
                                  [-16.427, -0.169],
                                  [-2.938, -6.081],
                                  [5.107, 4.768],
                                ],
                                o: [
                                  [-7.617, -1.61],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.859, 1.189],
                                  [0.988, -5.528],
                                  [10.651, -5.351],
                                  [11.875, 0.122],
                                  [4.366, -5.617],
                                  [-8.778, -8.195],
                                ],
                                v: [
                                  [17.672, -29.52],
                                  [-6.073, -27.87],
                                  [-20.83, -34.156],
                                  [-41.923, -27.4],
                                  [-52.34, 24.148],
                                  [-50.361, 31.032],
                                  [-20.204, 12.357],
                                  [19.686, -2.886],
                                  [56.714, 13.987],
                                  [51.631, -7.994],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [954.82, 850.825], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 2',
                  ix: 4,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.857, 0.259],
                                  [-7.807, 0.034],
                                  [-6.089, -0.14],
                                  [0, 0],
                                  [0, 0],
                                  [15.962, 0.057],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.007, -0.031],
                                  [12.873, 0.296],
                                  [11.729, -0.374],
                                  [0, 0],
                                  [-10.942, -0.039],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.314, 7.096],
                                  [-25.247, 6.98],
                                  [-3.899, 7.096],
                                  [39.315, 7.096],
                                  [49.233, -7.096],
                                  [-3.811, -6.861],
                                  [-49.232, -7.096],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.857, 0.259],
                                  [-7.807, 0.034],
                                  [-6.09, -0.02],
                                  [0, 0],
                                  [0, 0],
                                  [15.962, 0.057],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.007, -0.031],
                                  [16.061, 0.052],
                                  [11.729, -0.374],
                                  [0, 0],
                                  [-10.942, -0.039],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.314, 7.096],
                                  [-25.247, 6.98],
                                  [-4.082, 6.728],
                                  [39.315, 7.096],
                                  [49.233, -7.096],
                                  [-3.811, -6.861],
                                  [-49.232, -7.096],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.607, -0.167],
                                  [-8.03, -0.141],
                                  [-7.111, 0.319],
                                  [-0.245, 0.149],
                                  [0.144, 0.165],
                                  [16.427, 0.321],
                                  [0.145, 0.44],
                                ],
                                o: [
                                  [0.944, 0.456],
                                  [6.471, -0.044],
                                  [14.149, -0.087],
                                  [11.122, -0.47],
                                  [-0.396, 0.151],
                                  [-11.245, -0.217],
                                  [-0.034, -0.103],
                                ],
                                v: [
                                  [-39.713, 6.484],
                                  [-23.786, 7.14],
                                  [-0.982, 6.778],
                                  [40.08, 6.005],
                                  [49.243, -9.22],
                                  [-2.926, -7.934],
                                  [-48.482, -8.778],
                                ],
                              },
                            ],
                            t: 8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.169, -0.915],
                                  [-8.422, -0.45],
                                  [-8.902, 0.913],
                                  [-0.674, 0.41],
                                  [0.397, 0.456],
                                  [17.241, 0.783],
                                  [0.399, 1.214],
                                ],
                                o: [
                                  [2.6, 1.255],
                                  [5.531, -0.068],
                                  [10.793, -0.333],
                                  [10.056, -0.639],
                                  [-1.092, 0.416],
                                  [-11.777, -0.53],
                                  [-0.094, -0.285],
                                ],
                                v: [
                                  [-40.411, 5.41],
                                  [-21.222, 7.422],
                                  [5.448, 5.829],
                                  [41.423, 4.09],
                                  [49.261, -12.949],
                                  [-1.371, -9.819],
                                  [-47.165, -11.731],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.647, -1.803],
                                  [-8.888, -0.816],
                                  [-11.03, 1.619],
                                  [-1.184, 0.72],
                                  [0.697, 0.801],
                                  [18.21, 1.333],
                                  [0.702, 2.133],
                                ],
                                o: [
                                  [4.569, 2.206],
                                  [8.094, 0.432],
                                  [14.221, -2.304],
                                  [8.789, -0.839],
                                  [-1.919, 0.732],
                                  [-12.41, -0.901],
                                  [-0.165, -0.5],
                                ],
                                v: [
                                  [-41.242, 4.132],
                                  [-18.173, 7.756],
                                  [14.55, 4.078],
                                  [43.869, -0.494],
                                  [49.282, -17.381],
                                  [0.477, -12.058],
                                  [-45.599, -15.24],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-9.58, -3.924],
                                  [-10.121, -1.692],
                                  [-9.324, 3.989],
                                  [-5.006, 2.096],
                                  [1.415, 1.626],
                                  [20.768, 2.639],
                                  [1.425, 4.33],
                                ],
                                o: [
                                  [9.242, 6.659],
                                  [10.508, -0.755],
                                  [12.672, -4.6],
                                  [5.938, -1.323],
                                  [-3.897, 1.485],
                                  [-14.087, -1.79],
                                  [-0.334, -1.015],
                                ],
                                v: [
                                  [-40.35, -1.852],
                                  [-8.837, 6.893],
                                  [25.223, -2.963],
                                  [49.886, -12.497],
                                  [49.004, -30.371],
                                  [3.633, -20.148],
                                  [-49.062, -24.579],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-8.227, -5.927],
                                  [-11.331, -0.285],
                                  [-10.733, 5.964],
                                  [-3.553, 2.159],
                                  [2.091, 2.402],
                                  [22.705, 3.884],
                                  [2.106, 6.398],
                                ],
                                o: [
                                  [13.657, 9.839],
                                  [16.339, 0.411],
                                  [17.027, -9.462],
                                  [2.909, -1.768],
                                  [-5.758, 2.195],
                                  [-15.346, -2.625],
                                  [-0.494, -1.5],
                                ],
                                v: [
                                  [-46.668, -8.94],
                                  [-10.851, 4.615],
                                  [24.194, -5.133],
                                  [46.597, -17.591],
                                  [41.984, -37.307],
                                  [3.609, -26.977],
                                  [-50.047, -32.166],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-8.074, -6.004],
                                  [-11.065, -2.623],
                                  [-10.416, 5.779],
                                  [-3.048, 2.72],
                                  [2.194, 2.296],
                                  [22.728, 2.417],
                                  [2.499, 5.942],
                                ],
                                o: [
                                  [13.461, 9.475],
                                  [12.948, 3.078],
                                  [16.108, -9.006],
                                  [4.12, -5.213],
                                  [-5.082, 2.872],
                                  [-15.361, -1.634],
                                  [-0.726, -1.429],
                                ],
                                v: [
                                  [-46.386, -7.216],
                                  [-10.369, 5.257],
                                  [24.392, -0.328],
                                  [45.799, -17.234],
                                  [42.739, -37.189],
                                  [5.331, -21.3],
                                  [-50.796, -31.946],
                                ],
                              },
                            ],
                            t: 13,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-8.26, -5.365],
                                  [-10.683, -2.487],
                                  [-9.281, 4.691],
                                  [-3.454, 5.046],
                                  [2.591, 2.84],
                                  [21.717, -1.068],
                                  [2.926, 4.342],
                                ],
                                o: [
                                  [11.411, 7.604],
                                  [11.192, 2.699],
                                  [13.976, -6.986],
                                  [5.99, -11.327],
                                  [-3.359, 3.456],
                                  [-14.697, 0.721],
                                  [-1.071, -1.117],
                                ],
                                v: [
                                  [-43.098, -1.625],
                                  [-14.575, 11.42],
                                  [21.924, 12.977],
                                  [41.824, -5.364],
                                  [37.821, -31.049],
                                  [4.842, -10.635],
                                  [-51.711, -25.074],
                                ],
                              },
                            ],
                            t: 15,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-9.167, -3.819],
                                  [-9.872, -1.849],
                                  [-8.299, 2.659],
                                  [-6.503, 7.345],
                                  [3.324, 4.021],
                                  [19.997, -0.051],
                                  [2.4, 2.743],
                                ],
                                o: [
                                  [7.996, 5.144],
                                  [9.571, 1.923],
                                  [13.193, -3.741],
                                  [6.449, -6.793],
                                  [-1.92, 2.907],
                                  [-13.573, 0.034],
                                  [-0.948, -0.742],
                                ],
                                v: [
                                  [-42.273, 3.795],
                                  [-14.485, 15.803],
                                  [17.733, 15.217],
                                  [42.429, -2.624],
                                  [42.342, -26.78],
                                  [2.698, -9.951],
                                  [-51.258, -18.732],
                                ],
                              },
                            ],
                            t: 17,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-9.167, -3.819],
                                  [-9.872, -1.849],
                                  [-8.362, 2.456],
                                  [-6.68, 7.184],
                                  [3.324, 4.021],
                                  [19.997, -0.051],
                                  [2.4, 2.743],
                                ],
                                o: [
                                  [7.996, 5.144],
                                  [9.571, 1.923],
                                  [13.28, -3.418],
                                  [6.613, -6.634],
                                  [-1.92, 2.907],
                                  [-13.573, 0.034],
                                  [-0.948, -0.742],
                                ],
                                v: [
                                  [-42.273, 3.795],
                                  [-14.485, 15.803],
                                  [17.18, 14.61],
                                  [42.304, -2.622],
                                  [42.342, -26.78],
                                  [2.698, -9.951],
                                  [-51.258, -18.732],
                                ],
                              },
                            ],
                            t: 18,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.739, -1.394],
                                  [-8.636, -0.748],
                                  [-6.814, 1.131],
                                  [-2.392, 4.369],
                                  [1.139, 0.049],
                                  [17.55, 0.257],
                                  [1.068, 0.972],
                                ],
                                o: [
                                  [3.145, 1.957],
                                  [7.884, 0.752],
                                  [11.862, -0.753],
                                  [8.291, -6.053],
                                  [-0.417, 1.385],
                                  [-11.976, -0.174],
                                  [-0.443, -0.277],
                                ],
                                v: [
                                  [-40.548, 9.307],
                                  [-19.699, 13.721],
                                  [6.101, 13.455],
                                  [43.277, -3.528],
                                  [43.52, -22.675],
                                  [-2.794, -8.726],
                                  [-50.305, -10.224],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.828, 0.863],
                                  [-7.806, -0.113],
                                  [-7.773, 0.424],
                                  [0, 0],
                                  [0, 0],
                                  [15.944, -0.758],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [8.087, 0.117],
                                  [16.694, -0.91],
                                  [12.409, -1.877],
                                  [0, 0],
                                  [-10.929, 0.519],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.314, 8.988],
                                  [-23.91, 9.63],
                                  [4.759, 9.609],
                                  [41.809, 7.937],
                                  [50.952, -7.242],
                                  [-3.401, -4.519],
                                  [-48.944, -4.68],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.7, -1.942],
                                  [-7.561, -1.945],
                                  [-7.655, -1.416],
                                  [0, 0],
                                  [0, 0],
                                  [15.676, 3.012],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.833, 2.015],
                                  [16.44, 3.041],
                                  [12.503, 1.093],
                                  [0, 0],
                                  [-10.745, -2.065],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.319, 15.613],
                                  [-33.402, 9.18],
                                  [-5.532, 15.9],
                                  [20.125, 16.353],
                                  [42.392, 7.62],
                                  [-10.141, 0.248],
                                  [-52.438, -0.17],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-2.065, -4.797],
                                  [-6.253, -0.986],
                                  [-10.682, 4.179],
                                  [7.938, 11.962],
                                  [0, 0],
                                  [14.386, 2.58],
                                  [1.421, -2.463],
                                ],
                                o: [
                                  [2.833, 6.583],
                                  [8.247, 1.3],
                                  [9.242, -3.616],
                                  [-3.954, -7.779],
                                  [0, 0],
                                  [-8.861, -1.589],
                                  [-2.899, 5.022],
                                ],
                                v: [
                                  [-39.74, 12.308],
                                  [-10.295, 23.902],
                                  [16.321, 16.949],
                                  [39.328, -7.697],
                                  [27.987, -20.815],
                                  [18.399, -22.825],
                                  [-40.891, -0.99],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [955.975, 876.533], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 8',
                  ix: 5,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.348, 6.543],
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.349, -6.542],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.348, -6.542],
                                  [0, 0],
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.349, 6.543],
                                ],
                                v: [
                                  [-9.73, 17.629],
                                  [-2.836, 17.629],
                                  [13.263, 5.782],
                                  [17.325, -5.531],
                                  [9.732, -17.377],
                                  [-0.226, -17.629],
                                  [-13.263, -5.531],
                                  [-17.323, 5.782],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.348, 6.543],
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.349, -6.542],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.348, -6.542],
                                  [0, 0],
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.349, 6.543],
                                ],
                                v: [
                                  [-9.73, 17.629],
                                  [-2.836, 17.629],
                                  [13.263, 5.782],
                                  [15.575, -7.531],
                                  [8.982, -20.377],
                                  [-6.476, -19.879],
                                  [-14.013, -5.281],
                                  [-17.323, 5.782],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.439, 0.235],
                                  [0, 0],
                                  [-2.076, 6.524],
                                  [0, 0],
                                  [6.382, 0.525],
                                  [1.102, -0.821],
                                  [1.53, -6.756],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.439, -0.235],
                                  [0, 0],
                                  [1.704, -6.577],
                                  [0, 0],
                                  [-6.384, 0.58],
                                  [0, 0],
                                  [-2.077, 6.524],
                                ],
                                v: [
                                  [-8.962, 12.959],
                                  [-2.176, 12.711],
                                  [13.242, 0.473],
                                  [16.214, -11.207],
                                  [9.506, -23.928],
                                  [-5.443, -22.449],
                                  [-13.642, -7.962],
                                  [-16.851, 2.522],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.36, 0.413],
                                  [0, 0],
                                  [-1.87, 6.51],
                                  [0, 0],
                                  [6.26, 0.922],
                                  [1.936, -1.443],
                                  [0.909, -6.919],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.36, -0.413],
                                  [0, 0],
                                  [1.216, -6.604],
                                  [0, 0],
                                  [-6.264, 1.02],
                                  [0, 0],
                                  [-1.871, 6.51],
                                ],
                                v: [
                                  [-9.876, 9.242],
                                  [-3.173, 8.806],
                                  [11.731, -3.728],
                                  [15.203, -14.173],
                                  [8.408, -26.799],
                                  [-6.158, -24.576],
                                  [-14.857, -10.173],
                                  [-16.801, -2.13],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.135, 2.055],
                                  [0, 0],
                                  [-0.147, 6.874],
                                  [0, 0],
                                  [6.188, 0.662],
                                  [3.637, -3.287],
                                  [-1.874, -7.518],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.135, -2.055],
                                  [0, 0],
                                  [-1.194, -6.939],
                                  [0, 0],
                                  [-5.824, 3.263],
                                  [0, 0],
                                  [-0.148, 6.874],
                                ],
                                v: [
                                  [-9.819, 6.576],
                                  [-3.353, 4.41],
                                  [8.023, -11.757],
                                  [8.914, -22.049],
                                  [0.464, -34.241],
                                  [-18.44, -26.698],
                                  [-20.026, -8.197],
                                  [-18.29, -3.054],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.795, 3.037],
                                  [0, 0],
                                  [0.957, 6.885],
                                  [0, 0],
                                  [5.873, 0.978],
                                  [5.374, -4.857],
                                  [-3.943, -7.837],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.795, -3.036],
                                  [0, 0],
                                  [-2.938, -6.983],
                                  [0, 0],
                                  [-5.335, 4.822],
                                  [0, 0],
                                  [0.956, 6.886],
                                ],
                                v: [
                                  [-12.148, 3.324],
                                  [-6.04, 0.123],
                                  [2.719, -17.843],
                                  [3.592, -26.54],
                                  [-6.716, -37.467],
                                  [-24.283, -27.849],
                                  [-20.617, -6.962],
                                  [-24.372, -3.646],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.314, 3.737],
                                  [0, 0],
                                  [1.986, 6.611],
                                  [0, 0],
                                  [5.682, -0.125],
                                  [4.536, -5.583],
                                  [-4.594, -7.402],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.225, -3.86],
                                  [0, 0],
                                  [-3.944, -6.406],
                                  [0, 0],
                                  [-4.503, 5.543],
                                  [0, 0],
                                  [1.343, 6.771],
                                ],
                                v: [
                                  [-9.831, 7.418],
                                  [-4.063, 3.822],
                                  [0.824, -16.412],
                                  [0.108, -24.498],
                                  [-6.613, -36.837],
                                  [-21.241, -23.926],
                                  [-20.769, -2.838],
                                  [-25.818, 0.843],
                                ],
                              },
                            ],
                            t: 13,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.084, 3.877],
                                  [0, 0],
                                  [2.357, 6.363],
                                  [0, 0],
                                  [5.433, -0.871],
                                  [3.685, -5.587],
                                  [-4.764, -6.869],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [4.887, -4.11],
                                  [0, 0],
                                  [-4.151, -5.992],
                                  [0, 0],
                                  [-4.063, 5.601],
                                  [0, 0],
                                  [1.745, 6.544],
                                ],
                                v: [
                                  [-10.916, 13.397],
                                  [-4.928, 10.121],
                                  [0.067, -14.978],
                                  [-0.644, -20.307],
                                  [-7.322, -34.371],
                                  [-20.622, -19.027],
                                  [-21.986, 0.379],
                                  [-25.115, 4.22],
                                ],
                              },
                            ],
                            t: 14,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-4.855, 4.017],
                                  [0, 0],
                                  [2.728, 6.114],
                                  [0, 0],
                                  [5.184, -1.618],
                                  [2.833, -5.592],
                                  [-4.933, -6.337],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [4.549, -4.36],
                                  [0, 0],
                                  [-4.358, -5.578],
                                  [0, 0],
                                  [-3.623, 5.66],
                                  [0, 0],
                                  [2.146, 6.317],
                                ],
                                v: [
                                  [-12, 19.377],
                                  [-5.792, 16.419],
                                  [-0.359, -10.442],
                                  [-2.596, -17.556],
                                  [-7.7, -28.804],
                                  [-20.002, -14.128],
                                  [-23.204, 3.596],
                                  [-24.412, 7.596],
                                ],
                              },
                            ],
                            t: 15,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.069, 3.526],
                                  [0, 0],
                                  [2.082, 6.213],
                                  [0, 0],
                                  [5.3, -1.557],
                                  [2.445, -4.786],
                                  [-3.963, -6.371],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [4.842, -3.82],
                                  [0, 0],
                                  [-3.481, -5.758],
                                  [0, 0],
                                  [-4.052, 4.937],
                                  [0, 0],
                                  [1.45, 6.373],
                                ],
                                v: [
                                  [-10.997, 18.456],
                                  [-5.435, 17.216],
                                  [-1.16, -8.227],
                                  [-2.779, -15.785],
                                  [-8.589, -27.807],
                                  [-20.13, -11.902],
                                  [-22.862, 3.819],
                                  [-23.824, 7.905],
                                ],
                              },
                            ],
                            t: 16,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.283, 3.035],
                                  [0, 0],
                                  [1.437, 6.312],
                                  [0, 0],
                                  [5.416, -1.496],
                                  [2.056, -3.98],
                                  [-2.994, -6.405],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.135, -3.279],
                                  [0, 0],
                                  [-2.605, -5.938],
                                  [0, 0],
                                  [-4.481, 4.213],
                                  [0, 0],
                                  [0.754, 6.43],
                                ],
                                v: [
                                  [-9.993, 17.535],
                                  [-5.078, 18.012],
                                  [1.546, -3.335],
                                  [0.546, -11.338],
                                  [-5.971, -24.134],
                                  [-20.258, -9.676],
                                  [-22.521, 4.042],
                                  [-23.235, 8.214],
                                ],
                              },
                            ],
                            t: 17,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.283, 3.035],
                                  [0, 0],
                                  [1.437, 6.312],
                                  [0, 0],
                                  [5.416, -1.496],
                                  [2.056, -3.98],
                                  [-2.994, -6.405],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.135, -3.279],
                                  [0, 0],
                                  [-2.605, -5.938],
                                  [0, 0],
                                  [-4.481, 4.213],
                                  [0, 0],
                                  [0.754, 6.43],
                                ],
                                v: [
                                  [-9.993, 17.535],
                                  [-5.078, 18.012],
                                  [1.546, -3.335],
                                  [0.546, -11.338],
                                  [-5.971, -24.134],
                                  [-20.258, -9.676],
                                  [-22.521, 4.042],
                                  [-23.235, 8.214],
                                ],
                              },
                            ],
                            t: 18,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.128, 1.258],
                                  [0, 0],
                                  [0.163, 6.581],
                                  [0, 0],
                                  [5.945, -1.589],
                                  [0.771, -1.607],
                                  [-0.94, -6.514],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.868, -2.236],
                                  [0, 0],
                                  [-0.628, -6.415],
                                  [0, 0],
                                  [-5.591, 2.596],
                                  [0, 0],
                                  [-0.942, 6.581],
                                ],
                                v: [
                                  [-14.429, 17.677],
                                  [-5.763, 19.738],
                                  [7.596, -0.063],
                                  [7.875, -10.152],
                                  [-1.943, -19.808],
                                  [-16.385, -11.79],
                                  [-22.051, 2.441],
                                  [-24.033, 7.389],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.314, 0.997],
                                  [0, 0],
                                  [-1.219, 6.672],
                                  [0, 0],
                                  [6.292, -0.713],
                                  [0.487, -0.753],
                                  [1.055, -6.658],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.314, -0.997],
                                  [0, 0],
                                  [0.979, -6.62],
                                  [0, 0],
                                  [-6.186, 1.24],
                                  [0, 0],
                                  [-1.442, 6.628],
                                ],
                                v: [
                                  [-12.871, 16.784],
                                  [-6.461, 16.538],
                                  [9.95, 1.261],
                                  [12.248, -9.594],
                                  [3.716, -20.611],
                                  [-8.906, -17.205],
                                  [-18.465, -4.683],
                                  [-21.392, 5.759],
                                ],
                              },
                            ],
                            t: 20,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.501, 0.735],
                                  [0, 0],
                                  [-1.941, 6.675],
                                  [0, 0],
                                  [6.53, -0.403],
                                  [0, 0],
                                  [1.942, -6.674],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.501, -0.735],
                                  [0, 0],
                                  [1.941, -6.674],
                                  [0, 0],
                                  [-6.53, 0.403],
                                  [0, 0],
                                  [-1.942, 6.675],
                                ],
                                v: [
                                  [-11.314, 15.892],
                                  [-4.463, 15.117],
                                  [11.778, 2.248],
                                  [15.136, -9.294],
                                  [6.828, -20.65],
                                  [-3.125, -20.289],
                                  [-15.393, -7.412],
                                  [-18.75, 4.13],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-7.269, -1.355],
                                  [0, 0],
                                  [-4.1, 5.856],
                                  [0, 0],
                                  [7.328, 0.987],
                                  [0, 0],
                                  [3.06, -6.461],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.391, 0.231],
                                  [0, 0],
                                  [3.704, -6.113],
                                  [0, 0],
                                  [-7.328, -0.987],
                                  [0, 0],
                                  [-3.06, 6.462],
                                ],
                                v: [
                                  [-11.72, 19.124],
                                  [-0.449, 16.677],
                                  [20.73, 1.374],
                                  [22.341, -7.589],
                                  [14.878, -19.301],
                                  [-1.97, -20.37],
                                  [-13.446, -6.516],
                                  [-21.04, 1.002],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.535, -0.297],
                                  [0, 0],
                                  [-3.679, 5.898],
                                  [0, 0],
                                  [6.142, 2.534],
                                  [0, 0],
                                  [3.68, -5.897],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.535, 0.297],
                                  [0, 0],
                                  [3.678, -5.897],
                                  [0, 0],
                                  [-6.491, -0.818],
                                  [0, 0],
                                  [-3.149, 6.198],
                                ],
                                v: [
                                  [-12.406, 6.427],
                                  [-5.518, 6.74],
                                  [13.918, -5.166],
                                  [18.896, -12.816],
                                  [19.027, -24.745],
                                  [2.185, -28.81],
                                  [-12.493, -22.434],
                                  [-20.393, -6.773],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [957.152, 832.241], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 9,
              parent: 12,
            },
            {
              ty: 4,
              nm: 'right bottom leg ',
              sr: 1,
              st: -48,
              op: 22,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [880, 769, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [907.497, 774.341, 0],
                      t: 0,
                      ti: [-0.865, 0.184, 0],
                      to: [-0.178, -0.099, 0],
                    },
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [901.627, 765.665, 0],
                      t: 1,
                      ti: [-0.91, -0.437, 0],
                      to: [0.525, -0.112, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.077 },
                      i: { x: 0.584, y: 0.747 },
                      s: [894.809, 764.733, 0],
                      t: 2,
                      ti: [-2.536, -0.782, 0],
                      to: [0.771, 0.37, 0],
                    },
                    {
                      o: { x: 0.306, y: 1 },
                      i: { x: 0.833, y: 0.589 },
                      s: [904.84, 767.662, 0],
                      t: 3,
                      ti: [-1.182, -0.418, 0],
                      to: [2.29, 0.706, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.07 },
                      i: { x: 0.833, y: 0.93 },
                      s: [906.402, 768.495, 0],
                      t: 4,
                      ti: [0.373, 0.207, 0],
                      to: [2.186, 0.772, 0],
                    },
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [907.404, 769.604, 0],
                      t: 5,
                      ti: [3.339, 0.246, 0],
                      to: [-0.603, -0.334, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.102 },
                      i: { x: 0.833, y: 0.898 },
                      s: [907.216, 763.71, 0],
                      t: 7,
                      ti: [0.16, 0.089, 0],
                      to: [-2.165, -0.159, 0],
                    },
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.584, y: 0.654 },
                      s: [912.108, 758.331, 0],
                      t: 8,
                      ti: [0.174, -1.933, 0],
                      to: [-0.512, -0.284, 0],
                    },
                    {
                      o: { x: 0.417, y: 0.43 },
                      i: { x: 0.833, y: 1 },
                      s: [914.401, 763.24, 0],
                      t: 10,
                      ti: [-0.076, 0.032, 0],
                      to: [-0.173, 1.921, 0],
                    },
                    {
                      o: { x: 0.157, y: 0 },
                      i: { x: 0.684, y: 0.642 },
                      s: [910.975, 765.722, 0],
                      t: 12,
                      ti: [-0.193, -3.169, 0],
                      to: [0.128, -0.054, 0],
                    },
                    {
                      o: { x: 0.432, y: 0.869 },
                      i: { x: 0.785, y: 1 },
                      s: [907.468, 773.253, 0],
                      t: 17,
                      ti: [-0.106, -0.249, 0],
                      to: [0.038, 0.615, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.114 },
                      i: { x: 0.833, y: 0.886 },
                      s: [907.175, 772.766, 0],
                      t: 18,
                      ti: [-2.677, -0.404, 0],
                      to: [0.42, 0.99, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.145 },
                      i: { x: 0.833, y: 0.855 },
                      s: [922.257, 779.496, 0],
                      t: 20,
                      ti: [-0.572, -0.149, 0],
                      to: [1.47, 0.222, 0],
                    },
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [924.484, 780.673, 0],
                      t: 21,
                      ti: [1.02, 0.566, 0],
                      to: [1.613, 0.42, 0],
                    },
                    { s: [901.23, 771.725, 0], t: 22 },
                  ],
                  ix: 2,
                },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [15.97], t: 8 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [12], t: 18 },
                    { s: [30.65], t: 20 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-38.541, -7.725],
                                  [-34.373, 20.269],
                                  [6.355, 11.687],
                                  [5.134, -15.293],
                                ],
                              },
                            ],
                            t: -22,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-25.117, -16.712],
                                  [-25.809, 17.275],
                                  [16.12, 8.334],
                                  [19.812, -21.992],
                                ],
                              },
                            ],
                            t: -20,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [6.569, -5.843],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-23.582, -15.505],
                                  [-25.668, 16.734],
                                  [15.142, 8.542],
                                  [20.093, -23.074],
                                ],
                              },
                            ],
                            t: -18,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-25.444, -19.454],
                                  [-26.136, 14.533],
                                  [14.674, 6.341],
                                  [18.231, -27.023],
                                ],
                              },
                            ],
                            t: -15,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.58, -8.631],
                                  [-4.84, 3.889],
                                  [7.497, 4.925],
                                ],
                                o: [
                                  [-4.969, 6.82],
                                  [12.108, 12.707],
                                  [6.015, -3.024],
                                  [-3.335, -2.191],
                                ],
                                v: [
                                  [-17.56, -34.542],
                                  [-23.453, 0.335],
                                  [18.325, 3.806],
                                  [24.218, -26.905],
                                ],
                              },
                            ],
                            t: -13,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.289, -8.846],
                                  [-4.967, 3.725],
                                  [7.329, 5.172],
                                ],
                                o: [
                                  [-5.193, 6.65],
                                  [11.678, 13.104],
                                  [6.112, -2.821],
                                  [-3.26, -2.301],
                                ],
                                v: [
                                  [-17.009, -31.93],
                                  [-24.062, 2.732],
                                  [17.577, 7.594],
                                  [24.491, -22.904],
                                ],
                              },
                            ],
                            t: -12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.979, -8.312],
                                  [-7.722, 6.879],
                                  [7.72, 4.567],
                                ],
                                o: [
                                  [-4.642, 7.046],
                                  [12.693, 12.123],
                                  [7.256, -5.255],
                                  [-3.434, -2.031],
                                ],
                                v: [
                                  [-19.14, -24.595],
                                  [-23.385, 10.522],
                                  [17.003, 13.359],
                                  [22.951, -18.933],
                                ],
                              },
                            ],
                            t: -10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-7.012, -8.284],
                                  [-7.694, 6.909],
                                  [7.739, 4.536],
                                ],
                                o: [
                                  [-4.614, 7.064],
                                  [12.741, 12.073],
                                  [7.235, -5.284],
                                  [-3.442, -2.018],
                                ],
                                v: [
                                  [-21.396, -19.887],
                                  [-23.311, 17.068],
                                  [17.088, 19.747],
                                  [22.908, -12.569],
                                ],
                              },
                            ],
                            t: -6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-5.965, -9.067],
                                  [-8.469, 5.934],
                                  [7.137, 5.434],
                                ],
                                o: [
                                  [-5.43, 6.459],
                                  [11.197, 13.517],
                                  [7.818, -4.376],
                                  [-3.175, -2.417],
                                ],
                                v: [
                                  [-7.752, -17.829],
                                  [-16.052, 16.555],
                                  [23.733, 24.07],
                                  [33.395, -7.312],
                                ],
                              },
                            ],
                            t: -5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-5.63, -9.279],
                                  [-9.515, 4.351],
                                  [6.934, 5.691],
                                ],
                                o: [
                                  [-5.662, 6.256],
                                  [10.695, 13.917],
                                  [7.973, -4.087],
                                  [-3.084, -2.531],
                                ],
                                v: [
                                  [-2.636, -23.792],
                                  [-12.188, 10.266],
                                  [27.183, 20.255],
                                  [38.099, -11.777],
                                ],
                              },
                            ],
                            t: -4,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.521, -8.676],
                                  [-7.883, 2.874],
                                  [7.463, 4.977],
                                ],
                                o: [
                                  [-5.015, 6.785],
                                  [9.841, 6.714],
                                  [7.529, -4.856],
                                  [-3.32, -2.214],
                                ],
                                v: [
                                  [-6.227, -22.573],
                                  [-11.599, 12.047],
                                  [28.612, 17.091],
                                  [38.54, -15.513],
                                ],
                              },
                            ],
                            t: -3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [7.242, -3.203],
                                  [-3.575, -12.661],
                                  [-6.397, -4.823],
                                  [4.774, 7.873],
                                ],
                                o: [
                                  [-5.514, 2.438],
                                  [19.514, -1.327],
                                  [4.781, -2.018],
                                  [-5.823, -9.603],
                                ],
                                v: [
                                  [-9.122, -17.135],
                                  [-14.507, 5.882],
                                  [26.832, 11.181],
                                  [33.6, -7.895],
                                ],
                              },
                            ],
                            t: -2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [9.164, -6.875],
                                  [-3.575, -12.661],
                                  [-6.397, -4.823],
                                  [4.774, 7.873],
                                ],
                                o: [
                                  [-4.823, 3.618],
                                  [14.981, -7.746],
                                  [4.781, -2.018],
                                  [-5.823, -9.603],
                                ],
                                v: [
                                  [-13.909, -20.675],
                                  [-18.461, 5.938],
                                  [22.593, 10.382],
                                  [29.932, -12.684],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [8.893, -9.46],
                                  [-3.633, -6.483],
                                  [-6.584, -4.564],
                                  [5.083, 7.676],
                                ],
                                o: [
                                  [-3.052, 3.247],
                                  [14.778, -3.779],
                                  [4.697, -2.207],
                                  [-6.201, -9.364],
                                ],
                                v: [
                                  [-20.987, -13.341],
                                  [-20.884, 6.619],
                                  [20.634, 11.774],
                                  [27.295, -9.949],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.393, -8.29],
                                  [-3.887, -8.103],
                                  [-6.695, -0.406],
                                  [8.979, 2.036],
                                ],
                                o: [
                                  [-3.603, 2.622],
                                  [13.05, -1.037],
                                  [4.127, -3.146],
                                  [-9.724, -2.205],
                                ],
                                v: [
                                  [-34.506, -4.277],
                                  [-31.425, 17.108],
                                  [9.287, 14.936],
                                  [8.872, -10.185],
                                ],
                              },
                            ],
                            t: 1,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [881.203, 765.949], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 10,
              parent: 12,
            },
            {
              ty: 4,
              nm: 'right bottom leg ',
              sr: 1,
              st: -24,
              op: 24,
              ip: 22,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [880, 769, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [906.447, 775.066, 0], ix: 2 },
                r: { a: 0, k: 12, ix: 10 },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-38.541, -7.725],
                                  [-34.373, 20.269],
                                  [6.355, 11.687],
                                  [5.134, -15.293],
                                ],
                              },
                            ],
                            t: 2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-25.117, -16.712],
                                  [-25.809, 17.275],
                                  [16.12, 8.334],
                                  [19.812, -21.992],
                                ],
                              },
                            ],
                            t: 4,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [6.569, -5.843],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-23.582, -15.505],
                                  [-25.668, 16.734],
                                  [15.142, 8.542],
                                  [20.093, -23.074],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-25.444, -19.454],
                                  [-26.136, 14.533],
                                  [14.674, 6.341],
                                  [18.231, -27.023],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.58, -8.631],
                                  [-4.84, 3.889],
                                  [7.497, 4.925],
                                ],
                                o: [
                                  [-4.969, 6.82],
                                  [12.108, 12.707],
                                  [6.015, -3.024],
                                  [-3.335, -2.191],
                                ],
                                v: [
                                  [-17.56, -34.542],
                                  [-23.453, 0.335],
                                  [18.325, 3.806],
                                  [24.218, -26.905],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.289, -8.846],
                                  [-4.967, 3.725],
                                  [7.329, 5.172],
                                ],
                                o: [
                                  [-5.193, 6.65],
                                  [11.678, 13.104],
                                  [6.112, -2.821],
                                  [-3.26, -2.301],
                                ],
                                v: [
                                  [-17.009, -31.93],
                                  [-24.062, 2.732],
                                  [17.577, 7.594],
                                  [24.491, -22.904],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.979, -8.312],
                                  [-7.722, 6.879],
                                  [7.72, 4.567],
                                ],
                                o: [
                                  [-4.642, 7.046],
                                  [12.693, 12.123],
                                  [7.256, -5.255],
                                  [-3.434, -2.031],
                                ],
                                v: [
                                  [-19.14, -24.595],
                                  [-23.385, 10.522],
                                  [17.003, 13.359],
                                  [22.951, -18.933],
                                ],
                              },
                            ],
                            t: 14,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-7.012, -8.284],
                                  [-7.694, 6.909],
                                  [7.739, 4.536],
                                ],
                                o: [
                                  [-4.614, 7.064],
                                  [12.741, 12.073],
                                  [7.235, -5.284],
                                  [-3.442, -2.018],
                                ],
                                v: [
                                  [-21.396, -19.887],
                                  [-23.311, 17.068],
                                  [17.088, 19.747],
                                  [22.908, -12.569],
                                ],
                              },
                            ],
                            t: 18,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-5.965, -9.067],
                                  [-8.469, 5.934],
                                  [7.137, 5.434],
                                ],
                                o: [
                                  [-5.43, 6.459],
                                  [11.197, 13.517],
                                  [7.818, -4.376],
                                  [-3.175, -2.417],
                                ],
                                v: [
                                  [-7.752, -17.829],
                                  [-16.052, 16.555],
                                  [23.733, 24.07],
                                  [33.395, -7.312],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-5.63, -9.279],
                                  [-9.515, 4.351],
                                  [6.934, 5.691],
                                ],
                                o: [
                                  [-5.662, 6.256],
                                  [10.695, 13.917],
                                  [7.973, -4.087],
                                  [-3.084, -2.531],
                                ],
                                v: [
                                  [-2.636, -23.792],
                                  [-12.188, 10.266],
                                  [27.183, 20.255],
                                  [38.099, -11.777],
                                ],
                              },
                            ],
                            t: 20,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.521, -8.676],
                                  [-7.883, 2.874],
                                  [7.463, 4.977],
                                ],
                                o: [
                                  [-5.015, 6.785],
                                  [9.841, 6.714],
                                  [7.529, -4.856],
                                  [-3.32, -2.214],
                                ],
                                v: [
                                  [-6.227, -22.573],
                                  [-11.599, 12.047],
                                  [28.612, 17.091],
                                  [38.54, -15.513],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [7.242, -3.203],
                                  [-3.575, -12.661],
                                  [-6.397, -4.823],
                                  [4.774, 7.873],
                                ],
                                o: [
                                  [-5.514, 2.438],
                                  [19.514, -1.327],
                                  [4.781, -2.018],
                                  [-5.823, -9.603],
                                ],
                                v: [
                                  [-8.184, -16.709],
                                  [-14.798, 6.083],
                                  [26.697, 11.918],
                                  [35.948, -8.228],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [9.164, -6.875],
                                  [-4.182, -10.645],
                                  [-4.082, -5.409],
                                  [4.774, 7.873],
                                ],
                                o: [
                                  [-4.823, 3.618],
                                  [11.823, -4.721],
                                  [4.781, -2.018],
                                  [-5.823, -9.603],
                                ],
                                v: [
                                  [-15.839, -15.501],
                                  [-18.461, 5.938],
                                  [22.593, 10.382],
                                  [28.791, -10.404],
                                ],
                              },
                            ],
                            t: 23,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [0.9922, 0.9961, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [881.203, 765.949], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 11,
              parent: 12,
            },
            {
              ty: 3,
              nm: 'right shoe',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [868, 856, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  s: true,
                  x: {
                    a: 1,
                    k: [
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.743, y: 0.839 }, s: [144.5], t: 0 },
                      { o: { x: 0.402, y: 0 }, i: { x: 0.734, y: 1 }, s: [-101.5], t: 12 },
                      { s: [144.5], t: 24 },
                    ],
                    ix: 3,
                  },
                  y: {
                    a: 1,
                    k: [
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [448.5], t: 0 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [454.5], t: 2 },
                      { o: { x: 0.472, y: 0 }, i: { x: 0.955, y: 0.742 }, s: [454.5], t: 9 },
                      { o: { x: 0.04, y: 0.52 }, i: { x: 0.562, y: 1 }, s: [419.5], t: 12 },
                      { o: { x: 0.586, y: 0 }, i: { x: 0.839, y: 1 }, s: [389], t: 18 },
                      { s: [448.5], t: 24 },
                    ],
                    ix: 4,
                  },
                  z: { a: 0, k: 0 },
                },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [-29], t: 0 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 2 },
                    { o: { x: 0.625, y: 0 }, i: { x: 0.937, y: 0.87 }, s: [0], t: 9 },
                    { o: { x: 0.202, y: 0 }, i: { x: 0.811, y: 1 }, s: [23], t: 12 },
                    { o: { x: 0.081, y: 0.118 }, i: { x: 0.516, y: 1 }, s: [23], t: 18 },
                    { s: [-29], t: 24 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              ind: 12,
              parent: 5,
            },
            {
              ty: 4,
              nm: 'Shape Layer 11',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [-89.533, 326.535, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [876.475, 838.035, 0], ix: 2 },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [7.715], t: 0 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [-7.051], t: 1 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [-13.036], t: 2 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [-4.972], t: 3 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [-0.984], t: 6 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [1.699], t: 10 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [-0.284], t: 18 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [14.686], t: 21 },
                    { s: [7.715], t: 24 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Shape 2',
                  ix: 1,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: false,
                          i: [[15.973, 2.207]],
                          o: [[-15.973, -2.207]],
                          v: [[-69.883, 262.249]],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [0, 0], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Shape 1',
                  ix: 2,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, 0],
                            [2, -6],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-2, 6],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-42.119, 257.778],
                            [-86, 248],
                            [-109, 322],
                            [-68, 329],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [0, 0], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 13,
              parent: 12,
            },
            {
              ty: 4,
              nm: 'right back shoe ',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [865.111, 828.512, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [865.861, 825.512, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 10 },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.255, 3.049],
                                  [0, 0],
                                  [-3.048, 5.254],
                                  [0, 0],
                                  [-5.254, -3.048],
                                  [0, 0],
                                  [3.048, -5.254],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.254, -3.048],
                                  [0, 0],
                                  [3.048, -5.254],
                                  [0, 0],
                                  [5.255, 3.049],
                                  [0, 0],
                                  [-3.048, 5.254],
                                ],
                                v: [
                                  [-11.693, 21.231],
                                  [-14.458, 19.627],
                                  [-18.452, 4.593],
                                  [-12.924, -4.935],
                                  [2.11, -8.929],
                                  [4.875, -7.325],
                                  [8.869, 7.709],
                                  [3.341, 17.237],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.975, 1.098],
                                  [0, 0],
                                  [-1.098, 5.974],
                                  [0, 0],
                                  [-5.974, -1.098],
                                  [0, 0],
                                  [1.098, -5.974],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.974, -1.098],
                                  [0, 0],
                                  [1.098, -5.974],
                                  [0, 0],
                                  [5.975, 1.098],
                                  [0, 0],
                                  [-1.098, 5.974],
                                ],
                                v: [
                                  [-3.945, 22.84],
                                  [-7.089, 22.262],
                                  [-15.919, 9.457],
                                  [-13.928, -1.378],
                                  [-1.123, -10.208],
                                  [2.022, -9.63],
                                  [10.852, 3.175],
                                  [8.861, 14.01],
                                ],
                              },
                            ],
                            t: 1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-0.637, 20.318],
                                  [-3.834, 20.328],
                                  [-14.865, 9.361],
                                  [-14.897, -1.655],
                                  [-3.931, -12.686],
                                  [-0.734, -12.695],
                                  [10.298, -1.729],
                                  [10.33, 9.287],
                                ],
                              },
                            ],
                            t: 2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-1.637, 20.318],
                                  [-4.834, 20.328],
                                  [-15.865, 9.361],
                                  [-15.897, -1.655],
                                  [-4.931, -12.686],
                                  [-1.734, -12.695],
                                  [9.298, -1.729],
                                  [9.33, 9.287],
                                ],
                              },
                            ],
                            t: 3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-3.137, 20.318],
                                  [-6.334, 20.328],
                                  [-17.365, 9.361],
                                  [-17.397, -1.655],
                                  [-6.431, -12.686],
                                  [-3.234, -12.695],
                                  [7.798, -1.729],
                                  [7.83, 9.287],
                                ],
                              },
                            ],
                            t: 4,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-4.387, 20.068],
                                  [-7.584, 20.078],
                                  [-18.615, 9.111],
                                  [-18.647, -1.905],
                                  [-7.681, -12.936],
                                  [-4.484, -12.945],
                                  [6.548, -1.979],
                                  [6.58, 9.037],
                                ],
                              },
                            ],
                            t: 5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-2.637, 20.318],
                                  [-5.834, 20.328],
                                  [-16.865, 9.361],
                                  [-16.897, -1.655],
                                  [-5.931, -12.686],
                                  [-2.734, -12.695],
                                  [8.298, -1.729],
                                  [8.33, 9.287],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-0.72, 20.318],
                                  [-3.917, 20.328],
                                  [-14.948, 9.361],
                                  [-14.981, -1.655],
                                  [-4.014, -12.686],
                                  [-0.817, -12.695],
                                  [10.214, -1.729],
                                  [10.247, 9.287],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-2.303, 18.318],
                                  [-5.5, 18.328],
                                  [-16.531, 7.361],
                                  [-16.564, -3.655],
                                  [-5.597, -14.686],
                                  [-2.4, -14.695],
                                  [8.631, -3.729],
                                  [8.663, 7.287],
                                ],
                              },
                            ],
                            t: 8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-2.887, 14.318],
                                  [-6.084, 14.328],
                                  [-17.115, 3.361],
                                  [-17.147, -7.655],
                                  [-6.181, -18.686],
                                  [-2.984, -18.695],
                                  [8.048, -7.729],
                                  [8.08, 3.287],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.84, 0.05],
                                  [0, 0],
                                  [-0.05, 5.839],
                                  [0, 0],
                                  [-5.839, -0.05],
                                  [0, 0],
                                  [0.05, -5.839],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.839, -0.05],
                                  [0, 0],
                                  [0.05, -5.839],
                                  [0, 0],
                                  [5.84, 0.05],
                                  [0, 0],
                                  [-0.05, 5.839],
                                ],
                                v: [
                                  [1.063, 8.288],
                                  [-2.011, 8.262],
                                  [-12.494, -2.402],
                                  [-12.404, -12.992],
                                  [-1.74, -23.475],
                                  [1.333, -23.449],
                                  [11.817, -12.785],
                                  [11.726, -2.195],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.6, -0.695],
                                  [0, 0],
                                  [0.695, 5.599],
                                  [0, 0],
                                  [-5.599, 0.695],
                                  [0, 0],
                                  [-0.695, -5.599],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.599, 0.695],
                                  [0, 0],
                                  [-0.695, -5.599],
                                  [0, 0],
                                  [5.6, -0.695],
                                  [0, 0],
                                  [0.695, 5.599],
                                ],
                                v: [
                                  [8.647, 2.446],
                                  [5.7, 2.812],
                                  [-5.697, -6.069],
                                  [-6.957, -16.224],
                                  [1.924, -27.621],
                                  [4.871, -27.987],
                                  [16.268, -19.106],
                                  [17.528, -8.951],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.205, -2.371],
                                  [0, 0],
                                  [2.371, 5.204],
                                  [0, 0],
                                  [-5.204, 2.37],
                                  [0, 0],
                                  [-2.37, -5.204],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.204, 2.37],
                                  [0, 0],
                                  [-2.37, -5.204],
                                  [0, 0],
                                  [5.205, -2.371],
                                  [0, 0],
                                  [2.37, 5.204],
                                ],
                                v: [
                                  [14.137, -0.477],
                                  [11.398, 0.771],
                                  [-2.318, -4.36],
                                  [-6.617, -13.797],
                                  [-1.486, -27.513],
                                  [1.253, -28.761],
                                  [14.968, -23.63],
                                  [19.268, -14.193],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.914, -3.155],
                                  [0, 0],
                                  [3.155, 4.914],
                                  [0, 0],
                                  [-4.914, 3.155],
                                  [0, 0],
                                  [-3.155, -4.914],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.914, 3.155],
                                  [0, 0],
                                  [-3.155, -4.914],
                                  [0, 0],
                                  [4.914, -3.155],
                                  [0, 0],
                                  [3.155, 4.914],
                                ],
                                v: [
                                  [15.844, 0.663],
                                  [13.258, 2.324],
                                  [-1.353, -0.862],
                                  [-7.074, -9.773],
                                  [-3.889, -24.384],
                                  [-1.303, -26.045],
                                  [13.308, -22.859],
                                  [19.029, -13.947],
                                ],
                              },
                            ],
                            t: 13,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.624, -3.94],
                                  [0, 0],
                                  [3.939, 4.624],
                                  [0, 0],
                                  [-5.426, 4.475],
                                  [0, 0],
                                  [-3.939, -4.624],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.624, 3.939],
                                  [0, 0],
                                  [-0.89, -7.844],
                                  [0, 0],
                                  [4.624, -3.939],
                                  [0, 0],
                                  [3.939, 4.624],
                                ],
                                v: [
                                  [18.788, 4.272],
                                  [16.354, 6.345],
                                  [-6.056, 9.628],
                                  [-7.524, -2.421],
                                  [-5.055, -18.786],
                                  [-2.621, -20.86],
                                  [12.884, -19.62],
                                  [20.028, -11.234],
                                ],
                              },
                            ],
                            t: 14,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.815, -3.649],
                                  [0, 0],
                                  [3.648, 4.815],
                                  [0, 0],
                                  [-6.211, 4.745],
                                  [0, 0],
                                  [-3.648, -4.814],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.814, 3.648],
                                  [0, 0],
                                  [-1.438, -6.641],
                                  [0, 0],
                                  [4.815, -3.648],
                                  [0, 0],
                                  [3.648, 4.814],
                                ],
                                v: [
                                  [16.916, 3.928],
                                  [14.382, 5.848],
                                  [-6.362, 7.761],
                                  [-8.328, -4.139],
                                  [-5.446, -20.32],
                                  [-2.912, -22.24],
                                  [12.412, -20.128],
                                  [19.028, -11.396],
                                ],
                              },
                            ],
                            t: 15,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.197, -3.067],
                                  [0, 0],
                                  [3.066, 5.196],
                                  [0, 0],
                                  [-5.196, 3.066],
                                  [0, 0],
                                  [-3.066, -5.196],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.196, 3.066],
                                  [0, 0],
                                  [-3.066, -5.196],
                                  [0, 0],
                                  [5.197, -3.066],
                                  [0, 0],
                                  [3.066, 5.196],
                                ],
                                v: [
                                  [14.873, 7.555],
                                  [12.138, 9.169],
                                  [-2.823, 5.312],
                                  [-8.383, -4.112],
                                  [-4.526, -19.073],
                                  [-1.791, -20.687],
                                  [13.17, -16.83],
                                  [18.73, -7.406],
                                ],
                              },
                            ],
                            t: 17,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.503, -2.572],
                                  [0, 0],
                                  [2.572, 5.503],
                                  [0, 0],
                                  [-5.503, 2.572],
                                  [0, 0],
                                  [-2.572, -5.503],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.503, 2.572],
                                  [0, 0],
                                  [-2.572, -5.503],
                                  [0, 0],
                                  [5.503, -2.572],
                                  [0, 0],
                                  [2.572, 5.503],
                                ],
                                v: [
                                  [11.162, 8.778],
                                  [8.265, 10.132],
                                  [-6.356, 4.825],
                                  [-11.021, -5.155],
                                  [-5.714, -19.776],
                                  [-2.817, -21.13],
                                  [11.804, -15.823],
                                  [16.469, -5.843],
                                ],
                              },
                            ],
                            t: 18,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.037, -0.675],
                                  [0, 0],
                                  [0.675, 6.036],
                                  [0, 0],
                                  [-6.036, 0.675],
                                  [0, 0],
                                  [-0.675, -6.036],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.036, 0.675],
                                  [0, 0],
                                  [-0.675, -6.036],
                                  [0, 0],
                                  [6.037, -0.675],
                                  [0, 0],
                                  [0.675, 6.036],
                                ],
                                v: [
                                  [4.156, 14.312],
                                  [0.979, 14.668],
                                  [-11.175, 4.959],
                                  [-12.399, -5.989],
                                  [-2.691, -18.142],
                                  [0.487, -18.497],
                                  [12.64, -8.789],
                                  [13.864, 2.159],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.066, 0.331],
                                  [0, 0],
                                  [-0.331, 6.065],
                                  [0, 0],
                                  [-6.065, -0.331],
                                  [0, 0],
                                  [0.331, -6.065],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.065, -0.331],
                                  [0, 0],
                                  [0.331, -6.065],
                                  [0, 0],
                                  [6.066, 0.331],
                                  [0, 0],
                                  [-0.331, 6.065],
                                ],
                                v: [
                                  [3.872, 10.449],
                                  [0.68, 10.275],
                                  [-9.704, -1.307],
                                  [-9.104, -12.307],
                                  [2.478, -22.69],
                                  [5.671, -22.516],
                                  [16.054, -10.934],
                                  [15.454, 0.065],
                                ],
                              },
                            ],
                            t: 20,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.545, 2.481],
                                  [0, 0],
                                  [-2.481, 5.544],
                                  [0, 0],
                                  [-5.544, -2.48],
                                  [0, 0],
                                  [2.48, -5.544],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.544, -2.48],
                                  [0, 0],
                                  [2.481, -5.544],
                                  [0, 0],
                                  [5.545, 2.481],
                                  [0, 0],
                                  [-2.48, 5.544],
                                ],
                                v: [
                                  [-2.371, 12.87],
                                  [-5.289, 11.565],
                                  [-10.837, -2.967],
                                  [-6.339, -13.023],
                                  [8.193, -18.571],
                                  [11.112, -17.265],
                                  [16.66, -2.734],
                                  [12.161, 7.322],
                                ],
                              },
                            ],
                            t: 22,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [865.111, 828.512], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 14,
              parent: 12,
            },
            {
              ty: 4,
              nm: 'left shoe ',
              sr: 1,
              st: -37,
              op: 13,
              ip: 12,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [826, 739.5, 0], ix: 1 },
                s: { a: 0, k: [-136, 136, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [400.487, 274.305, 0], ix: 2 },
                r: { a: 0, k: 20.626, ix: 10 },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.392, 0.272],
                                  [26.393, -0.272],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.922, -10.09],
                                  [12.423, 10.73],
                                ],
                              },
                            ],
                            t: 12,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [798.925, 767.689], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 2',
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.125, 0.322],
                                  [26.124, -0.322],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.68, -5.655],
                                  [15.975, 11.206],
                                ],
                              },
                            ],
                            t: 12,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [782.192, 744.239], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 3',
                  ix: 3,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-2.377, 11.234],
                                  [10.567, 12.991],
                                  [14.322, 1.679],
                                  [-15.242, -23.886],
                                  [-11.879, -5.029],
                                ],
                                o: [
                                  [1.168, -5.52],
                                  [-14.946, -18.376],
                                  [-17.083, -2.003],
                                  [8.982, 14.077],
                                  [11.793, 4.993],
                                ],
                                v: [
                                  [42.086, 24.313],
                                  [28.74, -2.539],
                                  [-11.407, -35.506],
                                  [-28.012, -5.555],
                                  [9.848, 32.516],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-13.135, -4.694],
                                  [8.434, 14.165],
                                  [11.304, 8.245],
                                  [-5.173, -26.185],
                                  [-13.666, -5.499],
                                ],
                                o: [
                                  [5.231, 1.869],
                                  [-8.629, -14.494],
                                  [-13.484, -9.835],
                                  [3.048, 15.432],
                                  [11.696, 4.706],
                                ],
                                v: [
                                  [42.472, 36.692],
                                  [21.102, 17.623],
                                  [-7.727, -26.64],
                                  [-34.211, -12.422],
                                  [-8.392, 17.549],
                                ],
                              },
                            ],
                            t: 12,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [787.515, 756.053], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 5',
                  ix: 4,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-2.377, 11.234],
                                  [10.567, 12.992],
                                  [13.883, 3.895],
                                  [-10.531, -32.477],
                                  [-11.88, -5.031],
                                ],
                                o: [
                                  [1.168, -5.519],
                                  [-14.946, -18.375],
                                  [-15.694, -4.405],
                                  [5.15, 15.883],
                                  [11.792, 4.993],
                                ],
                                v: [
                                  [45.333, 23.15],
                                  [31.987, -3.702],
                                  [-9.171, -40.459],
                                  [-35.969, -7.165],
                                  [12.477, 39.871],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-4.141, 11.771],
                                  [5.642, 15.491],
                                  [9.823, 9.823],
                                  [7.309, -31.28],
                                  [-11.424, -4.223],
                                ],
                                o: [
                                  [1.843, -5.239],
                                  [-7.98, -21.91],
                                  [-11.105, -11.105],
                                  [-3.574, 15.297],
                                  [11.825, 4.371],
                                ],
                                v: [
                                  [46.545, 33.995],
                                  [24.365, 12.952],
                                  [0.686, -27.815],
                                  [-38.684, -13.833],
                                  [29.783, 40.516],
                                ],
                              },
                            ],
                            t: 12,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [791.28, 749.258], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 7',
                  ix: 5,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.333, 4.667],
                                  [3.879, 1.037],
                                  [2.191, -21.923],
                                  [-13.537, 5.36],
                                  [-3.333, 8.333],
                                  [-1.833, 8.5],
                                  [6.772, 9.592],
                                ],
                                o: [
                                  [-5.333, -4.666],
                                  [-20.94, -5.594],
                                  [-1.833, 6.251],
                                  [10.074, -3.988],
                                  [1.681, -4.199],
                                  [-5.419, 2.187],
                                  [-3.598, -5.095],
                                ],
                                v: [
                                  [20.022, -28.661],
                                  [1.523, -40.344],
                                  [-45.839, -16.566],
                                  [25.598, 40.578],
                                  [42.505, 22.09],
                                  [47.672, 2.923],
                                  [38.689, -19.661],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.112, 5.636],
                                  [8.441, 5.052],
                                  [12.819, -16.641],
                                  [-13.068, 5.888],
                                  [-5.864, 6.609],
                                  [1.865, 15.754],
                                  [7.91, 2.879],
                                ],
                                o: [
                                  [-4.755, -6.519],
                                  [-11.032, -6.603],
                                  [-4.68, 4.164],
                                  [9.725, -4.381],
                                  [1.489, -1.678],
                                  [-14.001, -0.659],
                                  [-5.77, -2.1],
                                ],
                                v: [
                                  [24.114, -12.816],
                                  [9.306, -26.368],
                                  [-37.923, -18.416],
                                  [23.735, 46.979],
                                  [37.328, 42.592],
                                  [48.678, 10.914],
                                  [35.064, -8.157],
                                ],
                              },
                            ],
                            t: 12,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [802.478, 736.994], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 9',
                  ix: 6,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [-6.167, -4.666],
                                  [8.333, -1.667],
                                  [3.834, 3.5],
                                  [0, 0],
                                ],
                                o: [
                                  [13.5, 0.5],
                                  [6.166, 4.667],
                                  [-8.333, 1.666],
                                  [-3.833, -3.5],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.917, -12.083],
                                  [10.25, -0.417],
                                  [12.583, 10.417],
                                  [-12.584, 6.917],
                                  [-18.084, -0.917],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [-14.183, -7.503],
                                  [8.352, 0.481],
                                  [2.307, 4.363],
                                  [0, 0],
                                ],
                                o: [
                                  [5.238, -1.292],
                                  [9.086, 4.807],
                                  [-8.352, -0.481],
                                  [-2.307, -4.362],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.269, 3.693],
                                  [3.243, 7.234],
                                  [14.73, 27.299],
                                  [-12.294, 22.985],
                                  [-14.722, 21.651],
                                ],
                              },
                            ],
                            t: 12,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [819.917, 708.75], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 11',
                  ix: 7,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.143, 1.837],
                                  [0, 0],
                                  [-1.837, 5.142],
                                  [0, 0],
                                  [-4.049, 21.439],
                                  [-16.108, -3.455],
                                  [6.695, -12.578],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.144, -1.836],
                                  [0, 0],
                                  [1.836, -5.143],
                                  [2.471, -13.082],
                                  [7.346, 1.576],
                                  [0, 0],
                                  [-1.837, 5.143],
                                ],
                                v: [
                                  [-3.752, 13.578],
                                  [-9.171, 11.642],
                                  [-15.157, -0.995],
                                  [-16.607, -2.451],
                                  [-10.997, -6.269],
                                  [13.86, -14.796],
                                  [13.464, 11.045],
                                  [6.027, 14.231],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.799, 2.423],
                                  [0, 0],
                                  [-1.779, 4.828],
                                  [0, 0],
                                  [-4.976, 19.906],
                                  [-22.468, -10.312],
                                  [3.736, -19.521],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.683, -2.56],
                                  [0, 0],
                                  [2.235, -4.649],
                                  [4.505, -18.021],
                                  [8.81, 4.044],
                                  [0, 0],
                                  [-2.423, 4.799],
                                ],
                                v: [
                                  [-4.794, 28.105],
                                  [-12.677, 30.584],
                                  [-17.108, 18.886],
                                  [-17.928, 24.66],
                                  [-18.014, 11.591],
                                  [11.164, -0.28],
                                  [15.625, 25.394],
                                  [4.679, 29.938],
                                ],
                              },
                            ],
                            t: 12,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [822.466, 708.978], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 15,
            },
            {
              ty: 4,
              nm: 'left shoe ',
              sr: 1,
              st: -36,
              op: 24,
              ip: 13,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [943.5, 857, 0], ix: 1 },
                s: { a: 0, k: [136, 136, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  s: true,
                  x: {
                    a: 1,
                    k: [
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [888.297], t: 13 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [894.289], t: 14 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [888.789], t: 17 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [891.339], t: 19 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [885.839], t: 21 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [885.771], t: 22 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [886.884], t: 23 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1.191 }, s: [902.172], t: 24 },
                      { o: { x: 0.333, y: -0.21 }, i: { x: 0.667, y: 1.188 }, s: [896.453], t: 25 },
                      {
                        o: { x: 0.333, y: -0.295 },
                        i: { x: 0.667, y: 1.097 },
                        s: [891.251],
                        t: 26,
                      },
                      { o: { x: 0.305, y: 0.054 }, i: { x: 0.651, y: 0.864 }, s: [887.927], t: 27 },
                      {
                        o: { x: 0.342, y: 0.787 },
                        i: { x: 0.687, y: -0.363 },
                        s: [893.394],
                        t: 28,
                      },
                      { o: { x: 0.418, y: -0.166 }, i: { x: 0.779, y: 1 }, s: [894.317], t: 29 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.583, y: -2.061 }, s: [884.186], t: 30 },
                      { o: { x: 0.417, y: 0.96 }, i: { x: 0.833, y: 1 }, s: [884.779], t: 31 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [886.672], t: 32 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [885.133], t: 33 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [893.963], t: 34 },
                      { s: [886.406], t: 35 },
                    ],
                    ix: 3,
                  },
                  y: {
                    a: 1,
                    k: [
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [866.799], t: 13 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [860.562], t: 14 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [860.843], t: 19 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [860.843], t: 21 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [862.998], t: 22 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [869.227], t: 23 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: -111.342 }, s: [879.787], t: 24 },
                      { o: { x: 0.333, y: 0.645 }, i: { x: 0.667, y: 0.333 }, s: [879.776], t: 25 },
                      { o: { x: 0.333, y: 0.27 }, i: { x: 0.667, y: 0.868 }, s: [877.917], t: 26 },
                      { o: { x: 0.333, y: 0.622 }, i: { x: 0.667, y: 1 }, s: [873.333], t: 27 },
                      { o: { x: 0.297, y: 0 }, i: { x: 0.659, y: 12.486 }, s: [872.358], t: 28 },
                      { o: { x: 0.425, y: 0.992 }, i: { x: 0.815, y: 1 }, s: [872.295], t: 29 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.583, y: 0.505 }, s: [873.203], t: 30 },
                      { o: { x: 0.417, y: 1.615 }, i: { x: 0.833, y: 1 }, s: [867.784], t: 31 },
                      { o: { x: 0.182, y: 0 }, i: { x: 0.564, y: 1.349 }, s: [866.122], t: 32 },
                      { o: { x: 0.323, y: -0.06 }, i: { x: 0.677, y: 1.18 }, s: [863.488], t: 33 },
                      { o: { x: 0.436, y: 0.332 }, i: { x: 0.818, y: 0.955 }, s: [852.197], t: 34 },
                      { s: [860.468], t: 35 },
                    ],
                    ix: 4,
                  },
                  z: { a: 0, k: 0 },
                },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.222, y: 0 }, i: { x: 0, y: 1 }, s: [10.525], t: 13 },
                    { o: { x: 0.659, y: 0 }, i: { x: 0.822, y: 1 }, s: [0], t: 14 },
                    { o: { x: 0.265, y: 0 }, i: { x: 0.608, y: 0.432 }, s: [0], t: 19 },
                    { o: { x: 0.318, y: 0.209 }, i: { x: 0.653, y: 1.183 }, s: [3.04], t: 21 },
                    { o: { x: 0.324, y: -0.102 }, i: { x: 0.658, y: 0.987 }, s: [6.399], t: 22 },
                    { o: { x: 0.327, y: -0.01 }, i: { x: 0.661, y: 0.889 }, s: [12.038], t: 23 },
                    { o: { x: 0.33, y: -0.204 }, i: { x: 0.664, y: 1.26 }, s: [5.376], t: 24 },
                    { o: { x: 0.333, y: -0.224 }, i: { x: 0.67, y: 1.323 }, s: [8.904], t: 25 },
                    { o: { x: 0.337, y: 2.087 }, i: { x: 0.672, y: 0.356 }, s: [17.051], t: 27 },
                    { o: { x: 0.341, y: 0.246 }, i: { x: 0.675, y: 1.346 }, s: [16.408], t: 28 },
                    { o: { x: 0.345, y: 0.111 }, i: { x: 0.679, y: 1.457 }, s: [14.66], t: 29 },
                    { o: { x: 0.351, y: 3.752 }, i: { x: 0.686, y: -0.387 }, s: [20.436], t: 30 },
                    { o: { x: 0.423, y: 0.756 }, i: { x: 0.771, y: 1.401 }, s: [19.666], t: 31 },
                    { o: { x: 0.167, y: -0.183 }, i: { x: 0.583, y: 1.909 }, s: [15.858], t: 33 },
                    { o: { x: 0.417, y: -0.109 }, i: { x: 0.833, y: 1.042 }, s: [12.817], t: 34 },
                    { s: [-12.475], t: 35 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.663, -2.93],
                                ],
                                o: [
                                  [-12.006, -3.071],
                                  [0, 0],
                                ],
                                v: [
                                  [17.125, 0.734],
                                  [-17.126, 2.337],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.663, -2.93],
                                ],
                                o: [
                                  [-12.006, -3.071],
                                  [0, 0],
                                ],
                                v: [
                                  [17.125, 0.734],
                                  [-17.126, 2.337],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [4.779, -3.849],
                                ],
                                o: [
                                  [-10.362, -2.324],
                                  [0, 0],
                                ],
                                v: [
                                  [16.422, -3.576],
                                  [-16.757, 0.1],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.624, -4.544],
                                ],
                                o: [
                                  [-9.117, -1.758],
                                  [0, 0],
                                ],
                                v: [
                                  [14.393, -7.021],
                                  [-17.974, -1.776],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [7.699, -6.251],
                                ],
                                o: [
                                  [-6.323, -0.451],
                                  [0, 0],
                                ],
                                v: [
                                  [12.137, -16.52],
                                  [-18.137, -5.501],
                                ],
                              },
                            ],
                            t: 23,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [9.545, -7.772],
                                ],
                                o: [
                                  [-3.34, 0.869],
                                  [0, 0],
                                ],
                                v: [
                                  [6.577, -21.569],
                                  [-20.949, -6.924],
                                ],
                              },
                            ],
                            t: 24,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [8.186, -9.078],
                                ],
                                o: [
                                  [-3.153, 1.639],
                                  [0, 0],
                                ],
                                v: [
                                  [3.991, -21.232],
                                  [-19.714, -1.368],
                                ],
                              },
                            ],
                            t: 25,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [6.945, -9.371],
                                ],
                                o: [
                                  [-3.539, 1.981],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.6, -18.212],
                                  [-19.083, 4.361],
                                ],
                              },
                            ],
                            t: 26,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.703, -9.665],
                                ],
                                o: [
                                  [-3.925, 2.324],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.778, -14.631],
                                  [-20.602, 11.197],
                                ],
                              },
                            ],
                            t: 27,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.395, -8.739],
                                ],
                                o: [
                                  [-5.142, 1.827],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.062, -12.138],
                                  [-20.848, 11.925],
                                ],
                              },
                            ],
                            t: 28,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.087, -7.814],
                                ],
                                o: [
                                  [-6.359, 1.331],
                                  [0, 0],
                                ],
                                v: [
                                  [3.577, -9.018],
                                  [-20.552, 12.158],
                                ],
                              },
                            ],
                            t: 29,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.087, -7.814],
                                ],
                                o: [
                                  [-6.359, 1.331],
                                  [0, 0],
                                ],
                                v: [
                                  [3.577, -9.018],
                                  [-20.552, 12.158],
                                ],
                              },
                            ],
                            t: 30,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.809, -5.257],
                                ],
                                o: [
                                  [-10, 0.123],
                                  [0, 0],
                                ],
                                v: [
                                  [11.409, -6.635],
                                  [-20.466, 9.679],
                                ],
                              },
                            ],
                            t: 31,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.912, -3.131],
                                ],
                                o: [
                                  [-9.133, -1.445],
                                  [0, 0],
                                ],
                                v: [
                                  [14.683, -2.034],
                                  [-19.848, 5.027],
                                ],
                              },
                            ],
                            t: 32,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.208, -1.655],
                                ],
                                o: [
                                  [-8.332, -1.972],
                                  [0, 0],
                                ],
                                v: [
                                  [16.745, 0.493],
                                  [-17.126, 2.337],
                                ],
                              },
                            ],
                            t: 33,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [-0.276, -1.258],
                                ],
                                o: [
                                  [-7.767, -3.604],
                                  [0, 0],
                                ],
                                v: [
                                  [24.72, 1.774],
                                  [-17.504, -3.621],
                                ],
                              },
                            ],
                            t: 34,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [13.275, -5.855],
                                ],
                                o: [
                                  [-5.77, -4.153],
                                  [0, 0],
                                ],
                                v: [
                                  [21.008, -8.431],
                                  [-22.226, -13.735],
                                ],
                              },
                            ],
                            t: 35,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [966.42, 834.392], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 4',
                  ix: 2,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [12.21, 10.257],
                                  [-14.731, 1.228],
                                  [-5.832, -1.505],
                                  [-3.243, -2.339],
                                ],
                                o: [
                                  [-24.349, -4.803],
                                  [-8.728, -7.331],
                                  [5.861, -0.488],
                                  [3.514, 0.907],
                                  [6.071, 4.383],
                                ],
                                v: [
                                  [25.89, 6.128],
                                  [-15.797, 1.36],
                                  [-11.159, -11.129],
                                  [5.448, -9.908],
                                  [15.115, -3.512],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [12.21, 10.257],
                                  [-14.731, 1.228],
                                  [-5.832, -1.505],
                                  [-3.243, -2.339],
                                ],
                                o: [
                                  [-24.349, -4.803],
                                  [-8.728, -7.331],
                                  [5.861, -0.488],
                                  [3.514, 0.907],
                                  [6.071, 4.383],
                                ],
                                v: [
                                  [25.89, 6.128],
                                  [-15.797, 1.36],
                                  [-11.159, -11.129],
                                  [5.448, -9.908],
                                  [15.115, -3.512],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.722, -0.447],
                                  [11.018, 9.056],
                                  [-8.331, 1.94],
                                  [-5.873, -1.199],
                                  [-3.358, -2.286],
                                ],
                                o: [
                                  [-21.208, -2.978],
                                  [-8.297, -6.752],
                                  [3.436, -1.058],
                                  [3.536, 0.721],
                                  [6.29, 4.283],
                                ],
                                v: [
                                  [25.256, 2.028],
                                  [-13.816, 0.482],
                                  [-17.228, -12.03],
                                  [4.534, -13.84],
                                  [14.871, -7.65],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [1.269, -0.785],
                                  [10.116, 8.147],
                                  [-3.485, 2.48],
                                  [-5.905, -0.966],
                                  [-3.446, -2.245],
                                ],
                                o: [
                                  [-18.829, -1.597],
                                  [-7.971, -6.313],
                                  [1.6, -1.489],
                                  [3.552, 0.581],
                                  [6.457, 4.206],
                                ],
                                v: [
                                  [23.28, -1.258],
                                  [-13.812, -0.364],
                                  [-23.319, -12.894],
                                  [2.346, -16.998],
                                  [13.191, -10.964],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [2.577, -1.593],
                                  [8.143, 6.129],
                                  [-1.745, 2.05],
                                  [-6.069, -0.431],
                                  [-3.705, -2.182],
                                ],
                                o: [
                                  [-13.51, 1.634],
                                  [-7.324, -5.376],
                                  [-2.702, -2.529],
                                  [3.645, 0.259],
                                  [6.946, 4.091],
                                ],
                                v: [
                                  [19.155, -11.363],
                                  [-12.161, -3.172],
                                  [-26.615, -13.755],
                                  [-0.922, -25.507],
                                  [11.339, -20.314],
                                ],
                              },
                            ],
                            t: 23,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [3.808, -2.354],
                                  [5.928, 3.927],
                                  [4.787, 2.415],
                                  [-6.052, 0.118],
                                  [-3.854, -2.055],
                                ],
                                o: [
                                  [-7.789, 4.816],
                                  [-6.458, -4.278],
                                  [-6.922, -3.493],
                                  [3.629, -0.071],
                                  [7.228, 3.854],
                                ],
                                v: [
                                  [13.067, -16.46],
                                  [-14.052, -5.343],
                                  [-28.769, -12.376],
                                  [-5.221, -29.577],
                                  [6.394, -25.122],
                                ],
                              },
                            ],
                            t: 24,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [2.895, -2.82],
                                  [6.33, 2.895],
                                  [4.174, 1.844],
                                  [-5.63, 0.949],
                                  [-4.008, -1.524],
                                ],
                                o: [
                                  [-6.823, 5.891],
                                  [-6.743, -3.185],
                                  [-6.219, -3.427],
                                  [3.498, -0.69],
                                  [8.182, 3.112],
                                ],
                                v: [
                                  [11.636, -16.135],
                                  [-11.698, -0.744],
                                  [-28.423, -7.199],
                                  [-8.816, -27.849],
                                  [2.758, -25.193],
                                ],
                              },
                            ],
                            t: 25,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [1.964, -2.804],
                                  [6.888, 2.578],
                                  [2.815, 1.802],
                                  [-5.226, 1.266],
                                  [-3.986, -1.266],
                                ],
                                o: [
                                  [-6.997, 6.116],
                                  [-6.907, -2.684],
                                  [-4.572, -3.721],
                                  [2.043, 0.525],
                                  [8.759, 2.743],
                                ],
                                v: [
                                  [10.68, -14.498],
                                  [-12.601, 5.753],
                                  [-28.898, -3.394],
                                  [-10.521, -26.478],
                                  [-0.881, -21.994],
                                ],
                              },
                            ],
                            t: 26,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [1.033, -2.787],
                                  [7.447, 2.261],
                                  [1.456, 1.76],
                                  [-4.822, 1.583],
                                  [-3.648, -1.851],
                                ],
                                o: [
                                  [-7.172, 6.34],
                                  [-7.071, -2.182],
                                  [-2.925, -4.016],
                                  [3.201, -1.307],
                                  [7.879, 3.998],
                                ],
                                v: [
                                  [10.056, -9.759],
                                  [-13.503, 12.249],
                                  [-29.373, 0.411],
                                  [-13.275, -21.44],
                                  [-1.743, -17.988],
                                ],
                              },
                            ],
                            t: 27,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.698, -2.39],
                                  [8.232, 3.212],
                                  [0.054, 2.277],
                                  [-4.869, 1.198],
                                  [-3.846, -1.206],
                                ],
                                o: [
                                  [-9.119, 5.433],
                                  [-7.309, -2.779],
                                  [-1.08, -4.155],
                                  [3.244, -1.064],
                                  [9.096, 2.796],
                                ],
                                v: [
                                  [10.119, -10.047],
                                  [-14.168, 11.884],
                                  [-28.729, 1.647],
                                  [-12.99, -19.782],
                                  [-3.913, -16.093],
                                ],
                              },
                            ],
                            t: 28,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.364, -1.992],
                                  [9.017, 4.164],
                                  [-1.348, 2.793],
                                  [-4.917, 0.813],
                                  [-3.727, -1.403],
                                ],
                                o: [
                                  [-11.066, 4.527],
                                  [-7.546, -3.376],
                                  [0.764, -4.295],
                                  [3.287, -0.821],
                                  [8.855, 3.218],
                                ],
                                v: [
                                  [13.69, -7.659],
                                  [-14.833, 11.519],
                                  [-28.085, 2.882],
                                  [-10.116, -15.456],
                                  [-0.13, -13.818],
                                ],
                              },
                            ],
                            t: 29,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.364, -1.992],
                                  [9.017, 4.164],
                                  [-1.348, 2.793],
                                  [-4.917, 0.813],
                                  [-3.727, -1.403],
                                ],
                                o: [
                                  [-11.066, 4.527],
                                  [-7.546, -3.376],
                                  [0.764, -4.295],
                                  [3.287, -0.821],
                                  [8.855, 3.218],
                                ],
                                v: [
                                  [13.69, -7.659],
                                  [-14.833, 11.519],
                                  [-28.085, 2.882],
                                  [-10.116, -15.456],
                                  [-0.13, -13.818],
                                ],
                              },
                            ],
                            t: 30,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-0.018, -0.783],
                                  [11.984, 6.261],
                                  [-8.507, 3.571],
                                  [-5.506, 0.087],
                                  [-3.661, -1.571],
                                ],
                                o: [
                                  [-18.928, 1.826],
                                  [-8.963, -4.618],
                                  [4.108, -3.069],
                                  [3.48, -0.219],
                                  [7.806, 3.22],
                                ],
                                v: [
                                  [19.952, -3.341],
                                  [-16.609, 9.239],
                                  [-22.674, -1.854],
                                  [-2.12, -12.594],
                                  [9.838, -9.897],
                                ],
                              },
                            ],
                            t: 31,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.043, -0.393],
                                  [11.759, 8.847],
                                  [-11.772, 2.085],
                                  [-5.659, -0.975],
                                  [-3.359, -2.143],
                                ],
                                o: [
                                  [-21.698, -2.33],
                                  [-8.592, -6.421],
                                  [5.151, -1.606],
                                  [3.501, 0.515],
                                  [6.738, 4.216],
                                ],
                                v: [
                                  [24.233, 1.313],
                                  [-16.73, 4.815],
                                  [-16.867, -7.456],
                                  [2.3, -11.141],
                                  [12.755, -6.081],
                                ],
                              },
                            ],
                            t: 32,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [12.21, 10.257],
                                  [-14.731, 1.228],
                                  [-5.832, -1.505],
                                  [-3.243, -2.339],
                                ],
                                o: [
                                  [-24.349, -4.803],
                                  [-8.728, -7.331],
                                  [5.861, -0.488],
                                  [3.514, 0.907],
                                  [6.071, 4.383],
                                ],
                                v: [
                                  [25.616, 5.134],
                                  [-15.797, 1.36],
                                  [-11.159, -11.129],
                                  [5.448, -9.908],
                                  [14.734, -3.753],
                                ],
                              },
                            ],
                            t: 33,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [9.905, 12.497],
                                  [-14.678, -1.754],
                                  [-5.411, -2.645],
                                  [-3.26, -2.315],
                                ],
                                o: [
                                  [-22.89, -9.59],
                                  [-7.08, -8.933],
                                  [5.84, 0.698],
                                  [3.261, 1.594],
                                  [5.7, 4.048],
                                ],
                                v: [
                                  [32.886, 6.229],
                                  [-17.848, -6.672],
                                  [4.26, -10.234],
                                  [9.888, -8.49],
                                  [23.8, -1.889],
                                ],
                              },
                            ],
                            t: 34,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [10.623, 1.914],
                                  [-14.782, -0.068],
                                  [-5.944, -2.655],
                                  [-3.613, -3.237],
                                ],
                                o: [
                                  [-18.074, -9.165],
                                  [-11.218, -2.021],
                                  [5.881, 0.027],
                                  [2.247, 1.004],
                                  [5.577, 4.996],
                                ],
                                v: [
                                  [28.706, -3.788],
                                  [-16.994, -13.196],
                                  [-10.373, -23.794],
                                  [11.195, -18.741],
                                  [21.493, -10.489],
                                ],
                              },
                            ],
                            t: 35,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [966.864, 837.464], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 6',
                  ix: 3,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.199, -10.206],
                                  [0, 0],
                                  [-10.634, -0.086],
                                  [-12.005, -0.071],
                                  [-3.674, 0.116],
                                  [3.096, 5.163],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.762, 1.254],
                                  [0, 0],
                                  [9.686, 0.078],
                                  [23.475, 0.14],
                                  [0.752, -5.235],
                                  [-6.177, -10.299],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-41.118, -19.362],
                                  [-48.009, 18.597],
                                  [-46.915, 19.127],
                                  [-28.912, 19.28],
                                  [5.175, 19.523],
                                  [50.335, 18.864],
                                  [46.385, 2.983],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.199, -10.206],
                                  [0, 0],
                                  [-10.634, -0.086],
                                  [-12.005, -0.071],
                                  [-3.674, 0.116],
                                  [3.096, 5.163],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.762, 1.254],
                                  [0, 0],
                                  [9.686, 0.078],
                                  [23.475, 0.14],
                                  [0.752, -5.235],
                                  [-6.177, -10.299],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-43.324, -18.994],
                                  [-48.009, 18.597],
                                  [-46.915, 19.127],
                                  [-28.912, 19.28],
                                  [5.175, 19.523],
                                  [50.335, 18.864],
                                  [46.385, 2.983],
                                ],
                              },
                            ],
                            t: 13,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.199, -10.206],
                                  [0, 0],
                                  [-10.634, -0.086],
                                  [-12.005, -0.071],
                                  [-3.674, 0.116],
                                  [3.096, 5.163],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.762, 1.254],
                                  [0, 0],
                                  [9.686, 0.078],
                                  [23.475, 0.14],
                                  [0.752, -5.235],
                                  [-6.177, -10.299],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-41.118, -19.362],
                                  [-48.009, 18.597],
                                  [-46.915, 19.127],
                                  [-28.912, 19.28],
                                  [5.175, 19.523],
                                  [50.335, 18.864],
                                  [46.385, 2.983],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.278, 4.176],
                                  [9.223, -0.7],
                                  [7.69, 0.892],
                                  [7.667, 9.119],
                                  [-6.169, -14.462],
                                  [-1.384, -0.664],
                                  [-10.754, -0.383],
                                  [-11.631, 0.824],
                                  [-3.633, 0.621],
                                  [5.458, 6.041],
                                ],
                                o: [
                                  [-6.643, 0.102],
                                  [-7.164, -5.535],
                                  [-6.381, -0.746],
                                  [-1.242, 13.441],
                                  [0.922, 2.279],
                                  [1.127, 0.541],
                                  [11.661, 0.883],
                                  [21.475, -1.14],
                                  [1.444, -5.152],
                                  [-6.9, -9.063],
                                ],
                                v: [
                                  [17.195, -23.738],
                                  [-6.154, -16.331],
                                  [-20.278, -26.283],
                                  [-39.821, -22.705],
                                  [-46.542, 14.148],
                                  [-42.898, 16.815],
                                  [-22.789, 18.299],
                                  [12.016, 17.343],
                                  [50.572, 13.574],
                                  [43.377, -4.444],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [9.597, 4.896],
                                  [9.223, -0.7],
                                  [7.595, 1.074],
                                  [7.555, 10.6],
                                  [-6.146, -17.685],
                                  [-2.432, -1.167],
                                  [-10.844, -0.609],
                                  [-11.348, 1.502],
                                  [-3.603, 1.004],
                                  [7.246, 6.706],
                                ],
                                o: [
                                  [-5.821, 0.889],
                                  [-7.164, -5.535],
                                  [-6.348, -0.904],
                                  [-0.457, 13.263],
                                  [1.043, 3.056],
                                  [1.981, 0.95],
                                  [13.156, 1.493],
                                  [19.96, -2.109],
                                  [1.969, -5.09],
                                  [-7.449, -8.128],
                                ],
                                v: [
                                  [15.908, -27.079],
                                  [-7.996, -18.223],
                                  [-20.776, -28.593],
                                  [-39.146, -27.421],
                                  [-45.432, 10.779],
                                  [-39.856, 15.064],
                                  [-18.152, 17.556],
                                  [17.197, 15.693],
                                  [50.751, 9.567],
                                  [40.792, -12.252],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [8.139, 6.665],
                                  [9.363, -0.711],
                                  [7.486, 1.519],
                                  [5.213, 13.618],
                                  [-0.566, -21.674],
                                  [-4.938, -2.368],
                                  [-11.221, -1.149],
                                  [-14.328, 4.584],
                                  [-3.584, 1.919],
                                  [11.568, 8.373],
                                ],
                                o: [
                                  [-3.972, 2.757],
                                  [-7.272, -5.619],
                                  [-6.366, -1.292],
                                  [-1.875, 7.482],
                                  [1.344, 4.932],
                                  [4.022, 1.929],
                                  [9.616, 1.886],
                                  [18.6, -7.084],
                                  [0.152, -6.575],
                                  [-8.852, -6.047],
                                ],
                                v: [
                                  [10.794, -37.376],
                                  [-14.776, -24.974],
                                  [-26.166, -32.227],
                                  [-40.549, -32.567],
                                  [-48.503, -0.551],
                                  [-39.772, 9.939],
                                  [-11.832, 15.752],
                                  [22.631, 8.486],
                                  [50.18, -3.131],
                                  [32.885, -25.356],
                                ],
                              },
                            ],
                            t: 23,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.437, 8.236],
                                  [9.223, -0.7],
                                  [7.773, 2.791],
                                  [2.515, 13.394],
                                  [-6.56, -18.84],
                                  [-7.296, -3.499],
                                  [-11.325, 1.17],
                                  [-8.063, 3.455],
                                  [-3.459, 2.778],
                                  [11.662, 5.069],
                                ],
                                o: [
                                  [-2.005, 4.542],
                                  [-7.164, -5.535],
                                  [-6.068, -2.179],
                                  [-1.548, 11.054],
                                  [2.253, 6.47],
                                  [5.943, 2.85],
                                  [13.378, -1.382],
                                  [10, -4.285],
                                  [4.403, -4.8],
                                  [-9.992, -3.786],
                                ],
                                v: [
                                  [6.624, -42.585],
                                  [-17.807, -25.827],
                                  [-30.773, -35.01],
                                  [-47.696, -42.912],
                                  [-51.992, -5.493],
                                  [-37.453, 6.302],
                                  [-3.584, 11.622],
                                  [26.283, 3.402],
                                  [43.968, -7.899],
                                  [26.247, -32.545],
                                ],
                              },
                            ],
                            t: 24,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [8.298, 6.56],
                                  [9.06, -1.509],
                                  [7.866, 2.32],
                                  [6.661, 10.04],
                                  [-2.758, -13.867],
                                  [-7.227, -3.601],
                                  [-11.062, -1.712],
                                  [-11.547, 6.448],
                                  [-1.709, 4.398],
                                  [12.362, 3.895],
                                ],
                                o: [
                                  [-1.277, 4.762],
                                  [-7.576, -4.84],
                                  [-6.141, -1.811],
                                  [-0.846, 10.193],
                                  [1.341, 6.74],
                                  [5.622, 2.788],
                                  [11.005, 0.355],
                                  [9.712, -6.336],
                                  [4.225, -6.053],
                                  [-10.196, -2.932],
                                ],
                                v: [
                                  [3.252, -40.942],
                                  [-17.989, -21.743],
                                  [-31.341, -31.573],
                                  [-46.7, -37.563],
                                  [-52.35, -5.664],
                                  [-38.091, 6.744],
                                  [-5.588, 15.465],
                                  [25.658, 7.417],
                                  [44.565, -8.067],
                                  [24.661, -32.05],
                                ],
                              },
                            ],
                            t: 25,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [9.686, 4.887],
                                  [8.841, -2.242],
                                  [7.94, 1.821],
                                  [6.058, 13.923],
                                  [-1.78, -8.496],
                                  [-6.709, -3.472],
                                  [-10.845, -1.664],
                                  [-10.448, 4.379],
                                  [-2.289, 3.799],
                                  [10.932, 3.614],
                                ],
                                o: [
                                  [-1.23, 4.533],
                                  [-7.897, -4.164],
                                  [-6.219, -1.423],
                                  [-0.068, 11.148],
                                  [1.298, 6.414],
                                  [4.969, 2.555],
                                  [11.66, 1.485],
                                  [11.16, -4.913],
                                  [4.525, -7.922],
                                  [-10.019, -3.061],
                                ],
                                v: [
                                  [1.852, -36.994],
                                  [-18.933, -17.918],
                                  [-33.577, -26.837],
                                  [-46.42, -33.218],
                                  [-52.266, -2.633],
                                  [-39.092, 9.608],
                                  [-8.237, 19.169],
                                  [20.13, 16.523],
                                  [41.456, -1.861],
                                  [24.915, -29.232],
                                ],
                              },
                            ],
                            t: 26,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.073, 3.215],
                                  [8.622, -2.974],
                                  [8.015, 1.321],
                                  [9.276, 13.463],
                                  [-1.573, -4.834],
                                  [-6.192, -3.343],
                                  [-10.628, -1.617],
                                  [-9.591, 3.492],
                                  [-2.869, 3.2],
                                  [9.503, 3.332],
                                ],
                                o: [
                                  [-1.182, 4.304],
                                  [-8.218, -3.488],
                                  [-6.297, -1.035],
                                  [-3.316, 9.505],
                                  [1.487, 6.046],
                                  [4.316, 2.322],
                                  [12.315, 2.616],
                                  [14.088, -6.385],
                                  [4.824, -9.792],
                                  [-9.841, -3.189],
                                ],
                                v: [
                                  [0.056, -34.312],
                                  [-19.877, -14.092],
                                  [-31.328, -22.886],
                                  [-45.973, -27.891],
                                  [-52.182, 0.397],
                                  [-40.094, 12.473],
                                  [-10.885, 22.872],
                                  [18.026, 25.46],
                                  [38.514, 4.797],
                                  [25.499, -23.312],
                                ],
                              },
                            ],
                            t: 27,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.44, 2.915],
                                  [8.694, -2.747],
                                  [8, 1.148],
                                  [9.666, 12.22],
                                  [-3.697, -9.426],
                                  [-5.28, -2.905],
                                  [-10.549, -1.421],
                                  [-9.74, 2.821],
                                  [-3.332, 3.392],
                                  [8.418, 4.219],
                                ],
                                o: [
                                  [-2.138, 3.665],
                                  [-8.121, -3.7],
                                  [-6.326, -0.903],
                                  [-0.728, 10.099],
                                  [1.382, 5.371],
                                  [3.574, 1.966],
                                  [12.257, 3.419],
                                  [16.13, -5.284],
                                  [5.089, -9.007],
                                  [-9.2, -4.618],
                                ],
                                v: [
                                  [-0.588, -32.227],
                                  [-18.849, -12.609],
                                  [-32.427, -23.172],
                                  [-49.413, -25.505],
                                  [-51.56, 3.446],
                                  [-41.152, 14.113],
                                  [-14.268, 24.984],
                                  [15.095, 26.642],
                                  [39.631, 6.379],
                                  [28.359, -21.229],
                                ],
                              },
                            ],
                            t: 28,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.806, 2.615],
                                  [8.766, -2.519],
                                  [7.985, 0.975],
                                  [9.216, 12.405],
                                  [-5.82, -14.018],
                                  [-4.368, -2.468],
                                  [-10.471, -1.224],
                                  [-9.889, 2.149],
                                  [-3.795, 3.583],
                                  [7.334, 5.107],
                                ],
                                o: [
                                  [-3.093, 3.026],
                                  [-8.024, -3.913],
                                  [-6.356, -0.771],
                                  [-2.205, 13.058],
                                  [1.276, 4.695],
                                  [2.831, 1.61],
                                  [12.198, 4.222],
                                  [18.172, -4.182],
                                  [5.355, -8.223],
                                  [-8.558, -6.047],
                                ],
                                v: [
                                  [2.275, -27.466],
                                  [-17.821, -11.125],
                                  [-30.923, -22.624],
                                  [-48.313, -25.091],
                                  [-50.937, 6.494],
                                  [-42.21, 15.753],
                                  [-17.65, 27.097],
                                  [12.165, 27.824],
                                  [40.747, 7.961],
                                  [34.726, -16.47],
                                ],
                              },
                            ],
                            t: 29,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.806, 2.615],
                                  [8.766, -2.519],
                                  [7.985, 0.975],
                                  [9.216, 12.405],
                                  [-5.82, -14.018],
                                  [-4.368, -2.468],
                                  [-10.471, -1.224],
                                  [-9.889, 2.149],
                                  [-3.795, 3.583],
                                  [7.334, 5.107],
                                ],
                                o: [
                                  [-3.093, 3.026],
                                  [-8.024, -3.913],
                                  [-6.356, -0.771],
                                  [-2.205, 13.058],
                                  [1.276, 4.695],
                                  [2.831, 1.61],
                                  [12.198, 4.222],
                                  [18.172, -4.182],
                                  [5.355, -8.223],
                                  [-8.558, -6.047],
                                ],
                                v: [
                                  [2.275, -27.466],
                                  [-17.821, -11.125],
                                  [-30.923, -22.624],
                                  [-46.624, -25.23],
                                  [-50.937, 6.494],
                                  [-42.21, 15.753],
                                  [-17.65, 27.097],
                                  [12.165, 27.824],
                                  [40.747, 7.961],
                                  [34.726, -16.47],
                                ],
                              },
                            ],
                            t: 30,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.978, 1.477],
                                  [8.827, -2.677],
                                  [7.893, 0.726],
                                  [7.952, 6.9],
                                  [-4.872, -8.703],
                                  [-1.728, -1.015],
                                  [-10.513, -0.557],
                                  [-11.022, 0.703],
                                  [-2.945, 1.306],
                                  [2.425, 3.298],
                                ],
                                o: [
                                  [-5.892, 1.484],
                                  [-8.197, -3.748],
                                  [-6.405, -0.588],
                                  [-2.558, 12.839],
                                  [0.966, 2.636],
                                  [1.044, 0.627],
                                  [10.345, 0.998],
                                  [21.844, -1.471],
                                  [0.692, -6.87],
                                  [-6.86, -9.045],
                                ],
                                v: [
                                  [10.174, -23.227],
                                  [-13.276, -8.798],
                                  [-28.057, -23.035],
                                  [-45.236, -19.925],
                                  [-49.335, 15.174],
                                  [-45.265, 19.365],
                                  [-25.4, 23.739],
                                  [2.758, 24.96],
                                  [44.627, 4.523],
                                  [40.793, -8.351],
                                ],
                              },
                            ],
                            t: 31,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.443, 2.956],
                                  [9.163, -0.955],
                                  [7.854, 0.689],
                                  [8.331, 8.28],
                                  [-5.108, -15.713],
                                  [-0.864, -0.508],
                                  [-10.569, -0.05],
                                  [-11.507, 0.622],
                                  [-3.379, 0.6],
                                  [3.942, 5.346],
                                ],
                                o: [
                                  [-6.881, 0.014],
                                  [-7.288, -5.31],
                                  [-6.415, -0.562],
                                  [-0.707, 9.749],
                                  [0.895, 1.925],
                                  [0.522, 0.313],
                                  [10.011, 0.291],
                                  [22.648, -1.265],
                                  [1.048, -5.99],
                                  [-6.777, -9.508],
                                ],
                                v: [
                                  [14.06, -21.259],
                                  [-9.907, -13.116],
                                  [-24.041, -24.576],
                                  [-42.788, -23.49],
                                  [-48.528, 18.092],
                                  [-45.933, 20.424],
                                  [-26.826, 22.967],
                                  [3.31, 22.671],
                                  [50.232, 14.284],
                                  [44.002, -3.57],
                                ],
                              },
                            ],
                            t: 32,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.712, -9.877],
                                  [0, 0],
                                  [-10.625, 0.457],
                                  [-11.992, 0.541],
                                  [-3.663, 0.304],
                                  [3.356, 4.998],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.825, 1.213],
                                  [0, 0],
                                  [9.678, -0.416],
                                  [23.451, -1.059],
                                  [0.484, -5.266],
                                  [-6.694, -9.97],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-41.118, -19.362],
                                  [-47.721, 21.009],
                                  [-46.602, 21.483],
                                  [-28.253, 22.194],
                                  [5.609, 21.464],
                                  [52.068, 18.721],
                                  [45.753, 0.599],
                                ],
                              },
                            ],
                            t: 33,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.302, 5.403],
                                  [9.176, 1.165],
                                  [5.084, -2.509],
                                  [6.353, 8.487],
                                  [-4.202, -11.178],
                                  [0, 0],
                                  [-10.435, -2.053],
                                  [-11.784, -2.293],
                                  [-3.632, -0.566],
                                  [-1.181, 5.903],
                                ],
                                o: [
                                  [-7.384, -2.469],
                                  [-5.908, -6.86],
                                  [-5.782, 2.853],
                                  [-6.698, 14.804],
                                  [0.516, 1.373],
                                  [0, 0],
                                  [9.504, 1.87],
                                  [23.043, 4.484],
                                  [1.708, -5.005],
                                  [4.448, -22.232],
                                ],
                                v: [
                                  [22.898, -22.349],
                                  [-4.25, -21.089],
                                  [-22.373, -23.905],
                                  [-44.82, -17.886],
                                  [-53.09, 35.477],
                                  [-48.815, 36.514],
                                  [-31.824, 36.285],
                                  [-1.416, 28.071],
                                  [43.449, 33.565],
                                  [55.586, 19.697],
                                ],
                              },
                            ],
                            t: 34,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.852, 4.192],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.996, -9.678],
                                  [-1.219, -1.481],
                                  [-8.167, 4.103],
                                  [-16.427, -0.169],
                                  [-2.938, -6.081],
                                  [5.107, 4.768],
                                ],
                                o: [
                                  [-7.617, -1.61],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.859, 1.189],
                                  [0.988, -5.528],
                                  [10.651, -5.351],
                                  [11.875, 0.122],
                                  [4.366, -5.617],
                                  [-8.778, -8.195],
                                ],
                                v: [
                                  [17.672, -29.52],
                                  [-6.073, -27.87],
                                  [-20.83, -34.156],
                                  [-41.923, -27.4],
                                  [-52.34, 24.148],
                                  [-50.361, 31.032],
                                  [-20.204, 12.357],
                                  [19.686, -2.886],
                                  [56.714, 13.987],
                                  [51.631, -7.994],
                                ],
                              },
                            ],
                            t: 35,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [954.82, 850.825], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 2',
                  ix: 4,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.857, 0.259],
                                  [-7.807, 0.034],
                                  [-6.089, -0.14],
                                  [0, 0],
                                  [0, 0],
                                  [15.962, 0.057],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.007, -0.031],
                                  [12.873, 0.296],
                                  [11.729, -0.374],
                                  [0, 0],
                                  [-10.942, -0.039],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.314, 7.096],
                                  [-25.247, 6.98],
                                  [-3.899, 7.096],
                                  [39.315, 7.096],
                                  [49.233, -7.096],
                                  [-3.811, -6.861],
                                  [-49.232, -7.096],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.857, 0.259],
                                  [-7.807, 0.034],
                                  [-6.09, -0.02],
                                  [0, 0],
                                  [0, 0],
                                  [15.962, 0.057],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.007, -0.031],
                                  [16.061, 0.052],
                                  [11.729, -0.374],
                                  [0, 0],
                                  [-10.942, -0.039],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.314, 7.096],
                                  [-25.247, 6.98],
                                  [-4.082, 6.728],
                                  [39.315, 7.096],
                                  [49.233, -7.096],
                                  [-3.811, -6.861],
                                  [-49.232, -7.096],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.607, -0.167],
                                  [-8.03, -0.141],
                                  [-7.111, 0.319],
                                  [-0.245, 0.149],
                                  [0.144, 0.165],
                                  [16.427, 0.321],
                                  [0.145, 0.44],
                                ],
                                o: [
                                  [0.944, 0.456],
                                  [6.471, -0.044],
                                  [14.149, -0.087],
                                  [11.122, -0.47],
                                  [-0.396, 0.151],
                                  [-11.245, -0.217],
                                  [-0.034, -0.103],
                                ],
                                v: [
                                  [-39.713, 6.484],
                                  [-23.786, 7.14],
                                  [-0.982, 6.778],
                                  [40.08, 6.005],
                                  [49.243, -9.22],
                                  [-2.926, -7.934],
                                  [-48.482, -8.778],
                                ],
                              },
                            ],
                            t: 20,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.169, -0.915],
                                  [-8.422, -0.45],
                                  [-8.902, 0.913],
                                  [-0.674, 0.41],
                                  [0.397, 0.456],
                                  [17.241, 0.783],
                                  [0.399, 1.214],
                                ],
                                o: [
                                  [2.6, 1.255],
                                  [5.531, -0.068],
                                  [10.793, -0.333],
                                  [10.056, -0.639],
                                  [-1.092, 0.416],
                                  [-11.777, -0.53],
                                  [-0.094, -0.285],
                                ],
                                v: [
                                  [-40.411, 5.41],
                                  [-21.222, 7.422],
                                  [5.448, 5.829],
                                  [41.423, 4.09],
                                  [49.261, -12.949],
                                  [-1.371, -9.819],
                                  [-47.165, -11.731],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.647, -1.803],
                                  [-8.888, -0.816],
                                  [-11.03, 1.619],
                                  [-1.184, 0.72],
                                  [0.697, 0.801],
                                  [18.21, 1.333],
                                  [0.702, 2.133],
                                ],
                                o: [
                                  [4.569, 2.206],
                                  [8.094, 0.432],
                                  [14.221, -2.304],
                                  [8.789, -0.839],
                                  [-1.919, 0.732],
                                  [-12.41, -0.901],
                                  [-0.165, -0.5],
                                ],
                                v: [
                                  [-41.242, 4.132],
                                  [-18.173, 7.756],
                                  [14.55, 4.078],
                                  [43.869, -0.494],
                                  [49.282, -17.381],
                                  [0.477, -12.058],
                                  [-45.599, -15.24],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-9.58, -3.924],
                                  [-10.121, -1.692],
                                  [-9.324, 3.989],
                                  [-5.006, 2.096],
                                  [1.415, 1.626],
                                  [20.768, 2.639],
                                  [1.425, 4.33],
                                ],
                                o: [
                                  [9.242, 6.659],
                                  [10.508, -0.755],
                                  [12.672, -4.6],
                                  [5.938, -1.323],
                                  [-3.897, 1.485],
                                  [-14.087, -1.79],
                                  [-0.334, -1.015],
                                ],
                                v: [
                                  [-40.35, -1.852],
                                  [-8.837, 6.893],
                                  [25.223, -2.963],
                                  [49.886, -12.497],
                                  [49.004, -30.371],
                                  [3.633, -20.148],
                                  [-49.062, -24.579],
                                ],
                              },
                            ],
                            t: 23,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-8.227, -5.927],
                                  [-11.331, -0.285],
                                  [-10.733, 5.964],
                                  [-3.553, 2.159],
                                  [2.091, 2.402],
                                  [22.705, 3.884],
                                  [2.106, 6.398],
                                ],
                                o: [
                                  [13.657, 9.839],
                                  [16.339, 0.411],
                                  [17.027, -9.462],
                                  [2.909, -1.768],
                                  [-5.758, 2.195],
                                  [-15.346, -2.625],
                                  [-0.494, -1.5],
                                ],
                                v: [
                                  [-46.668, -8.94],
                                  [-10.851, 4.615],
                                  [24.194, -5.133],
                                  [46.597, -17.591],
                                  [41.984, -37.307],
                                  [3.609, -26.977],
                                  [-50.047, -32.166],
                                ],
                              },
                            ],
                            t: 24,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-8.074, -6.004],
                                  [-11.065, -2.623],
                                  [-10.416, 5.779],
                                  [-3.048, 2.72],
                                  [2.194, 2.296],
                                  [22.728, 2.417],
                                  [2.499, 5.942],
                                ],
                                o: [
                                  [13.461, 9.475],
                                  [12.948, 3.078],
                                  [16.108, -9.006],
                                  [4.12, -5.213],
                                  [-5.082, 2.872],
                                  [-15.361, -1.634],
                                  [-0.726, -1.429],
                                ],
                                v: [
                                  [-46.386, -7.216],
                                  [-10.369, 5.257],
                                  [24.392, -0.328],
                                  [45.799, -17.234],
                                  [42.739, -37.189],
                                  [5.331, -21.3],
                                  [-50.796, -31.946],
                                ],
                              },
                            ],
                            t: 25,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-8.26, -5.365],
                                  [-10.683, -2.487],
                                  [-9.281, 4.691],
                                  [-3.454, 5.046],
                                  [2.591, 2.84],
                                  [21.717, -1.068],
                                  [2.926, 4.342],
                                ],
                                o: [
                                  [11.411, 7.604],
                                  [11.192, 2.699],
                                  [13.976, -6.986],
                                  [5.99, -11.327],
                                  [-3.359, 3.456],
                                  [-14.697, 0.721],
                                  [-1.071, -1.117],
                                ],
                                v: [
                                  [-43.098, -1.625],
                                  [-14.575, 11.42],
                                  [21.924, 12.977],
                                  [41.824, -5.364],
                                  [37.821, -31.049],
                                  [4.842, -10.635],
                                  [-51.711, -25.074],
                                ],
                              },
                            ],
                            t: 27,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-9.167, -3.819],
                                  [-9.872, -1.849],
                                  [-8.299, 2.659],
                                  [-6.503, 7.345],
                                  [3.324, 4.021],
                                  [19.997, -0.051],
                                  [2.4, 2.743],
                                ],
                                o: [
                                  [7.996, 5.144],
                                  [9.571, 1.923],
                                  [13.193, -3.741],
                                  [6.449, -6.793],
                                  [-1.92, 2.907],
                                  [-13.573, 0.034],
                                  [-0.948, -0.742],
                                ],
                                v: [
                                  [-42.273, 3.795],
                                  [-14.485, 15.803],
                                  [17.733, 15.217],
                                  [42.429, -2.624],
                                  [42.342, -26.78],
                                  [2.698, -9.951],
                                  [-51.258, -18.732],
                                ],
                              },
                            ],
                            t: 29,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-9.167, -3.819],
                                  [-9.872, -1.849],
                                  [-8.362, 2.456],
                                  [-6.68, 7.184],
                                  [3.324, 4.021],
                                  [19.997, -0.051],
                                  [2.4, 2.743],
                                ],
                                o: [
                                  [7.996, 5.144],
                                  [9.571, 1.923],
                                  [13.28, -3.418],
                                  [6.613, -6.634],
                                  [-1.92, 2.907],
                                  [-13.573, 0.034],
                                  [-0.948, -0.742],
                                ],
                                v: [
                                  [-42.273, 3.795],
                                  [-14.485, 15.803],
                                  [17.18, 14.61],
                                  [42.304, -2.622],
                                  [42.342, -26.78],
                                  [2.698, -9.951],
                                  [-51.258, -18.732],
                                ],
                              },
                            ],
                            t: 30,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.739, -1.394],
                                  [-8.636, -0.748],
                                  [-6.814, 1.131],
                                  [-2.392, 4.369],
                                  [1.139, 0.049],
                                  [17.55, 0.257],
                                  [1.068, 0.972],
                                ],
                                o: [
                                  [3.145, 1.957],
                                  [7.884, 0.752],
                                  [11.862, -0.753],
                                  [8.291, -6.053],
                                  [-0.417, 1.385],
                                  [-11.976, -0.174],
                                  [-0.443, -0.277],
                                ],
                                v: [
                                  [-40.548, 9.307],
                                  [-19.699, 13.721],
                                  [6.101, 13.455],
                                  [43.277, -3.528],
                                  [43.52, -22.675],
                                  [-2.794, -8.726],
                                  [-50.305, -10.224],
                                ],
                              },
                            ],
                            t: 31,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.828, 0.863],
                                  [-7.806, -0.113],
                                  [-7.773, 0.424],
                                  [0, 0],
                                  [0, 0],
                                  [15.944, -0.758],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [8.087, 0.117],
                                  [16.694, -0.91],
                                  [12.409, -1.877],
                                  [0, 0],
                                  [-10.929, 0.519],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.314, 8.988],
                                  [-23.91, 9.63],
                                  [4.759, 9.609],
                                  [41.809, 7.937],
                                  [50.952, -7.242],
                                  [-3.401, -4.519],
                                  [-48.944, -4.68],
                                ],
                              },
                            ],
                            t: 33,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.7, -1.942],
                                  [-7.561, -1.945],
                                  [-7.655, -1.416],
                                  [0, 0],
                                  [0, 0],
                                  [15.676, 3.012],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.833, 2.015],
                                  [16.44, 3.041],
                                  [12.503, 1.093],
                                  [0, 0],
                                  [-10.745, -2.065],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.319, 15.613],
                                  [-33.402, 9.18],
                                  [-5.532, 15.9],
                                  [20.125, 16.353],
                                  [42.392, 7.62],
                                  [-10.141, 0.248],
                                  [-52.438, -0.17],
                                ],
                              },
                            ],
                            t: 34,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-2.065, -4.797],
                                  [-6.253, -0.986],
                                  [-10.682, 4.179],
                                  [7.938, 11.962],
                                  [0, 0],
                                  [14.386, 2.58],
                                  [1.421, -2.463],
                                ],
                                o: [
                                  [2.833, 6.583],
                                  [8.247, 1.3],
                                  [9.242, -3.616],
                                  [-3.954, -7.779],
                                  [0, 0],
                                  [-8.861, -1.589],
                                  [-2.899, 5.022],
                                ],
                                v: [
                                  [-39.74, 12.308],
                                  [-10.295, 23.902],
                                  [16.321, 16.949],
                                  [39.328, -7.697],
                                  [27.987, -20.815],
                                  [18.399, -22.825],
                                  [-40.891, -0.99],
                                ],
                              },
                            ],
                            t: 35,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [955.975, 876.533], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 8',
                  ix: 5,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.348, 6.543],
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.349, -6.542],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.348, -6.542],
                                  [0, 0],
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.349, 6.543],
                                ],
                                v: [
                                  [-9.73, 17.629],
                                  [-2.836, 17.629],
                                  [13.263, 5.782],
                                  [17.325, -5.531],
                                  [9.732, -17.377],
                                  [-0.226, -17.629],
                                  [-13.263, -5.531],
                                  [-17.323, 5.782],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.348, 6.543],
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.349, -6.542],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.348, -6.542],
                                  [0, 0],
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.349, 6.543],
                                ],
                                v: [
                                  [-9.73, 17.629],
                                  [-2.836, 17.629],
                                  [13.263, 5.782],
                                  [15.575, -7.531],
                                  [8.982, -20.377],
                                  [-6.476, -19.879],
                                  [-14.013, -5.281],
                                  [-17.323, 5.782],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.439, 0.235],
                                  [0, 0],
                                  [-2.076, 6.524],
                                  [0, 0],
                                  [6.382, 0.525],
                                  [1.102, -0.821],
                                  [1.53, -6.756],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.439, -0.235],
                                  [0, 0],
                                  [1.704, -6.577],
                                  [0, 0],
                                  [-6.384, 0.58],
                                  [0, 0],
                                  [-2.077, 6.524],
                                ],
                                v: [
                                  [-8.962, 12.959],
                                  [-2.176, 12.711],
                                  [13.242, 0.473],
                                  [16.214, -11.207],
                                  [9.506, -23.928],
                                  [-5.443, -22.449],
                                  [-13.642, -7.962],
                                  [-16.851, 2.522],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.36, 0.413],
                                  [0, 0],
                                  [-1.87, 6.51],
                                  [0, 0],
                                  [6.26, 0.922],
                                  [1.936, -1.443],
                                  [0.909, -6.919],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.36, -0.413],
                                  [0, 0],
                                  [1.216, -6.604],
                                  [0, 0],
                                  [-6.264, 1.02],
                                  [0, 0],
                                  [-1.871, 6.51],
                                ],
                                v: [
                                  [-9.876, 9.242],
                                  [-3.173, 8.806],
                                  [11.731, -3.728],
                                  [15.203, -14.173],
                                  [8.408, -26.799],
                                  [-6.158, -24.576],
                                  [-14.857, -10.173],
                                  [-16.801, -2.13],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.135, 2.055],
                                  [0, 0],
                                  [-0.147, 6.874],
                                  [0, 0],
                                  [6.188, 0.662],
                                  [3.637, -3.287],
                                  [-1.874, -7.518],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.135, -2.055],
                                  [0, 0],
                                  [-1.194, -6.939],
                                  [0, 0],
                                  [-5.824, 3.263],
                                  [0, 0],
                                  [-0.148, 6.874],
                                ],
                                v: [
                                  [-9.819, 6.576],
                                  [-3.353, 4.41],
                                  [8.023, -11.757],
                                  [8.914, -22.049],
                                  [0.464, -34.241],
                                  [-18.44, -26.698],
                                  [-20.026, -8.197],
                                  [-18.29, -3.054],
                                ],
                              },
                            ],
                            t: 23,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.795, 3.037],
                                  [0, 0],
                                  [0.957, 6.885],
                                  [0, 0],
                                  [5.873, 0.978],
                                  [5.374, -4.857],
                                  [-3.943, -7.837],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.795, -3.036],
                                  [0, 0],
                                  [-2.938, -6.983],
                                  [0, 0],
                                  [-5.335, 4.822],
                                  [0, 0],
                                  [0.956, 6.886],
                                ],
                                v: [
                                  [-12.148, 3.324],
                                  [-6.04, 0.123],
                                  [2.719, -17.843],
                                  [3.592, -26.54],
                                  [-6.716, -37.467],
                                  [-24.283, -27.849],
                                  [-20.617, -6.962],
                                  [-24.372, -3.646],
                                ],
                              },
                            ],
                            t: 24,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.314, 3.737],
                                  [0, 0],
                                  [1.986, 6.611],
                                  [0, 0],
                                  [5.682, -0.125],
                                  [4.536, -5.583],
                                  [-4.594, -7.402],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.225, -3.86],
                                  [0, 0],
                                  [-3.944, -6.406],
                                  [0, 0],
                                  [-4.503, 5.543],
                                  [0, 0],
                                  [1.343, 6.771],
                                ],
                                v: [
                                  [-9.831, 7.418],
                                  [-4.063, 3.822],
                                  [0.824, -16.412],
                                  [0.108, -24.498],
                                  [-6.613, -36.837],
                                  [-21.241, -23.926],
                                  [-20.769, -2.838],
                                  [-25.818, 0.843],
                                ],
                              },
                            ],
                            t: 25,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.084, 3.877],
                                  [0, 0],
                                  [2.357, 6.363],
                                  [0, 0],
                                  [5.433, -0.871],
                                  [3.685, -5.587],
                                  [-4.764, -6.869],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [4.887, -4.11],
                                  [0, 0],
                                  [-4.151, -5.992],
                                  [0, 0],
                                  [-4.063, 5.601],
                                  [0, 0],
                                  [1.745, 6.544],
                                ],
                                v: [
                                  [-10.916, 13.397],
                                  [-4.928, 10.121],
                                  [0.067, -14.978],
                                  [-0.644, -20.307],
                                  [-7.322, -34.371],
                                  [-20.622, -19.027],
                                  [-21.986, 0.379],
                                  [-25.115, 4.22],
                                ],
                              },
                            ],
                            t: 26,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-4.855, 4.017],
                                  [0, 0],
                                  [2.728, 6.114],
                                  [0, 0],
                                  [5.184, -1.618],
                                  [2.833, -5.592],
                                  [-4.933, -6.337],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [4.549, -4.36],
                                  [0, 0],
                                  [-4.358, -5.578],
                                  [0, 0],
                                  [-3.623, 5.66],
                                  [0, 0],
                                  [2.146, 6.317],
                                ],
                                v: [
                                  [-12, 19.377],
                                  [-5.792, 16.419],
                                  [-0.359, -10.442],
                                  [-2.596, -17.556],
                                  [-7.7, -28.804],
                                  [-20.002, -14.128],
                                  [-23.204, 3.596],
                                  [-24.412, 7.596],
                                ],
                              },
                            ],
                            t: 27,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.069, 3.526],
                                  [0, 0],
                                  [2.082, 6.213],
                                  [0, 0],
                                  [5.3, -1.557],
                                  [2.445, -4.786],
                                  [-3.963, -6.371],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [4.842, -3.82],
                                  [0, 0],
                                  [-3.481, -5.758],
                                  [0, 0],
                                  [-4.052, 4.937],
                                  [0, 0],
                                  [1.45, 6.373],
                                ],
                                v: [
                                  [-10.997, 18.456],
                                  [-5.435, 17.216],
                                  [-1.16, -8.227],
                                  [-2.779, -15.785],
                                  [-8.589, -27.807],
                                  [-20.13, -11.902],
                                  [-22.862, 3.819],
                                  [-23.824, 7.905],
                                ],
                              },
                            ],
                            t: 28,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.283, 3.035],
                                  [0, 0],
                                  [1.437, 6.312],
                                  [0, 0],
                                  [5.416, -1.496],
                                  [2.056, -3.98],
                                  [-2.994, -6.405],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.135, -3.279],
                                  [0, 0],
                                  [-2.605, -5.938],
                                  [0, 0],
                                  [-4.481, 4.213],
                                  [0, 0],
                                  [0.754, 6.43],
                                ],
                                v: [
                                  [-9.993, 17.535],
                                  [-5.078, 18.012],
                                  [1.546, -3.335],
                                  [0.546, -11.338],
                                  [-5.971, -24.134],
                                  [-20.258, -9.676],
                                  [-22.521, 4.042],
                                  [-23.235, 8.214],
                                ],
                              },
                            ],
                            t: 29,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.283, 3.035],
                                  [0, 0],
                                  [1.437, 6.312],
                                  [0, 0],
                                  [5.416, -1.496],
                                  [2.056, -3.98],
                                  [-2.994, -6.405],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.135, -3.279],
                                  [0, 0],
                                  [-2.605, -5.938],
                                  [0, 0],
                                  [-4.481, 4.213],
                                  [0, 0],
                                  [0.754, 6.43],
                                ],
                                v: [
                                  [-9.993, 17.535],
                                  [-5.078, 18.012],
                                  [1.546, -3.335],
                                  [0.546, -11.338],
                                  [-5.971, -24.134],
                                  [-20.258, -9.676],
                                  [-22.521, 4.042],
                                  [-23.235, 8.214],
                                ],
                              },
                            ],
                            t: 30,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.128, 1.258],
                                  [0, 0],
                                  [0.163, 6.581],
                                  [0, 0],
                                  [5.945, -1.589],
                                  [0.771, -1.607],
                                  [-0.94, -6.514],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.868, -2.236],
                                  [0, 0],
                                  [-0.628, -6.415],
                                  [0, 0],
                                  [-5.591, 2.596],
                                  [0, 0],
                                  [-0.942, 6.581],
                                ],
                                v: [
                                  [-14.429, 17.677],
                                  [-5.763, 19.738],
                                  [7.596, -0.063],
                                  [7.875, -10.152],
                                  [-1.943, -19.808],
                                  [-16.385, -11.79],
                                  [-22.051, 2.441],
                                  [-24.033, 7.389],
                                ],
                              },
                            ],
                            t: 31,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.314, 0.997],
                                  [0, 0],
                                  [-1.219, 6.672],
                                  [0, 0],
                                  [6.292, -0.713],
                                  [0.487, -0.753],
                                  [1.055, -6.658],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.314, -0.997],
                                  [0, 0],
                                  [0.979, -6.62],
                                  [0, 0],
                                  [-6.186, 1.24],
                                  [0, 0],
                                  [-1.442, 6.628],
                                ],
                                v: [
                                  [-12.871, 16.784],
                                  [-6.461, 16.538],
                                  [9.95, 1.261],
                                  [12.248, -9.594],
                                  [3.716, -20.611],
                                  [-8.906, -17.205],
                                  [-18.465, -4.683],
                                  [-21.392, 5.759],
                                ],
                              },
                            ],
                            t: 32,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.501, 0.735],
                                  [0, 0],
                                  [-1.941, 6.675],
                                  [0, 0],
                                  [6.53, -0.403],
                                  [0, 0],
                                  [1.942, -6.674],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.501, -0.735],
                                  [0, 0],
                                  [1.941, -6.674],
                                  [0, 0],
                                  [-6.53, 0.403],
                                  [0, 0],
                                  [-1.942, 6.675],
                                ],
                                v: [
                                  [-11.314, 15.892],
                                  [-4.463, 15.117],
                                  [11.778, 2.248],
                                  [15.136, -9.294],
                                  [6.828, -20.65],
                                  [-3.125, -20.289],
                                  [-15.393, -7.412],
                                  [-18.75, 4.13],
                                ],
                              },
                            ],
                            t: 33,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-7.269, -1.355],
                                  [0, 0],
                                  [-4.1, 5.856],
                                  [0, 0],
                                  [7.328, 0.987],
                                  [0, 0],
                                  [3.06, -6.461],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.391, 0.231],
                                  [0, 0],
                                  [3.704, -6.113],
                                  [0, 0],
                                  [-7.328, -0.987],
                                  [0, 0],
                                  [-3.06, 6.462],
                                ],
                                v: [
                                  [-11.72, 19.124],
                                  [-0.449, 16.677],
                                  [20.73, 1.374],
                                  [22.341, -7.589],
                                  [14.878, -19.301],
                                  [-1.97, -20.37],
                                  [-13.446, -6.516],
                                  [-21.04, 1.002],
                                ],
                              },
                            ],
                            t: 34,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.535, -0.297],
                                  [0, 0],
                                  [-3.679, 5.898],
                                  [0, 0],
                                  [6.142, 2.534],
                                  [0, 0],
                                  [3.68, -5.897],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.535, 0.297],
                                  [0, 0],
                                  [3.678, -5.897],
                                  [0, 0],
                                  [-6.491, -0.818],
                                  [0, 0],
                                  [-3.149, 6.198],
                                ],
                                v: [
                                  [-12.406, 6.427],
                                  [-5.518, 6.74],
                                  [13.918, -5.166],
                                  [18.896, -12.816],
                                  [19.027, -24.745],
                                  [2.185, -28.81],
                                  [-12.493, -22.434],
                                  [-20.393, -6.773],
                                ],
                              },
                            ],
                            t: 35,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [957.152, 832.241], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 16,
              parent: 21,
            },
            {
              ty: 4,
              nm: 'left bottom leg ',
              sr: 1,
              st: -36,
              op: 24,
              ip: 12,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [880, 769, 0], ix: 1 },
                s: { a: 0, k: [121.342, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [904.292, 768.19, 0],
                      t: 12,
                      ti: [2.211, 0.318, 0],
                      to: [-0.627, -0.318, 0],
                    },
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [906.662, 756.62, 0],
                      t: 13,
                      ti: [-1.083, 0.333, 0],
                      to: [-2.211, -0.318, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.136 },
                      i: { x: 0.833, y: 0.864 },
                      s: [891.278, 749.905, 0],
                      t: 14,
                      ti: [-3.125, 0.512, 0],
                      to: [0.703, -0.216, 0],
                    },
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [911.188, 756.413, 0],
                      t: 16,
                      ti: [-0.672, 0.117, 0],
                      to: [1.687, -0.277, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.005 },
                      i: { x: 0.833, y: 0.995 },
                      s: [909.528, 758.03, 0],
                      t: 17,
                      ti: [0.503, 1.444, 0],
                      to: [1.444, -0.251, 0],
                    },
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [907.153, 759.347, 0],
                      t: 21,
                      ti: [0.007, 0.205, 0],
                      to: [-0.164, -0.472, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.16 },
                      i: { x: 0.833, y: 0.84 },
                      s: [906.85, 759.283, 0],
                      t: 22,
                      ti: [-0.823, 0.13, 0],
                      to: [-0.03, -0.833, 0],
                    },
                    { s: [911.788, 758.501, 0], t: 23 },
                  ],
                  ix: 2,
                },
                r: { a: 0, k: 12, ix: 10 },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-38.541, -7.725],
                                  [-34.373, 20.269],
                                  [6.355, 11.687],
                                  [5.134, -15.293],
                                ],
                              },
                            ],
                            t: -10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-25.117, -16.712],
                                  [-25.809, 17.275],
                                  [16.12, 8.334],
                                  [19.812, -21.992],
                                ],
                              },
                            ],
                            t: -8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [6.569, -5.843],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-23.582, -15.505],
                                  [-25.668, 16.734],
                                  [15.142, 8.542],
                                  [20.093, -23.074],
                                ],
                              },
                            ],
                            t: -6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-25.444, -19.454],
                                  [-26.136, 14.533],
                                  [14.674, 6.341],
                                  [18.231, -27.023],
                                ],
                              },
                            ],
                            t: -3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.58, -8.631],
                                  [-4.84, 3.889],
                                  [7.497, 4.925],
                                ],
                                o: [
                                  [-4.969, 6.82],
                                  [12.108, 12.707],
                                  [6.015, -3.024],
                                  [-3.335, -2.191],
                                ],
                                v: [
                                  [-17.56, -34.542],
                                  [-23.453, 0.335],
                                  [18.325, 3.806],
                                  [24.218, -26.905],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.289, -8.846],
                                  [-4.967, 3.725],
                                  [7.329, 5.172],
                                ],
                                o: [
                                  [-5.193, 6.65],
                                  [11.678, 13.104],
                                  [6.112, -2.821],
                                  [-3.26, -2.301],
                                ],
                                v: [
                                  [-17.009, -31.93],
                                  [-24.062, 2.732],
                                  [17.577, 7.594],
                                  [24.491, -22.904],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.979, -8.312],
                                  [-7.722, 6.879],
                                  [7.72, 4.567],
                                ],
                                o: [
                                  [-4.642, 7.046],
                                  [12.693, 12.123],
                                  [7.256, -5.255],
                                  [-3.434, -2.031],
                                ],
                                v: [
                                  [-19.14, -24.595],
                                  [-23.385, 10.522],
                                  [17.003, 13.359],
                                  [22.951, -18.933],
                                ],
                              },
                            ],
                            t: 2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-7.012, -8.284],
                                  [-7.694, 6.909],
                                  [7.739, 4.536],
                                ],
                                o: [
                                  [-4.614, 7.064],
                                  [12.741, 12.073],
                                  [7.235, -5.284],
                                  [-3.442, -2.018],
                                ],
                                v: [
                                  [-21.396, -19.887],
                                  [-23.311, 17.068],
                                  [17.088, 19.747],
                                  [22.908, -12.569],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-5.965, -9.067],
                                  [-8.469, 5.934],
                                  [7.137, 5.434],
                                ],
                                o: [
                                  [-5.43, 6.459],
                                  [11.197, 13.517],
                                  [7.818, -4.376],
                                  [-3.175, -2.417],
                                ],
                                v: [
                                  [-7.752, -17.829],
                                  [-16.052, 16.555],
                                  [23.733, 24.07],
                                  [33.395, -7.312],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-5.63, -9.279],
                                  [-9.515, 4.351],
                                  [6.934, 5.691],
                                ],
                                o: [
                                  [-5.662, 6.256],
                                  [10.695, 13.917],
                                  [7.973, -4.087],
                                  [-3.084, -2.531],
                                ],
                                v: [
                                  [-2.636, -23.792],
                                  [-12.188, 10.266],
                                  [27.183, 20.255],
                                  [38.099, -11.777],
                                ],
                              },
                            ],
                            t: 8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.521, -8.676],
                                  [-7.883, 2.874],
                                  [7.463, 4.977],
                                ],
                                o: [
                                  [-5.015, 6.785],
                                  [9.841, 6.714],
                                  [7.529, -4.856],
                                  [-3.32, -2.214],
                                ],
                                v: [
                                  [-6.227, -22.573],
                                  [-11.599, 12.047],
                                  [28.612, 17.091],
                                  [38.54, -15.513],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [7.242, -3.203],
                                  [-3.575, -12.661],
                                  [-6.397, -4.823],
                                  [4.774, 7.873],
                                ],
                                o: [
                                  [-5.514, 2.438],
                                  [19.514, -1.327],
                                  [4.781, -2.018],
                                  [-5.823, -9.603],
                                ],
                                v: [
                                  [-9.122, -17.135],
                                  [-14.507, 5.882],
                                  [26.832, 11.181],
                                  [33.6, -7.895],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [9.164, -6.875],
                                  [-3.575, -12.661],
                                  [-6.397, -4.823],
                                  [4.774, 7.873],
                                ],
                                o: [
                                  [-4.823, 3.618],
                                  [14.981, -7.746],
                                  [4.781, -2.018],
                                  [-5.823, -9.603],
                                ],
                                v: [
                                  [-13.909, -20.675],
                                  [-18.461, 5.938],
                                  [22.593, 10.382],
                                  [29.932, -12.684],
                                ],
                              },
                            ],
                            t: 11,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [9.128, -9.234],
                                  [-3.469, -6.572],
                                  [-6.467, -4.728],
                                  [4.889, 7.802],
                                ],
                                o: [
                                  [-3.133, 3.169],
                                  [14.868, -3.407],
                                  [4.751, -2.088],
                                  [-5.963, -9.516],
                                ],
                                v: [
                                  [-18.225, -12.922],
                                  [-18.625, 7.034],
                                  [22.034, 13.012],
                                  [29.955, -8.318],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [14.027, -5.181],
                                  [-3.887, -8.103],
                                  [-6.695, -0.406],
                                  [9.207, 0.03],
                                ],
                                o: [
                                  [-4.18, 1.544],
                                  [13.05, -1.037],
                                  [4.127, -3.146],
                                  [-8.614, -0.028],
                                ],
                                v: [
                                  [-31.769, -4.843],
                                  [-29.688, 16.565],
                                  [11.024, 14.394],
                                  [11.146, -9.24],
                                ],
                              },
                            ],
                            t: 13,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [881.203, 765.949], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 17,
              parent: 21,
            },
            {
              ty: 4,
              nm: 'left shoe ',
              sr: 1,
              st: -50,
              op: 12,
              ip: 10,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [826, 739.5, 0], ix: 1 },
                s: { a: 0, k: [-136, 136, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.157 },
                      i: { x: 0.833, y: 0.843 },
                      s: [430.861, 260.049, 0],
                      t: 10,
                      ti: [-2.333, -0.833, 0],
                      to: [2.333, 0.833, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [444.861, 265.049, 0],
                      t: 11,
                      ti: [0, 0, 0],
                      to: [0, 0, 0],
                    },
                    { s: [444.861, 265.049, 0], t: 12 },
                  ],
                  ix: 2,
                },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [4.708], t: 10 },
                    { s: [-3.292], t: 11 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.566, 7.262],
                                  [70.473, 12.612],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.238, 3.241],
                                  [74.555, 8.653],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.161, 12.94],
                                  [72.29, 8.896],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [798.925, 767.689], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 2',
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.579, 13.141],
                                  [68.406, 18.61],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.907, 8.865],
                                  [66.992, 13.745],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.767, 17.796],
                                  [71.634, 14.305],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [782.192, 744.239], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 3',
                  ix: 3,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.56, 6.886],
                                  [14.508, 9.939],
                                  [17.247, 1.927],
                                  [-17.223, -17.623],
                                  [-14.125, -4.33],
                                ],
                                o: [
                                  [3.872, -2.524],
                                  [-18.501, -12.674],
                                  [-20.572, -2.298],
                                  [10.15, 10.386],
                                  [14.023, 4.299],
                                ],
                                v: [
                                  [89.594, 35.433],
                                  [75.43, 15.153],
                                  [22.24, -6.742],
                                  [4.129, 8.64],
                                  [43.836, 33.589],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.03, 6.812],
                                  [13.523, 9.865],
                                  [16.206, 1.928],
                                  [-15.976, -17.483],
                                  [-13.236, -4.307],
                                ],
                                o: [
                                  [3.677, -2.497],
                                  [-17.244, -12.58],
                                  [-19.33, -2.3],
                                  [9.415, 10.303],
                                  [13.14, 4.276],
                                ],
                                v: [
                                  [88.768, 31.01],
                                  [75.705, 10.896],
                                  [25.937, -10.86],
                                  [8.689, 4.363],
                                  [45.728, 29.132],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-7.926, 7.062],
                                  [12.318, 10.683],
                                  [15.892, -0.423],
                                  [-12.281, -19.529],
                                  [-9.81, 1.212],
                                ],
                                o: [
                                  [3.233, -2.88],
                                  [-13.804, -11.972],
                                  [-18.956, 0.504],
                                  [10.612, 16.876],
                                  [14.513, -1.793],
                                ],
                                v: [
                                  [87.519, 31.855],
                                  [70.167, 5.343],
                                  [24, -15.11],
                                  [6.974, 7.949],
                                  [54.385, 38.089],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [787.515, 756.053], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 5',
                  ix: 4,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-3.488, 14.884],
                                  [10.664, 8.603],
                                  [16.653, 3.143],
                                  [-10.115, -21.802],
                                  [-13.283, -6.268],
                                ],
                                o: [
                                  [0.992, -4.235],
                                  [-17.786, -14.347],
                                  [-20.279, -3.828],
                                  [4.946, 10.662],
                                  [14.291, 6.744],
                                ],
                                v: [
                                  [94.335, 33.54],
                                  [74.543, 9.992],
                                  [22.468, -15.335],
                                  [-5.418, 6.182],
                                  [47.893, 41.455],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-4.923, 20.418],
                                  [9.922, 8.537],
                                  [15.631, 3.133],
                                  [-9.231, -21.617],
                                  [-12.418, -6.227],
                                ],
                                o: [
                                  [0.959, -3.976],
                                  [-16.549, -14.238],
                                  [-19.035, -3.815],
                                  [4.514, 10.572],
                                  [13.36, 6.699],
                                ],
                                v: [
                                  [96.984, 24.391],
                                  [73.555, 1.427],
                                  [25.552, -20.91],
                                  [-0.396, 1.982],
                                  [49.31, 36.997],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-1.871, 20.373],
                                  [9.919, 8.098],
                                  [19.811, 0.819],
                                  [-8.885, -20.35],
                                  [-12.846, -4.254],
                                ],
                                o: [
                                  [0.364, -3.968],
                                  [-13.812, -11.276],
                                  [-19.974, -0.826],
                                  [4.48, 10.262],
                                  [13.821, 4.576],
                                ],
                                v: [
                                  [93.093, 24.231],
                                  [69.795, -2.927],
                                  [26.381, -22.762],
                                  [-0.161, 8.759],
                                  [49.63, 41.511],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [791.28, 749.258], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 6',
                  ix: 5,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0.513, -1.181],
                                ],
                                o: [
                                  [8.311, -2.039],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.925, -121.849],
                                  [-113.488, -117.555],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0.503, -1.102],
                                ],
                                o: [
                                  [7.85, -1.773],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.462, -124.501],
                                  [-108.57, -121.607],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0.321, -1.135],
                                ],
                                o: [
                                  [7.294, -2.872],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.848, -121.747],
                                  [-111.174, -122.421],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [966.42, 834.392], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 4',
                  ix: 6,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-12.291, 10.152],
                                  [14.734, 1.047],
                                  [5.197, -1.444],
                                  [3.67, -1.583],
                                ],
                                o: [
                                  [24.372, -4.596],
                                  [8.786, -7.256],
                                  [-7.218, -0.513],
                                  [-3.495, 0.971],
                                  [-6.416, 2.768],
                                ],
                                v: [
                                  [-164.235, -119.162],
                                  [-111.965, -121.178],
                                  [-136.507, -129.396],
                                  [-142.37, -128.867],
                                  [-153.66, -125.202],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-11.467, 9.658],
                                  [13.844, 1.137],
                                  [5.479, -1.422],
                                  [3.437, -1.522],
                                ],
                                o: [
                                  [22.878, -4.543],
                                  [8.196, -6.903],
                                  [-5.508, -0.453],
                                  [-3.301, 0.857],
                                  [-6.009, 2.661],
                                ],
                                v: [
                                  [-157.028, -123.564],
                                  [-109.391, -124.305],
                                  [-129.079, -131.735],
                                  [-137.858, -131.498],
                                  [-148.638, -127.711],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-7.146, -2.194],
                                  [13.507, -0.803],
                                  [5.294, -1.542],
                                  [2.383, -0.967],
                                ],
                                o: [
                                  [12.953, -3.231],
                                  [8.895, -5.463],
                                  [-5.374, 0.32],
                                  [-3.445, 1.004],
                                  [-5.345, 2.168],
                                ],
                                v: [
                                  [-153.423, -122.216],
                                  [-113.913, -123.52],
                                  [-129.318, -128.535],
                                  [-142.569, -127.087],
                                  [-147.964, -124.984],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [966.864, 837.464], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 7',
                  ix: 7,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.196, 3.131],
                                  [-9.21, -0.778],
                                  [-4.36, -5.841],
                                  [-7.983, 6.972],
                                  [6.443, -10.053],
                                  [0, 0],
                                  [10.625, 0.172],
                                  [11.994, 0.219],
                                  [3.668, 0.205],
                                  [-0.08, 6.02],
                                ],
                                o: [
                                  [7.731, -0.871],
                                  [7.207, -5.474],
                                  [3.854, 5.164],
                                  [7.128, 18.259],
                                  [-0.791, 1.235],
                                  [0, 0],
                                  [-9.678, -0.157],
                                  [-23.454, -0.429],
                                  [-0.622, -5.251],
                                  [0.302, -22.67],
                                ],
                                v: [
                                  [-127.482, -142.801],
                                  [-101.218, -135.897],
                                  [-82.594, -134.459],
                                  [-66.449, -124.737],
                                  [-68.401, -69.227],
                                  [-72.796, -69.106],
                                  [-89.351, -72.879],
                                  [-117.346, -87.265],
                                  [-162.334, -91.265],
                                  [-171.294, -107.363],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.501, 3.046],
                                  [-8.668, -0.648],
                                  [-2.44, -5.613],
                                  [-7.443, 6.629],
                                  [5.968, -9.512],
                                  [0, 0],
                                  [9.994, 0.065],
                                  [11.281, 0.098],
                                  [3.451, 0.16],
                                  [-0.021, 5.661],
                                ],
                                o: [
                                  [7.262, -0.89],
                                  [6.728, -5.213],
                                  [2.416, 5.557],
                                  [3.388, 14.898],
                                  [-0.733, 1.169],
                                  [0, 0],
                                  [-9.103, -0.059],
                                  [-22.059, -0.191],
                                  [-0.633, -4.933],
                                  [0.079, -21.322],
                                ],
                                v: [
                                  [-124.03, -145.234],
                                  [-98.579, -138.931],
                                  [-81.315, -138.816],
                                  [-63.816, -127.43],
                                  [-67.38, -77.169],
                                  [-71.512, -77.015],
                                  [-87.114, -80.414],
                                  [-113.571, -93.688],
                                  [-155.913, -97.042],
                                  [-164.484, -112.098],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.294, -0.672],
                                  [-8.448, 0.565],
                                  [-3.26, -5.77],
                                  [-7.206, 6.507],
                                  [7.628, -16.717],
                                  [0, 0],
                                  [9.735, -0.004],
                                  [10.989, 0.019],
                                  [3.362, 0.133],
                                  [1.013, 5.421],
                                ],
                                o: [
                                  [6.881, -1.855],
                                  [7.802, -8.444],
                                  [2.903, 5.139],
                                  [4.634, 12.684],
                                  [-0.558, 1.222],
                                  [0, 0],
                                  [-8.867, 0.003],
                                  [-21.489, -0.037],
                                  [-0.65, -4.8],
                                  [-4.535, -24.267],
                                ],
                                v: [
                                  [-122.291, -141.376],
                                  [-97.938, -139.303],
                                  [-81.366, -134.663],
                                  [-67.457, -126.716],
                                  [-70.911, -74.465],
                                  [-72.641, -77.182],
                                  [-87.861, -80.388],
                                  [-113.721, -93.14],
                                  [-154.988, -96.121],
                                  [-163.928, -100.381],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [954.82, 850.825], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 8',
                  ix: 8,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.838, 0.546],
                                  [7.794, -0.322],
                                  [7.776, 0.215],
                                  [0, 0],
                                  [0, 0],
                                  [-15.947, -0.33],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-8.075, 0.334],
                                  [-16.7, -0.461],
                                  [-12.445, -1.544],
                                  [0, 0],
                                  [10.931, 0.226],
                                  [0, 0],
                                ],
                                v: [
                                  [-79.501, -91.085],
                                  [-89.801, -99.865],
                                  [-118.439, -99.117],
                                  [-143.603, -104.033],
                                  [-163.535, -117.226],
                                  [-110.662, -113.459],
                                  [-69.245, -105.032],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.138, 0.406],
                                  [7.327, -0.374],
                                  [7.315, 0.132],
                                  [0, 0],
                                  [0, 0],
                                  [-14.999, -0.165],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-7.59, 0.387],
                                  [-15.709, -0.282],
                                  [-11.717, -1.339],
                                  [0, 0],
                                  [10.282, 0.113],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.852, -99.166],
                                  [-87.618, -107.33],
                                  [-114.542, -106.367],
                                  [-138.25, -110.762],
                                  [-157.114, -122.987],
                                  [-107.358, -119.924],
                                  [-68.334, -112.375],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.852, 0.32],
                                  [7.134, -0.413],
                                  [7.126, 0.079],
                                  [0, 0],
                                  [0, 0],
                                  [-14.612, -0.06],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-7.391, 0.428],
                                  [-15.303, -0.169],
                                  [-11.422, -1.225],
                                  [0, 0],
                                  [10.016, 0.041],
                                  [0, 0],
                                ],
                                v: [
                                  [-78.823, -99.391],
                                  [-88.39, -107.277],
                                  [-114.609, -106.157],
                                  [-137.733, -110.279],
                                  [-156.19, -122.06],
                                  [-107.704, -119.412],
                                  [-69.641, -112.321],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [955.975, 876.533], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 9',
                  ix: 9,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [7.386, 0.194],
                                  [0, 0],
                                  [2.782, 6.584],
                                  [0, 0],
                                  [-7.367, -0.566],
                                  [0, 0],
                                  [-1.639, -6.957],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-7.27, -1.318],
                                  [0, 0],
                                  [-2.341, -6.752],
                                  [0, 0],
                                  [7.367, 0.566],
                                  [0, 0],
                                  [1.639, 6.958],
                                ],
                                v: [
                                  [-106.132, -93.938],
                                  [-116.634, -98.686],
                                  [-128.923, -115.763],
                                  [-131.032, -128.265],
                                  [-121.007, -138.221],
                                  [-104.321, -135.746],
                                  [-96.623, -117.463],
                                  [-93.236, -109.713],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.948, 0.115],
                                  [0, 0],
                                  [2.676, 6.166],
                                  [0, 0],
                                  [-6.933, -0.465],
                                  [0, 0],
                                  [-1.604, -6.528],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.848, -1.174],
                                  [0, 0],
                                  [-0.646, -6.737],
                                  [0, 0],
                                  [6.933, 0.465],
                                  [0, 0],
                                  [1.604, 6.528],
                                ],
                                v: [
                                  [-103.394, -98.978],
                                  [-113.313, -103.348],
                                  [-124.518, -119.659],
                                  [-126.837, -131.862],
                                  [-117.946, -138.667],
                                  [-101.804, -137.675],
                                  [-93.838, -122.755],
                                  [-91.409, -113.929],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.768, 0.065],
                                  [0, 0],
                                  [3.427, 5.579],
                                  [0, 0],
                                  [-6.75, 0.503],
                                  [0, 0],
                                  [-2.443, -6.075],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.679, -1.097],
                                  [0, 0],
                                  [-4.688, -13.697],
                                  [0, 0],
                                  [6.75, -0.503],
                                  [0, 0],
                                  [1.606, 6.348],
                                ],
                                v: [
                                  [-104.03, -97.895],
                                  [-113.721, -102.084],
                                  [-126.661, -117.122],
                                  [-132, -120.903],
                                  [-123.039, -134.458],
                                  [-103.93, -136.258],
                                  [-95.856, -119.53],
                                  [-92.457, -112.539],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [957.152, 832.241], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 18,
            },
            {
              ty: 4,
              nm: 'left shoe ',
              sr: 1,
              st: -60,
              op: 10,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [943.5, 857, 0], ix: 1 },
                s: { a: 0, k: [136, 136, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  s: true,
                  x: {
                    a: 1,
                    k: [
                      {
                        o: { x: 0.167, y: 0.167 },
                        i: { x: 0.833, y: 0.833 },
                        s: [888.297],
                        t: -11,
                      },
                      {
                        o: { x: 0.167, y: 0.167 },
                        i: { x: 0.833, y: 0.833 },
                        s: [894.289],
                        t: -10,
                      },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [888.789], t: -7 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [891.339], t: -5 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [885.839], t: -3 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [885.771], t: -2 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [886.884], t: -1 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1.191 }, s: [902.172], t: 0 },
                      { o: { x: 0.333, y: -0.21 }, i: { x: 0.667, y: 1.188 }, s: [896.453], t: 1 },
                      { o: { x: 0.333, y: -0.295 }, i: { x: 0.667, y: 1.097 }, s: [891.251], t: 2 },
                      { o: { x: 0.305, y: 0.054 }, i: { x: 0.651, y: 0.864 }, s: [887.927], t: 3 },
                      { o: { x: 0.342, y: 0.787 }, i: { x: 0.687, y: -0.363 }, s: [893.394], t: 4 },
                      { o: { x: 0.418, y: -0.166 }, i: { x: 0.779, y: 1 }, s: [894.317], t: 5 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.583, y: -2.061 }, s: [884.186], t: 6 },
                      { o: { x: 0.417, y: 0.96 }, i: { x: 0.833, y: 1 }, s: [884.779], t: 7 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [886.672], t: 8 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [885.133], t: 9 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [893.963], t: 10 },
                      { s: [886.406], t: 11 },
                    ],
                    ix: 3,
                  },
                  y: {
                    a: 1,
                    k: [
                      {
                        o: { x: 0.167, y: 0.167 },
                        i: { x: 0.833, y: 0.833 },
                        s: [866.799],
                        t: -11,
                      },
                      {
                        o: { x: 0.167, y: 0.167 },
                        i: { x: 0.833, y: 0.833 },
                        s: [860.562],
                        t: -10,
                      },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [860.843], t: -5 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [860.843], t: -3 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [862.998], t: -2 },
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [869.227], t: -1 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: -111.342 }, s: [879.787], t: 0 },
                      { o: { x: 0.333, y: 0.645 }, i: { x: 0.667, y: 0.333 }, s: [879.776], t: 1 },
                      { o: { x: 0.333, y: 0.27 }, i: { x: 0.667, y: 0.868 }, s: [877.917], t: 2 },
                      { o: { x: 0.333, y: 0.622 }, i: { x: 0.667, y: 1 }, s: [873.333], t: 3 },
                      { o: { x: 0.297, y: 0 }, i: { x: 0.659, y: 12.486 }, s: [872.358], t: 4 },
                      { o: { x: 0.425, y: 0.992 }, i: { x: 0.815, y: 1 }, s: [872.295], t: 5 },
                      { o: { x: 0.167, y: 0 }, i: { x: 0.583, y: 0.505 }, s: [873.203], t: 6 },
                      { o: { x: 0.417, y: 1.615 }, i: { x: 0.833, y: 1 }, s: [867.784], t: 7 },
                      { o: { x: 0.182, y: 0 }, i: { x: 0.564, y: 1.349 }, s: [866.122], t: 8 },
                      { o: { x: 0.323, y: -0.06 }, i: { x: 0.677, y: 1.18 }, s: [863.488], t: 9 },
                      { o: { x: 0.436, y: 0.332 }, i: { x: 0.818, y: 0.955 }, s: [852.197], t: 10 },
                      { s: [860.468], t: 11 },
                    ],
                    ix: 4,
                  },
                  z: { a: 0, k: 0 },
                },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.222, y: 0 }, i: { x: 0, y: 1 }, s: [10.525], t: -11 },
                    { o: { x: 0.659, y: 0 }, i: { x: 0.822, y: 1 }, s: [0], t: -10 },
                    { o: { x: 0.265, y: 0 }, i: { x: 0.608, y: 0.432 }, s: [0], t: -5 },
                    { o: { x: 0.318, y: 0.209 }, i: { x: 0.653, y: 1.183 }, s: [3.04], t: -3 },
                    { o: { x: 0.324, y: -0.102 }, i: { x: 0.658, y: 0.987 }, s: [6.399], t: -2 },
                    { o: { x: 0.327, y: -0.01 }, i: { x: 0.661, y: 0.889 }, s: [12.038], t: -1 },
                    { o: { x: 0.33, y: -0.204 }, i: { x: 0.664, y: 1.26 }, s: [5.376], t: 0 },
                    { o: { x: 0.333, y: -0.224 }, i: { x: 0.67, y: 1.323 }, s: [8.904], t: 1 },
                    { o: { x: 0.337, y: 2.087 }, i: { x: 0.672, y: 0.356 }, s: [17.051], t: 3 },
                    { o: { x: 0.341, y: 0.246 }, i: { x: 0.675, y: 1.346 }, s: [16.408], t: 4 },
                    { o: { x: 0.345, y: 0.111 }, i: { x: 0.679, y: 1.457 }, s: [14.66], t: 5 },
                    { o: { x: 0.351, y: 3.752 }, i: { x: 0.686, y: -0.387 }, s: [20.436], t: 6 },
                    { o: { x: 0.423, y: 0.756 }, i: { x: 0.771, y: 1.401 }, s: [19.666], t: 7 },
                    { o: { x: 0.167, y: -0.183 }, i: { x: 0.583, y: 1.909 }, s: [15.858], t: 9 },
                    { o: { x: 0.417, y: -0.109 }, i: { x: 0.833, y: 1.042 }, s: [12.817], t: 10 },
                    { s: [-12.475], t: 11 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.663, -2.93],
                                ],
                                o: [
                                  [-12.006, -3.071],
                                  [0, 0],
                                ],
                                v: [
                                  [17.125, 0.734],
                                  [-17.126, 2.337],
                                ],
                              },
                            ],
                            t: -12,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.663, -2.93],
                                ],
                                o: [
                                  [-12.006, -3.071],
                                  [0, 0],
                                ],
                                v: [
                                  [17.125, 0.734],
                                  [-17.126, 2.337],
                                ],
                              },
                            ],
                            t: -5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [4.779, -3.849],
                                ],
                                o: [
                                  [-10.362, -2.324],
                                  [0, 0],
                                ],
                                v: [
                                  [16.422, -3.576],
                                  [-16.757, 0.1],
                                ],
                              },
                            ],
                            t: -3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.624, -4.544],
                                ],
                                o: [
                                  [-9.117, -1.758],
                                  [0, 0],
                                ],
                                v: [
                                  [14.393, -7.021],
                                  [-17.974, -1.776],
                                ],
                              },
                            ],
                            t: -2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [7.699, -6.251],
                                ],
                                o: [
                                  [-6.323, -0.451],
                                  [0, 0],
                                ],
                                v: [
                                  [12.137, -16.52],
                                  [-18.137, -5.501],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [9.545, -7.772],
                                ],
                                o: [
                                  [-3.34, 0.869],
                                  [0, 0],
                                ],
                                v: [
                                  [6.577, -21.569],
                                  [-20.949, -6.924],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [8.186, -9.078],
                                ],
                                o: [
                                  [-3.153, 1.639],
                                  [0, 0],
                                ],
                                v: [
                                  [3.991, -21.232],
                                  [-19.714, -1.368],
                                ],
                              },
                            ],
                            t: 1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [6.945, -9.371],
                                ],
                                o: [
                                  [-3.539, 1.981],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.6, -18.212],
                                  [-19.083, 4.361],
                                ],
                              },
                            ],
                            t: 2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.703, -9.665],
                                ],
                                o: [
                                  [-3.925, 2.324],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.778, -14.631],
                                  [-20.602, 11.197],
                                ],
                              },
                            ],
                            t: 3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.395, -8.739],
                                ],
                                o: [
                                  [-5.142, 1.827],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.062, -12.138],
                                  [-20.848, 11.925],
                                ],
                              },
                            ],
                            t: 4,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.087, -7.814],
                                ],
                                o: [
                                  [-6.359, 1.331],
                                  [0, 0],
                                ],
                                v: [
                                  [3.577, -9.018],
                                  [-20.552, 12.158],
                                ],
                              },
                            ],
                            t: 5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [5.087, -7.814],
                                ],
                                o: [
                                  [-6.359, 1.331],
                                  [0, 0],
                                ],
                                v: [
                                  [3.577, -9.018],
                                  [-20.552, 12.158],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.809, -5.257],
                                ],
                                o: [
                                  [-10, 0.123],
                                  [0, 0],
                                ],
                                v: [
                                  [11.409, -6.635],
                                  [-20.466, 9.679],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.912, -3.131],
                                ],
                                o: [
                                  [-9.133, -1.445],
                                  [0, 0],
                                ],
                                v: [
                                  [14.683, -2.034],
                                  [-19.848, 5.027],
                                ],
                              },
                            ],
                            t: 8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [3.208, -1.655],
                                ],
                                o: [
                                  [-8.332, -1.972],
                                  [0, 0],
                                ],
                                v: [
                                  [16.745, 0.493],
                                  [-17.126, 2.337],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [-0.276, -1.258],
                                ],
                                o: [
                                  [-7.767, -3.604],
                                  [0, 0],
                                ],
                                v: [
                                  [24.72, 1.774],
                                  [-17.504, -3.621],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [13.275, -5.855],
                                ],
                                o: [
                                  [-5.77, -4.153],
                                  [0, 0],
                                ],
                                v: [
                                  [21.008, -8.431],
                                  [-22.226, -13.735],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [966.42, 834.392], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 4',
                  ix: 2,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [12.21, 10.257],
                                  [-14.731, 1.228],
                                  [-5.832, -1.505],
                                  [-3.243, -2.339],
                                ],
                                o: [
                                  [-24.349, -4.803],
                                  [-8.728, -7.331],
                                  [5.861, -0.488],
                                  [3.514, 0.907],
                                  [6.071, 4.383],
                                ],
                                v: [
                                  [25.89, 6.128],
                                  [-15.797, 1.36],
                                  [-11.159, -11.129],
                                  [5.448, -9.908],
                                  [15.115, -3.512],
                                ],
                              },
                            ],
                            t: -12,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [12.21, 10.257],
                                  [-14.731, 1.228],
                                  [-5.832, -1.505],
                                  [-3.243, -2.339],
                                ],
                                o: [
                                  [-24.349, -4.803],
                                  [-8.728, -7.331],
                                  [5.861, -0.488],
                                  [3.514, 0.907],
                                  [6.071, 4.383],
                                ],
                                v: [
                                  [25.89, 6.128],
                                  [-15.797, 1.36],
                                  [-11.159, -11.129],
                                  [5.448, -9.908],
                                  [15.115, -3.512],
                                ],
                              },
                            ],
                            t: -5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.722, -0.447],
                                  [11.018, 9.056],
                                  [-8.331, 1.94],
                                  [-5.873, -1.199],
                                  [-3.358, -2.286],
                                ],
                                o: [
                                  [-21.208, -2.978],
                                  [-8.297, -6.752],
                                  [3.436, -1.058],
                                  [3.536, 0.721],
                                  [6.29, 4.283],
                                ],
                                v: [
                                  [25.256, 2.028],
                                  [-13.816, 0.482],
                                  [-17.228, -12.03],
                                  [4.534, -13.84],
                                  [14.871, -7.65],
                                ],
                              },
                            ],
                            t: -3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [1.269, -0.785],
                                  [10.116, 8.147],
                                  [-3.485, 2.48],
                                  [-5.905, -0.966],
                                  [-3.446, -2.245],
                                ],
                                o: [
                                  [-18.829, -1.597],
                                  [-7.971, -6.313],
                                  [1.6, -1.489],
                                  [3.552, 0.581],
                                  [6.457, 4.206],
                                ],
                                v: [
                                  [23.28, -1.258],
                                  [-13.812, -0.364],
                                  [-23.319, -12.894],
                                  [2.346, -16.998],
                                  [13.191, -10.964],
                                ],
                              },
                            ],
                            t: -2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [2.577, -1.593],
                                  [8.143, 6.129],
                                  [-1.745, 2.05],
                                  [-6.069, -0.431],
                                  [-3.705, -2.182],
                                ],
                                o: [
                                  [-13.51, 1.634],
                                  [-7.324, -5.376],
                                  [-2.702, -2.529],
                                  [3.645, 0.259],
                                  [6.946, 4.091],
                                ],
                                v: [
                                  [19.155, -11.363],
                                  [-12.161, -3.172],
                                  [-26.615, -13.755],
                                  [-0.922, -25.507],
                                  [11.339, -20.314],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [3.808, -2.354],
                                  [5.928, 3.927],
                                  [4.787, 2.415],
                                  [-6.052, 0.118],
                                  [-3.854, -2.055],
                                ],
                                o: [
                                  [-7.789, 4.816],
                                  [-6.458, -4.278],
                                  [-6.922, -3.493],
                                  [3.629, -0.071],
                                  [7.228, 3.854],
                                ],
                                v: [
                                  [13.067, -16.46],
                                  [-14.052, -5.343],
                                  [-28.769, -12.376],
                                  [-5.221, -29.577],
                                  [6.394, -25.122],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [2.895, -2.82],
                                  [6.33, 2.895],
                                  [4.174, 1.844],
                                  [-5.63, 0.949],
                                  [-4.008, -1.524],
                                ],
                                o: [
                                  [-6.823, 5.891],
                                  [-6.743, -3.185],
                                  [-6.219, -3.427],
                                  [3.498, -0.69],
                                  [8.182, 3.112],
                                ],
                                v: [
                                  [11.636, -16.135],
                                  [-11.698, -0.744],
                                  [-28.423, -7.199],
                                  [-8.816, -27.849],
                                  [2.758, -25.193],
                                ],
                              },
                            ],
                            t: 1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [1.964, -2.804],
                                  [6.888, 2.578],
                                  [2.815, 1.802],
                                  [-5.226, 1.266],
                                  [-3.986, -1.266],
                                ],
                                o: [
                                  [-6.997, 6.116],
                                  [-6.907, -2.684],
                                  [-4.572, -3.721],
                                  [2.043, 0.525],
                                  [8.759, 2.743],
                                ],
                                v: [
                                  [10.68, -14.498],
                                  [-12.601, 5.753],
                                  [-28.898, -3.394],
                                  [-10.521, -26.478],
                                  [-0.881, -21.994],
                                ],
                              },
                            ],
                            t: 2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [1.033, -2.787],
                                  [7.447, 2.261],
                                  [1.456, 1.76],
                                  [-4.822, 1.583],
                                  [-3.648, -1.851],
                                ],
                                o: [
                                  [-7.172, 6.34],
                                  [-7.071, -2.182],
                                  [-2.925, -4.016],
                                  [3.201, -1.307],
                                  [7.879, 3.998],
                                ],
                                v: [
                                  [10.056, -9.759],
                                  [-13.503, 12.249],
                                  [-29.373, 0.411],
                                  [-13.275, -21.44],
                                  [-1.743, -17.988],
                                ],
                              },
                            ],
                            t: 3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.698, -2.39],
                                  [8.232, 3.212],
                                  [0.054, 2.277],
                                  [-4.869, 1.198],
                                  [-3.846, -1.206],
                                ],
                                o: [
                                  [-9.119, 5.433],
                                  [-7.309, -2.779],
                                  [-1.08, -4.155],
                                  [3.244, -1.064],
                                  [9.096, 2.796],
                                ],
                                v: [
                                  [10.119, -10.047],
                                  [-14.168, 11.884],
                                  [-28.729, 1.647],
                                  [-12.99, -19.782],
                                  [-3.913, -16.093],
                                ],
                              },
                            ],
                            t: 4,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.364, -1.992],
                                  [9.017, 4.164],
                                  [-1.348, 2.793],
                                  [-4.917, 0.813],
                                  [-3.727, -1.403],
                                ],
                                o: [
                                  [-11.066, 4.527],
                                  [-7.546, -3.376],
                                  [0.764, -4.295],
                                  [3.287, -0.821],
                                  [8.855, 3.218],
                                ],
                                v: [
                                  [13.69, -7.659],
                                  [-14.833, 11.519],
                                  [-28.085, 2.882],
                                  [-10.116, -15.456],
                                  [-0.13, -13.818],
                                ],
                              },
                            ],
                            t: 5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.364, -1.992],
                                  [9.017, 4.164],
                                  [-1.348, 2.793],
                                  [-4.917, 0.813],
                                  [-3.727, -1.403],
                                ],
                                o: [
                                  [-11.066, 4.527],
                                  [-7.546, -3.376],
                                  [0.764, -4.295],
                                  [3.287, -0.821],
                                  [8.855, 3.218],
                                ],
                                v: [
                                  [13.69, -7.659],
                                  [-14.833, 11.519],
                                  [-28.085, 2.882],
                                  [-10.116, -15.456],
                                  [-0.13, -13.818],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-0.018, -0.783],
                                  [11.984, 6.261],
                                  [-8.507, 3.571],
                                  [-5.506, 0.087],
                                  [-3.661, -1.571],
                                ],
                                o: [
                                  [-18.928, 1.826],
                                  [-8.963, -4.618],
                                  [4.108, -3.069],
                                  [3.48, -0.219],
                                  [7.806, 3.22],
                                ],
                                v: [
                                  [19.952, -3.341],
                                  [-16.609, 9.239],
                                  [-22.674, -1.854],
                                  [-2.12, -12.594],
                                  [9.838, -9.897],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0.043, -0.393],
                                  [11.759, 8.847],
                                  [-11.772, 2.085],
                                  [-5.659, -0.975],
                                  [-3.359, -2.143],
                                ],
                                o: [
                                  [-21.698, -2.33],
                                  [-8.592, -6.421],
                                  [5.151, -1.606],
                                  [3.501, 0.515],
                                  [6.738, 4.216],
                                ],
                                v: [
                                  [24.233, 1.313],
                                  [-16.73, 4.815],
                                  [-16.867, -7.456],
                                  [2.3, -11.141],
                                  [12.755, -6.081],
                                ],
                              },
                            ],
                            t: 8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [12.21, 10.257],
                                  [-14.731, 1.228],
                                  [-5.832, -1.505],
                                  [-3.243, -2.339],
                                ],
                                o: [
                                  [-24.349, -4.803],
                                  [-8.728, -7.331],
                                  [5.861, -0.488],
                                  [3.514, 0.907],
                                  [6.071, 4.383],
                                ],
                                v: [
                                  [25.616, 5.134],
                                  [-15.797, 1.36],
                                  [-11.159, -11.129],
                                  [5.448, -9.908],
                                  [14.734, -3.753],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [9.905, 12.497],
                                  [-14.678, -1.754],
                                  [-5.411, -2.645],
                                  [-3.26, -2.315],
                                ],
                                o: [
                                  [-22.89, -9.59],
                                  [-7.08, -8.933],
                                  [5.84, 0.698],
                                  [3.261, 1.594],
                                  [5.7, 4.048],
                                ],
                                v: [
                                  [32.886, 6.229],
                                  [-17.848, -6.672],
                                  [4.26, -10.234],
                                  [9.888, -8.49],
                                  [23.8, -1.889],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [10.623, 1.914],
                                  [-14.782, -0.068],
                                  [-5.944, -2.655],
                                  [-3.613, -3.237],
                                ],
                                o: [
                                  [-18.074, -9.165],
                                  [-11.218, -2.021],
                                  [5.881, 0.027],
                                  [2.247, 1.004],
                                  [5.577, 4.996],
                                ],
                                v: [
                                  [28.706, -3.788],
                                  [-16.994, -13.196],
                                  [-10.373, -23.794],
                                  [11.195, -18.741],
                                  [21.493, -10.489],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [966.864, 837.464], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 6',
                  ix: 3,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.199, -10.206],
                                  [0, 0],
                                  [-10.634, -0.086],
                                  [-12.005, -0.071],
                                  [-3.674, 0.116],
                                  [3.096, 5.163],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.762, 1.254],
                                  [0, 0],
                                  [9.686, 0.078],
                                  [23.475, 0.14],
                                  [0.752, -5.235],
                                  [-6.177, -10.299],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-41.118, -19.362],
                                  [-48.009, 18.597],
                                  [-46.915, 19.127],
                                  [-28.912, 19.28],
                                  [5.175, 19.523],
                                  [50.335, 18.864],
                                  [46.385, 2.983],
                                ],
                              },
                            ],
                            t: -12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.199, -10.206],
                                  [0, 0],
                                  [-10.634, -0.086],
                                  [-12.005, -0.071],
                                  [-3.674, 0.116],
                                  [3.096, 5.163],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.762, 1.254],
                                  [0, 0],
                                  [9.686, 0.078],
                                  [23.475, 0.14],
                                  [0.752, -5.235],
                                  [-6.177, -10.299],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-43.324, -18.994],
                                  [-48.009, 18.597],
                                  [-46.915, 19.127],
                                  [-28.912, 19.28],
                                  [5.175, 19.523],
                                  [50.335, 18.864],
                                  [46.385, 2.983],
                                ],
                              },
                            ],
                            t: -11,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.199, -10.206],
                                  [0, 0],
                                  [-10.634, -0.086],
                                  [-12.005, -0.071],
                                  [-3.674, 0.116],
                                  [3.096, 5.163],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.762, 1.254],
                                  [0, 0],
                                  [9.686, 0.078],
                                  [23.475, 0.14],
                                  [0.752, -5.235],
                                  [-6.177, -10.299],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-41.118, -19.362],
                                  [-48.009, 18.597],
                                  [-46.915, 19.127],
                                  [-28.912, 19.28],
                                  [5.175, 19.523],
                                  [50.335, 18.864],
                                  [46.385, 2.983],
                                ],
                              },
                            ],
                            t: -5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.278, 4.176],
                                  [9.223, -0.7],
                                  [7.69, 0.892],
                                  [7.667, 9.119],
                                  [-6.169, -14.462],
                                  [-1.384, -0.664],
                                  [-10.754, -0.383],
                                  [-11.631, 0.824],
                                  [-3.633, 0.621],
                                  [5.458, 6.041],
                                ],
                                o: [
                                  [-6.643, 0.102],
                                  [-7.164, -5.535],
                                  [-6.381, -0.746],
                                  [-1.242, 13.441],
                                  [0.922, 2.279],
                                  [1.127, 0.541],
                                  [11.661, 0.883],
                                  [21.475, -1.14],
                                  [1.444, -5.152],
                                  [-6.9, -9.063],
                                ],
                                v: [
                                  [17.195, -23.738],
                                  [-6.154, -16.331],
                                  [-20.278, -26.283],
                                  [-39.821, -22.705],
                                  [-46.542, 14.148],
                                  [-42.898, 16.815],
                                  [-22.789, 18.299],
                                  [12.016, 17.343],
                                  [50.572, 13.574],
                                  [43.377, -4.444],
                                ],
                              },
                            ],
                            t: -3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [9.597, 4.896],
                                  [9.223, -0.7],
                                  [7.595, 1.074],
                                  [7.555, 10.6],
                                  [-6.146, -17.685],
                                  [-2.432, -1.167],
                                  [-10.844, -0.609],
                                  [-11.348, 1.502],
                                  [-3.603, 1.004],
                                  [7.246, 6.706],
                                ],
                                o: [
                                  [-5.821, 0.889],
                                  [-7.164, -5.535],
                                  [-6.348, -0.904],
                                  [-0.457, 13.263],
                                  [1.043, 3.056],
                                  [1.981, 0.95],
                                  [13.156, 1.493],
                                  [19.96, -2.109],
                                  [1.969, -5.09],
                                  [-7.449, -8.128],
                                ],
                                v: [
                                  [15.908, -27.079],
                                  [-7.996, -18.223],
                                  [-20.776, -28.593],
                                  [-39.146, -27.421],
                                  [-45.432, 10.779],
                                  [-39.856, 15.064],
                                  [-18.152, 17.556],
                                  [17.197, 15.693],
                                  [50.751, 9.567],
                                  [40.792, -12.252],
                                ],
                              },
                            ],
                            t: -2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [8.139, 6.665],
                                  [9.363, -0.711],
                                  [7.486, 1.519],
                                  [5.213, 13.618],
                                  [-0.566, -21.674],
                                  [-4.938, -2.368],
                                  [-11.221, -1.149],
                                  [-14.328, 4.584],
                                  [-3.584, 1.919],
                                  [11.568, 8.373],
                                ],
                                o: [
                                  [-3.972, 2.757],
                                  [-7.272, -5.619],
                                  [-6.366, -1.292],
                                  [-1.875, 7.482],
                                  [1.344, 4.932],
                                  [4.022, 1.929],
                                  [9.616, 1.886],
                                  [18.6, -7.084],
                                  [0.152, -6.575],
                                  [-8.852, -6.047],
                                ],
                                v: [
                                  [10.794, -37.376],
                                  [-14.776, -24.974],
                                  [-26.166, -32.227],
                                  [-40.549, -32.567],
                                  [-48.503, -0.551],
                                  [-39.772, 9.939],
                                  [-11.832, 15.752],
                                  [22.631, 8.486],
                                  [50.18, -3.131],
                                  [32.885, -25.356],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.437, 8.236],
                                  [9.223, -0.7],
                                  [7.773, 2.791],
                                  [2.515, 13.394],
                                  [-6.56, -18.84],
                                  [-7.296, -3.499],
                                  [-11.325, 1.17],
                                  [-8.063, 3.455],
                                  [-3.459, 2.778],
                                  [11.662, 5.069],
                                ],
                                o: [
                                  [-2.005, 4.542],
                                  [-7.164, -5.535],
                                  [-6.068, -2.179],
                                  [-1.548, 11.054],
                                  [2.253, 6.47],
                                  [5.943, 2.85],
                                  [13.378, -1.382],
                                  [10, -4.285],
                                  [4.403, -4.8],
                                  [-9.992, -3.786],
                                ],
                                v: [
                                  [6.624, -42.585],
                                  [-17.807, -25.827],
                                  [-30.773, -35.01],
                                  [-47.696, -42.912],
                                  [-51.992, -5.493],
                                  [-37.453, 6.302],
                                  [-3.584, 11.622],
                                  [26.283, 3.402],
                                  [43.968, -7.899],
                                  [26.247, -32.545],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [8.298, 6.56],
                                  [9.06, -1.509],
                                  [7.866, 2.32],
                                  [6.661, 10.04],
                                  [-2.758, -13.867],
                                  [-7.227, -3.601],
                                  [-11.062, -1.712],
                                  [-11.547, 6.448],
                                  [-1.709, 4.398],
                                  [12.362, 3.895],
                                ],
                                o: [
                                  [-1.277, 4.762],
                                  [-7.576, -4.84],
                                  [-6.141, -1.811],
                                  [-0.846, 10.193],
                                  [1.341, 6.74],
                                  [5.622, 2.788],
                                  [11.005, 0.355],
                                  [9.712, -6.336],
                                  [4.225, -6.053],
                                  [-10.196, -2.932],
                                ],
                                v: [
                                  [3.252, -40.942],
                                  [-17.989, -21.743],
                                  [-31.341, -31.573],
                                  [-46.7, -37.563],
                                  [-52.35, -5.664],
                                  [-38.091, 6.744],
                                  [-5.588, 15.465],
                                  [25.658, 7.417],
                                  [44.565, -8.067],
                                  [24.661, -32.05],
                                ],
                              },
                            ],
                            t: 1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [9.686, 4.887],
                                  [8.841, -2.242],
                                  [7.94, 1.821],
                                  [6.058, 13.923],
                                  [-1.78, -8.496],
                                  [-6.709, -3.472],
                                  [-10.845, -1.664],
                                  [-10.448, 4.379],
                                  [-2.289, 3.799],
                                  [10.932, 3.614],
                                ],
                                o: [
                                  [-1.23, 4.533],
                                  [-7.897, -4.164],
                                  [-6.219, -1.423],
                                  [-0.068, 11.148],
                                  [1.298, 6.414],
                                  [4.969, 2.555],
                                  [11.66, 1.485],
                                  [11.16, -4.913],
                                  [4.525, -7.922],
                                  [-10.019, -3.061],
                                ],
                                v: [
                                  [1.852, -36.994],
                                  [-18.933, -17.918],
                                  [-33.577, -26.837],
                                  [-46.42, -33.218],
                                  [-52.266, -2.633],
                                  [-39.092, 9.608],
                                  [-8.237, 19.169],
                                  [20.13, 16.523],
                                  [41.456, -1.861],
                                  [24.915, -29.232],
                                ],
                              },
                            ],
                            t: 2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.073, 3.215],
                                  [8.622, -2.974],
                                  [8.015, 1.321],
                                  [9.276, 13.463],
                                  [-1.573, -4.834],
                                  [-6.192, -3.343],
                                  [-10.628, -1.617],
                                  [-9.591, 3.492],
                                  [-2.869, 3.2],
                                  [9.503, 3.332],
                                ],
                                o: [
                                  [-1.182, 4.304],
                                  [-8.218, -3.488],
                                  [-6.297, -1.035],
                                  [-3.316, 9.505],
                                  [1.487, 6.046],
                                  [4.316, 2.322],
                                  [12.315, 2.616],
                                  [14.088, -6.385],
                                  [4.824, -9.792],
                                  [-9.841, -3.189],
                                ],
                                v: [
                                  [0.056, -34.312],
                                  [-19.877, -14.092],
                                  [-31.328, -22.886],
                                  [-45.973, -27.891],
                                  [-52.182, 0.397],
                                  [-40.094, 12.473],
                                  [-10.885, 22.872],
                                  [18.026, 25.46],
                                  [38.514, 4.797],
                                  [25.499, -23.312],
                                ],
                              },
                            ],
                            t: 3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.44, 2.915],
                                  [8.694, -2.747],
                                  [8, 1.148],
                                  [9.666, 12.22],
                                  [-3.697, -9.426],
                                  [-5.28, -2.905],
                                  [-10.549, -1.421],
                                  [-9.74, 2.821],
                                  [-3.332, 3.392],
                                  [8.418, 4.219],
                                ],
                                o: [
                                  [-2.138, 3.665],
                                  [-8.121, -3.7],
                                  [-6.326, -0.903],
                                  [-0.728, 10.099],
                                  [1.382, 5.371],
                                  [3.574, 1.966],
                                  [12.257, 3.419],
                                  [16.13, -5.284],
                                  [5.089, -9.007],
                                  [-9.2, -4.618],
                                ],
                                v: [
                                  [-0.588, -32.227],
                                  [-18.849, -12.609],
                                  [-32.427, -23.172],
                                  [-49.413, -25.505],
                                  [-51.56, 3.446],
                                  [-41.152, 14.113],
                                  [-14.268, 24.984],
                                  [15.095, 26.642],
                                  [39.631, 6.379],
                                  [28.359, -21.229],
                                ],
                              },
                            ],
                            t: 4,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.806, 2.615],
                                  [8.766, -2.519],
                                  [7.985, 0.975],
                                  [9.216, 12.405],
                                  [-5.82, -14.018],
                                  [-4.368, -2.468],
                                  [-10.471, -1.224],
                                  [-9.889, 2.149],
                                  [-3.795, 3.583],
                                  [7.334, 5.107],
                                ],
                                o: [
                                  [-3.093, 3.026],
                                  [-8.024, -3.913],
                                  [-6.356, -0.771],
                                  [-2.205, 13.058],
                                  [1.276, 4.695],
                                  [2.831, 1.61],
                                  [12.198, 4.222],
                                  [18.172, -4.182],
                                  [5.355, -8.223],
                                  [-8.558, -6.047],
                                ],
                                v: [
                                  [2.275, -27.466],
                                  [-17.821, -11.125],
                                  [-30.923, -22.624],
                                  [-48.313, -25.091],
                                  [-50.937, 6.494],
                                  [-42.21, 15.753],
                                  [-17.65, 27.097],
                                  [12.165, 27.824],
                                  [40.747, 7.961],
                                  [34.726, -16.47],
                                ],
                              },
                            ],
                            t: 5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.806, 2.615],
                                  [8.766, -2.519],
                                  [7.985, 0.975],
                                  [9.216, 12.405],
                                  [-5.82, -14.018],
                                  [-4.368, -2.468],
                                  [-10.471, -1.224],
                                  [-9.889, 2.149],
                                  [-3.795, 3.583],
                                  [7.334, 5.107],
                                ],
                                o: [
                                  [-3.093, 3.026],
                                  [-8.024, -3.913],
                                  [-6.356, -0.771],
                                  [-2.205, 13.058],
                                  [1.276, 4.695],
                                  [2.831, 1.61],
                                  [12.198, 4.222],
                                  [18.172, -4.182],
                                  [5.355, -8.223],
                                  [-8.558, -6.047],
                                ],
                                v: [
                                  [2.275, -27.466],
                                  [-17.821, -11.125],
                                  [-30.923, -22.624],
                                  [-46.624, -25.23],
                                  [-50.937, 6.494],
                                  [-42.21, 15.753],
                                  [-17.65, 27.097],
                                  [12.165, 27.824],
                                  [40.747, 7.961],
                                  [34.726, -16.47],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.978, 1.477],
                                  [8.827, -2.677],
                                  [7.893, 0.726],
                                  [7.952, 6.9],
                                  [-4.872, -8.703],
                                  [-1.728, -1.015],
                                  [-10.513, -0.557],
                                  [-11.022, 0.703],
                                  [-2.945, 1.306],
                                  [2.425, 3.298],
                                ],
                                o: [
                                  [-5.892, 1.484],
                                  [-8.197, -3.748],
                                  [-6.405, -0.588],
                                  [-2.558, 12.839],
                                  [0.966, 2.636],
                                  [1.044, 0.627],
                                  [10.345, 0.998],
                                  [21.844, -1.471],
                                  [0.692, -6.87],
                                  [-6.86, -9.045],
                                ],
                                v: [
                                  [10.174, -23.227],
                                  [-13.276, -8.798],
                                  [-28.057, -23.035],
                                  [-45.236, -19.925],
                                  [-49.335, 15.174],
                                  [-45.265, 19.365],
                                  [-25.4, 23.739],
                                  [2.758, 24.96],
                                  [44.627, 4.523],
                                  [40.793, -8.351],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.443, 2.956],
                                  [9.163, -0.955],
                                  [7.854, 0.689],
                                  [8.331, 8.28],
                                  [-5.108, -15.713],
                                  [-0.864, -0.508],
                                  [-10.569, -0.05],
                                  [-11.507, 0.622],
                                  [-3.379, 0.6],
                                  [3.942, 5.346],
                                ],
                                o: [
                                  [-6.881, 0.014],
                                  [-7.288, -5.31],
                                  [-6.415, -0.562],
                                  [-0.707, 9.749],
                                  [0.895, 1.925],
                                  [0.522, 0.313],
                                  [10.011, 0.291],
                                  [22.648, -1.265],
                                  [1.048, -5.99],
                                  [-6.777, -9.508],
                                ],
                                v: [
                                  [14.06, -21.259],
                                  [-9.907, -13.116],
                                  [-24.041, -24.576],
                                  [-42.788, -23.49],
                                  [-48.528, 18.092],
                                  [-45.933, 20.424],
                                  [-26.826, 22.967],
                                  [3.31, 22.671],
                                  [50.232, 14.284],
                                  [44.002, -3.57],
                                ],
                              },
                            ],
                            t: 8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [11.177, 3.226],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.712, -9.877],
                                  [0, 0],
                                  [-10.625, 0.457],
                                  [-11.992, 0.541],
                                  [-3.663, 0.304],
                                  [3.356, 4.998],
                                ],
                                o: [
                                  [-7.729, -0.937],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.825, 1.213],
                                  [0, 0],
                                  [9.678, -0.416],
                                  [23.451, -1.059],
                                  [0.484, -5.266],
                                  [-6.694, -9.97],
                                ],
                                v: [
                                  [16.92, -19.565],
                                  [-5.698, -14.071],
                                  [-20.025, -26.118],
                                  [-41.118, -19.362],
                                  [-47.721, 21.009],
                                  [-46.602, 21.483],
                                  [-28.253, 22.194],
                                  [5.609, 21.464],
                                  [52.068, 18.721],
                                  [45.753, 0.599],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.302, 5.403],
                                  [9.176, 1.165],
                                  [5.084, -2.509],
                                  [6.353, 8.487],
                                  [-4.202, -11.178],
                                  [0, 0],
                                  [-10.435, -2.053],
                                  [-11.784, -2.293],
                                  [-3.632, -0.566],
                                  [-1.181, 5.903],
                                ],
                                o: [
                                  [-7.384, -2.469],
                                  [-5.908, -6.86],
                                  [-5.782, 2.853],
                                  [-6.698, 14.804],
                                  [0.516, 1.373],
                                  [0, 0],
                                  [9.504, 1.87],
                                  [23.043, 4.484],
                                  [1.708, -5.005],
                                  [4.448, -22.232],
                                ],
                                v: [
                                  [22.898, -22.349],
                                  [-4.25, -21.089],
                                  [-22.373, -23.905],
                                  [-44.82, -17.886],
                                  [-53.09, 35.477],
                                  [-48.815, 36.514],
                                  [-31.824, 36.285],
                                  [-1.416, 28.071],
                                  [43.449, 33.565],
                                  [55.586, 19.697],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [10.852, 4.192],
                                  [9.223, -0.7],
                                  [7.815, 0.651],
                                  [7.815, 7.164],
                                  [-6.996, -9.678],
                                  [-1.219, -1.481],
                                  [-8.167, 4.103],
                                  [-16.427, -0.169],
                                  [-2.938, -6.081],
                                  [5.107, 4.768],
                                ],
                                o: [
                                  [-7.617, -1.61],
                                  [-7.164, -5.535],
                                  [-6.425, -0.536],
                                  [-2.279, 13.676],
                                  [0.859, 1.189],
                                  [0.988, -5.528],
                                  [10.651, -5.351],
                                  [11.875, 0.122],
                                  [4.366, -5.617],
                                  [-8.778, -8.195],
                                ],
                                v: [
                                  [17.672, -29.52],
                                  [-6.073, -27.87],
                                  [-20.83, -34.156],
                                  [-41.923, -27.4],
                                  [-52.34, 24.148],
                                  [-50.361, 31.032],
                                  [-20.204, 12.357],
                                  [19.686, -2.886],
                                  [56.714, 13.987],
                                  [51.631, -7.994],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [954.82, 850.825], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 2',
                  ix: 4,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.857, 0.259],
                                  [-7.807, 0.034],
                                  [-6.089, -0.14],
                                  [0, 0],
                                  [0, 0],
                                  [15.962, 0.057],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.007, -0.031],
                                  [12.873, 0.296],
                                  [11.729, -0.374],
                                  [0, 0],
                                  [-10.942, -0.039],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.314, 7.096],
                                  [-25.247, 6.98],
                                  [-3.899, 7.096],
                                  [39.315, 7.096],
                                  [49.233, -7.096],
                                  [-3.811, -6.861],
                                  [-49.232, -7.096],
                                ],
                              },
                            ],
                            t: -12,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.857, 0.259],
                                  [-7.807, 0.034],
                                  [-6.09, -0.02],
                                  [0, 0],
                                  [0, 0],
                                  [15.962, 0.057],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.007, -0.031],
                                  [16.061, 0.052],
                                  [11.729, -0.374],
                                  [0, 0],
                                  [-10.942, -0.039],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.314, 7.096],
                                  [-25.247, 6.98],
                                  [-4.082, 6.728],
                                  [39.315, 7.096],
                                  [49.233, -7.096],
                                  [-3.811, -6.861],
                                  [-49.232, -7.096],
                                ],
                              },
                            ],
                            t: -5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.607, -0.167],
                                  [-8.03, -0.141],
                                  [-7.111, 0.319],
                                  [-0.245, 0.149],
                                  [0.144, 0.165],
                                  [16.427, 0.321],
                                  [0.145, 0.44],
                                ],
                                o: [
                                  [0.944, 0.456],
                                  [6.471, -0.044],
                                  [14.149, -0.087],
                                  [11.122, -0.47],
                                  [-0.396, 0.151],
                                  [-11.245, -0.217],
                                  [-0.034, -0.103],
                                ],
                                v: [
                                  [-39.713, 6.484],
                                  [-23.786, 7.14],
                                  [-0.982, 6.778],
                                  [40.08, 6.005],
                                  [49.243, -9.22],
                                  [-2.926, -7.934],
                                  [-48.482, -8.778],
                                ],
                              },
                            ],
                            t: -4,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.169, -0.915],
                                  [-8.422, -0.45],
                                  [-8.902, 0.913],
                                  [-0.674, 0.41],
                                  [0.397, 0.456],
                                  [17.241, 0.783],
                                  [0.399, 1.214],
                                ],
                                o: [
                                  [2.6, 1.255],
                                  [5.531, -0.068],
                                  [10.793, -0.333],
                                  [10.056, -0.639],
                                  [-1.092, 0.416],
                                  [-11.777, -0.53],
                                  [-0.094, -0.285],
                                ],
                                v: [
                                  [-40.411, 5.41],
                                  [-21.222, 7.422],
                                  [5.448, 5.829],
                                  [41.423, 4.09],
                                  [49.261, -12.949],
                                  [-1.371, -9.819],
                                  [-47.165, -11.731],
                                ],
                              },
                            ],
                            t: -3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.647, -1.803],
                                  [-8.888, -0.816],
                                  [-11.03, 1.619],
                                  [-1.184, 0.72],
                                  [0.697, 0.801],
                                  [18.21, 1.333],
                                  [0.702, 2.133],
                                ],
                                o: [
                                  [4.569, 2.206],
                                  [8.094, 0.432],
                                  [14.221, -2.304],
                                  [8.789, -0.839],
                                  [-1.919, 0.732],
                                  [-12.41, -0.901],
                                  [-0.165, -0.5],
                                ],
                                v: [
                                  [-41.242, 4.132],
                                  [-18.173, 7.756],
                                  [14.55, 4.078],
                                  [43.869, -0.494],
                                  [49.282, -17.381],
                                  [0.477, -12.058],
                                  [-45.599, -15.24],
                                ],
                              },
                            ],
                            t: -2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-9.58, -3.924],
                                  [-10.121, -1.692],
                                  [-9.324, 3.989],
                                  [-5.006, 2.096],
                                  [1.415, 1.626],
                                  [20.768, 2.639],
                                  [1.425, 4.33],
                                ],
                                o: [
                                  [9.242, 6.659],
                                  [10.508, -0.755],
                                  [12.672, -4.6],
                                  [5.938, -1.323],
                                  [-3.897, 1.485],
                                  [-14.087, -1.79],
                                  [-0.334, -1.015],
                                ],
                                v: [
                                  [-40.35, -1.852],
                                  [-8.837, 6.893],
                                  [25.223, -2.963],
                                  [49.886, -12.497],
                                  [49.004, -30.371],
                                  [3.633, -20.148],
                                  [-49.062, -24.579],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-8.227, -5.927],
                                  [-11.331, -0.285],
                                  [-10.733, 5.964],
                                  [-3.553, 2.159],
                                  [2.091, 2.402],
                                  [22.705, 3.884],
                                  [2.106, 6.398],
                                ],
                                o: [
                                  [13.657, 9.839],
                                  [16.339, 0.411],
                                  [17.027, -9.462],
                                  [2.909, -1.768],
                                  [-5.758, 2.195],
                                  [-15.346, -2.625],
                                  [-0.494, -1.5],
                                ],
                                v: [
                                  [-46.668, -8.94],
                                  [-10.851, 4.615],
                                  [24.194, -5.133],
                                  [46.597, -17.591],
                                  [41.984, -37.307],
                                  [3.609, -26.977],
                                  [-50.047, -32.166],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-8.074, -6.004],
                                  [-11.065, -2.623],
                                  [-10.416, 5.779],
                                  [-3.048, 2.72],
                                  [2.194, 2.296],
                                  [22.728, 2.417],
                                  [2.499, 5.942],
                                ],
                                o: [
                                  [13.461, 9.475],
                                  [12.948, 3.078],
                                  [16.108, -9.006],
                                  [4.12, -5.213],
                                  [-5.082, 2.872],
                                  [-15.361, -1.634],
                                  [-0.726, -1.429],
                                ],
                                v: [
                                  [-46.386, -7.216],
                                  [-10.369, 5.257],
                                  [24.392, -0.328],
                                  [45.799, -17.234],
                                  [42.739, -37.189],
                                  [5.331, -21.3],
                                  [-50.796, -31.946],
                                ],
                              },
                            ],
                            t: 1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-8.26, -5.365],
                                  [-10.683, -2.487],
                                  [-9.281, 4.691],
                                  [-3.454, 5.046],
                                  [2.591, 2.84],
                                  [21.717, -1.068],
                                  [2.926, 4.342],
                                ],
                                o: [
                                  [11.411, 7.604],
                                  [11.192, 2.699],
                                  [13.976, -6.986],
                                  [5.99, -11.327],
                                  [-3.359, 3.456],
                                  [-14.697, 0.721],
                                  [-1.071, -1.117],
                                ],
                                v: [
                                  [-43.098, -1.625],
                                  [-14.575, 11.42],
                                  [21.924, 12.977],
                                  [41.824, -5.364],
                                  [37.821, -31.049],
                                  [4.842, -10.635],
                                  [-51.711, -25.074],
                                ],
                              },
                            ],
                            t: 3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-9.167, -3.819],
                                  [-9.872, -1.849],
                                  [-8.299, 2.659],
                                  [-6.503, 7.345],
                                  [3.324, 4.021],
                                  [19.997, -0.051],
                                  [2.4, 2.743],
                                ],
                                o: [
                                  [7.996, 5.144],
                                  [9.571, 1.923],
                                  [13.193, -3.741],
                                  [6.449, -6.793],
                                  [-1.92, 2.907],
                                  [-13.573, 0.034],
                                  [-0.948, -0.742],
                                ],
                                v: [
                                  [-42.273, 3.795],
                                  [-14.485, 15.803],
                                  [17.733, 15.217],
                                  [42.429, -2.624],
                                  [42.342, -26.78],
                                  [2.698, -9.951],
                                  [-51.258, -18.732],
                                ],
                              },
                            ],
                            t: 5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-9.167, -3.819],
                                  [-9.872, -1.849],
                                  [-8.362, 2.456],
                                  [-6.68, 7.184],
                                  [3.324, 4.021],
                                  [19.997, -0.051],
                                  [2.4, 2.743],
                                ],
                                o: [
                                  [7.996, 5.144],
                                  [9.571, 1.923],
                                  [13.28, -3.418],
                                  [6.613, -6.634],
                                  [-1.92, 2.907],
                                  [-13.573, 0.034],
                                  [-0.948, -0.742],
                                ],
                                v: [
                                  [-42.273, 3.795],
                                  [-14.485, 15.803],
                                  [17.18, 14.61],
                                  [42.304, -2.622],
                                  [42.342, -26.78],
                                  [2.698, -9.951],
                                  [-51.258, -18.732],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-10.739, -1.394],
                                  [-8.636, -0.748],
                                  [-6.814, 1.131],
                                  [-2.392, 4.369],
                                  [1.139, 0.049],
                                  [17.55, 0.257],
                                  [1.068, 0.972],
                                ],
                                o: [
                                  [3.145, 1.957],
                                  [7.884, 0.752],
                                  [11.862, -0.753],
                                  [8.291, -6.053],
                                  [-0.417, 1.385],
                                  [-11.976, -0.174],
                                  [-0.443, -0.277],
                                ],
                                v: [
                                  [-40.548, 9.307],
                                  [-19.699, 13.721],
                                  [6.101, 13.455],
                                  [43.277, -3.528],
                                  [43.52, -22.675],
                                  [-2.794, -8.726],
                                  [-50.305, -10.224],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.828, 0.863],
                                  [-7.806, -0.113],
                                  [-7.773, 0.424],
                                  [0, 0],
                                  [0, 0],
                                  [15.944, -0.758],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [8.087, 0.117],
                                  [16.694, -0.91],
                                  [12.409, -1.877],
                                  [0, 0],
                                  [-10.929, 0.519],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.314, 8.988],
                                  [-23.91, 9.63],
                                  [4.759, 9.609],
                                  [41.809, 7.937],
                                  [50.952, -7.242],
                                  [-3.401, -4.519],
                                  [-48.944, -4.68],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-11.7, -1.942],
                                  [-7.561, -1.945],
                                  [-7.655, -1.416],
                                  [0, 0],
                                  [0, 0],
                                  [15.676, 3.012],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.833, 2.015],
                                  [16.44, 3.041],
                                  [12.503, 1.093],
                                  [0, 0],
                                  [-10.745, -2.065],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.319, 15.613],
                                  [-33.402, 9.18],
                                  [-5.532, 15.9],
                                  [20.125, 16.353],
                                  [42.392, 7.62],
                                  [-10.141, 0.248],
                                  [-52.438, -0.17],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-2.065, -4.797],
                                  [-6.253, -0.986],
                                  [-10.682, 4.179],
                                  [7.938, 11.962],
                                  [0, 0],
                                  [14.386, 2.58],
                                  [1.421, -2.463],
                                ],
                                o: [
                                  [2.833, 6.583],
                                  [8.247, 1.3],
                                  [9.242, -3.616],
                                  [-3.954, -7.779],
                                  [0, 0],
                                  [-8.861, -1.589],
                                  [-2.899, 5.022],
                                ],
                                v: [
                                  [-39.74, 12.308],
                                  [-10.295, 23.902],
                                  [16.321, 16.949],
                                  [39.328, -7.697],
                                  [27.987, -20.815],
                                  [18.399, -22.825],
                                  [-40.891, -0.99],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [955.975, 876.533], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 8',
                  ix: 5,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.348, 6.543],
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.349, -6.542],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.348, -6.542],
                                  [0, 0],
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.349, 6.543],
                                ],
                                v: [
                                  [-9.73, 17.629],
                                  [-2.836, 17.629],
                                  [13.263, 5.782],
                                  [17.325, -5.531],
                                  [9.732, -17.377],
                                  [-0.226, -17.629],
                                  [-13.263, -5.531],
                                  [-17.323, 5.782],
                                ],
                              },
                            ],
                            t: -12,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.348, 6.543],
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.349, -6.542],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.542, 0],
                                  [0, 0],
                                  [2.348, -6.542],
                                  [0, 0],
                                  [-6.542, 0],
                                  [0, 0],
                                  [-2.349, 6.543],
                                ],
                                v: [
                                  [-9.73, 17.629],
                                  [-2.836, 17.629],
                                  [13.263, 5.782],
                                  [15.575, -7.531],
                                  [8.982, -20.377],
                                  [-6.476, -19.879],
                                  [-14.013, -5.281],
                                  [-17.323, 5.782],
                                ],
                              },
                            ],
                            t: -5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.439, 0.235],
                                  [0, 0],
                                  [-2.076, 6.524],
                                  [0, 0],
                                  [6.382, 0.525],
                                  [1.102, -0.821],
                                  [1.53, -6.756],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.439, -0.235],
                                  [0, 0],
                                  [1.704, -6.577],
                                  [0, 0],
                                  [-6.384, 0.58],
                                  [0, 0],
                                  [-2.077, 6.524],
                                ],
                                v: [
                                  [-8.962, 12.959],
                                  [-2.176, 12.711],
                                  [13.242, 0.473],
                                  [16.214, -11.207],
                                  [9.506, -23.928],
                                  [-5.443, -22.449],
                                  [-13.642, -7.962],
                                  [-16.851, 2.522],
                                ],
                              },
                            ],
                            t: -3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.36, 0.413],
                                  [0, 0],
                                  [-1.87, 6.51],
                                  [0, 0],
                                  [6.26, 0.922],
                                  [1.936, -1.443],
                                  [0.909, -6.919],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.36, -0.413],
                                  [0, 0],
                                  [1.216, -6.604],
                                  [0, 0],
                                  [-6.264, 1.02],
                                  [0, 0],
                                  [-1.871, 6.51],
                                ],
                                v: [
                                  [-9.876, 9.242],
                                  [-3.173, 8.806],
                                  [11.731, -3.728],
                                  [15.203, -14.173],
                                  [8.408, -26.799],
                                  [-6.158, -24.576],
                                  [-14.857, -10.173],
                                  [-16.801, -2.13],
                                ],
                              },
                            ],
                            t: -2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.135, 2.055],
                                  [0, 0],
                                  [-0.147, 6.874],
                                  [0, 0],
                                  [6.188, 0.662],
                                  [3.637, -3.287],
                                  [-1.874, -7.518],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.135, -2.055],
                                  [0, 0],
                                  [-1.194, -6.939],
                                  [0, 0],
                                  [-5.824, 3.263],
                                  [0, 0],
                                  [-0.148, 6.874],
                                ],
                                v: [
                                  [-9.819, 6.576],
                                  [-3.353, 4.41],
                                  [8.023, -11.757],
                                  [8.914, -22.049],
                                  [0.464, -34.241],
                                  [-18.44, -26.698],
                                  [-20.026, -8.197],
                                  [-18.29, -3.054],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.795, 3.037],
                                  [0, 0],
                                  [0.957, 6.885],
                                  [0, 0],
                                  [5.873, 0.978],
                                  [5.374, -4.857],
                                  [-3.943, -7.837],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.795, -3.036],
                                  [0, 0],
                                  [-2.938, -6.983],
                                  [0, 0],
                                  [-5.335, 4.822],
                                  [0, 0],
                                  [0.956, 6.886],
                                ],
                                v: [
                                  [-12.148, 3.324],
                                  [-6.04, 0.123],
                                  [2.719, -17.843],
                                  [3.592, -26.54],
                                  [-6.716, -37.467],
                                  [-24.283, -27.849],
                                  [-20.617, -6.962],
                                  [-24.372, -3.646],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.314, 3.737],
                                  [0, 0],
                                  [1.986, 6.611],
                                  [0, 0],
                                  [5.682, -0.125],
                                  [4.536, -5.583],
                                  [-4.594, -7.402],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.225, -3.86],
                                  [0, 0],
                                  [-3.944, -6.406],
                                  [0, 0],
                                  [-4.503, 5.543],
                                  [0, 0],
                                  [1.343, 6.771],
                                ],
                                v: [
                                  [-9.831, 7.418],
                                  [-4.063, 3.822],
                                  [0.824, -16.412],
                                  [0.108, -24.498],
                                  [-6.613, -36.837],
                                  [-21.241, -23.926],
                                  [-20.769, -2.838],
                                  [-25.818, 0.843],
                                ],
                              },
                            ],
                            t: 1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.084, 3.877],
                                  [0, 0],
                                  [2.357, 6.363],
                                  [0, 0],
                                  [5.433, -0.871],
                                  [3.685, -5.587],
                                  [-4.764, -6.869],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [4.887, -4.11],
                                  [0, 0],
                                  [-4.151, -5.992],
                                  [0, 0],
                                  [-4.063, 5.601],
                                  [0, 0],
                                  [1.745, 6.544],
                                ],
                                v: [
                                  [-10.916, 13.397],
                                  [-4.928, 10.121],
                                  [0.067, -14.978],
                                  [-0.644, -20.307],
                                  [-7.322, -34.371],
                                  [-20.622, -19.027],
                                  [-21.986, 0.379],
                                  [-25.115, 4.22],
                                ],
                              },
                            ],
                            t: 2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-4.855, 4.017],
                                  [0, 0],
                                  [2.728, 6.114],
                                  [0, 0],
                                  [5.184, -1.618],
                                  [2.833, -5.592],
                                  [-4.933, -6.337],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [4.549, -4.36],
                                  [0, 0],
                                  [-4.358, -5.578],
                                  [0, 0],
                                  [-3.623, 5.66],
                                  [0, 0],
                                  [2.146, 6.317],
                                ],
                                v: [
                                  [-12, 19.377],
                                  [-5.792, 16.419],
                                  [-0.359, -10.442],
                                  [-2.596, -17.556],
                                  [-7.7, -28.804],
                                  [-20.002, -14.128],
                                  [-23.204, 3.596],
                                  [-24.412, 7.596],
                                ],
                              },
                            ],
                            t: 3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.069, 3.526],
                                  [0, 0],
                                  [2.082, 6.213],
                                  [0, 0],
                                  [5.3, -1.557],
                                  [2.445, -4.786],
                                  [-3.963, -6.371],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [4.842, -3.82],
                                  [0, 0],
                                  [-3.481, -5.758],
                                  [0, 0],
                                  [-4.052, 4.937],
                                  [0, 0],
                                  [1.45, 6.373],
                                ],
                                v: [
                                  [-10.997, 18.456],
                                  [-5.435, 17.216],
                                  [-1.16, -8.227],
                                  [-2.779, -15.785],
                                  [-8.589, -27.807],
                                  [-20.13, -11.902],
                                  [-22.862, 3.819],
                                  [-23.824, 7.905],
                                ],
                              },
                            ],
                            t: 4,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.283, 3.035],
                                  [0, 0],
                                  [1.437, 6.312],
                                  [0, 0],
                                  [5.416, -1.496],
                                  [2.056, -3.98],
                                  [-2.994, -6.405],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.135, -3.279],
                                  [0, 0],
                                  [-2.605, -5.938],
                                  [0, 0],
                                  [-4.481, 4.213],
                                  [0, 0],
                                  [0.754, 6.43],
                                ],
                                v: [
                                  [-9.993, 17.535],
                                  [-5.078, 18.012],
                                  [1.546, -3.335],
                                  [0.546, -11.338],
                                  [-5.971, -24.134],
                                  [-20.258, -9.676],
                                  [-22.521, 4.042],
                                  [-23.235, 8.214],
                                ],
                              },
                            ],
                            t: 5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-5.283, 3.035],
                                  [0, 0],
                                  [1.437, 6.312],
                                  [0, 0],
                                  [5.416, -1.496],
                                  [2.056, -3.98],
                                  [-2.994, -6.405],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.135, -3.279],
                                  [0, 0],
                                  [-2.605, -5.938],
                                  [0, 0],
                                  [-4.481, 4.213],
                                  [0, 0],
                                  [0.754, 6.43],
                                ],
                                v: [
                                  [-9.993, 17.535],
                                  [-5.078, 18.012],
                                  [1.546, -3.335],
                                  [0.546, -11.338],
                                  [-5.971, -24.134],
                                  [-20.258, -9.676],
                                  [-22.521, 4.042],
                                  [-23.235, 8.214],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.128, 1.258],
                                  [0, 0],
                                  [0.163, 6.581],
                                  [0, 0],
                                  [5.945, -1.589],
                                  [0.771, -1.607],
                                  [-0.94, -6.514],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [5.868, -2.236],
                                  [0, 0],
                                  [-0.628, -6.415],
                                  [0, 0],
                                  [-5.591, 2.596],
                                  [0, 0],
                                  [-0.942, 6.581],
                                ],
                                v: [
                                  [-14.429, 17.677],
                                  [-5.763, 19.738],
                                  [7.596, -0.063],
                                  [7.875, -10.152],
                                  [-1.943, -19.808],
                                  [-16.385, -11.79],
                                  [-22.051, 2.441],
                                  [-24.033, 7.389],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.314, 0.997],
                                  [0, 0],
                                  [-1.219, 6.672],
                                  [0, 0],
                                  [6.292, -0.713],
                                  [0.487, -0.753],
                                  [1.055, -6.658],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.314, -0.997],
                                  [0, 0],
                                  [0.979, -6.62],
                                  [0, 0],
                                  [-6.186, 1.24],
                                  [0, 0],
                                  [-1.442, 6.628],
                                ],
                                v: [
                                  [-12.871, 16.784],
                                  [-6.461, 16.538],
                                  [9.95, 1.261],
                                  [12.248, -9.594],
                                  [3.716, -20.611],
                                  [-8.906, -17.205],
                                  [-18.465, -4.683],
                                  [-21.392, 5.759],
                                ],
                              },
                            ],
                            t: 8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.501, 0.735],
                                  [0, 0],
                                  [-1.941, 6.675],
                                  [0, 0],
                                  [6.53, -0.403],
                                  [0, 0],
                                  [1.942, -6.674],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.501, -0.735],
                                  [0, 0],
                                  [1.941, -6.674],
                                  [0, 0],
                                  [-6.53, 0.403],
                                  [0, 0],
                                  [-1.942, 6.675],
                                ],
                                v: [
                                  [-11.314, 15.892],
                                  [-4.463, 15.117],
                                  [11.778, 2.248],
                                  [15.136, -9.294],
                                  [6.828, -20.65],
                                  [-3.125, -20.289],
                                  [-15.393, -7.412],
                                  [-18.75, 4.13],
                                ],
                              },
                            ],
                            t: 9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [-7.269, -1.355],
                                  [0, 0],
                                  [-4.1, 5.856],
                                  [0, 0],
                                  [7.328, 0.987],
                                  [0, 0],
                                  [3.06, -6.461],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [7.391, 0.231],
                                  [0, 0],
                                  [3.704, -6.113],
                                  [0, 0],
                                  [-7.328, -0.987],
                                  [0, 0],
                                  [-3.06, 6.462],
                                ],
                                v: [
                                  [-11.72, 19.124],
                                  [-0.449, 16.677],
                                  [20.73, 1.374],
                                  [22.341, -7.589],
                                  [14.878, -19.301],
                                  [-1.97, -20.37],
                                  [-13.446, -6.516],
                                  [-21.04, 1.002],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [-6.535, -0.297],
                                  [0, 0],
                                  [-3.679, 5.898],
                                  [0, 0],
                                  [6.142, 2.534],
                                  [0, 0],
                                  [3.68, -5.897],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [6.535, 0.297],
                                  [0, 0],
                                  [3.678, -5.897],
                                  [0, 0],
                                  [-6.491, -0.818],
                                  [0, 0],
                                  [-3.149, 6.198],
                                ],
                                v: [
                                  [-12.406, 6.427],
                                  [-5.518, 6.74],
                                  [13.918, -5.166],
                                  [18.896, -12.816],
                                  [19.027, -24.745],
                                  [2.185, -28.81],
                                  [-12.493, -22.434],
                                  [-20.393, -6.773],
                                ],
                              },
                            ],
                            t: 11,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [957.152, 832.241], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 19,
              parent: 21,
            },
            {
              ty: 4,
              nm: 'left bottom leg ',
              sr: 1,
              st: -36,
              op: 12,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [880, 769, 0], ix: 1 },
                s: { a: 0, k: [112.367, 85.726, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [911.309, 741.039, 0],
                      t: 0,
                      ti: [0.852, -3.414, 0],
                      to: [-0.441, 1.093, 0],
                    },
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [908.66, 747.595, 0],
                      t: 2,
                      ti: [-3.634, -3.32, 0],
                      to: [-0.852, 3.414, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.16 },
                      i: { x: 0.833, y: 0.84 },
                      s: [906.196, 761.521, 0],
                      t: 6,
                      ti: [-6.043, -0.029, 0],
                      to: [3.634, 3.32, 0],
                    },
                    {
                      o: { x: 0.167, y: 0.145 },
                      i: { x: 0.833, y: 0.855 },
                      s: [930.467, 767.514, 0],
                      t: 7,
                      ti: [-1.413, 2.099, 0],
                      to: [6.043, 0.029, 0],
                    },
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [942.455, 761.696, 0],
                      t: 8,
                      ti: [2.212, 2.156, 0],
                      to: [1.413, -2.099, 0],
                    },
                    {
                      o: { x: 0.167, y: 0 },
                      i: { x: 0.833, y: 1 },
                      s: [938.947, 754.918, 0],
                      t: 10,
                      ti: [1.627, 1.026, 0],
                      to: [-2.212, -2.156, 0],
                    },
                    { s: [937.843, 753.786, 0], t: 11 },
                  ],
                  ix: 2,
                },
                r: { a: 0, k: 192.366, ix: 10 },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-38.541, -7.725],
                                  [-34.373, 20.269],
                                  [6.355, 11.687],
                                  [5.134, -15.293],
                                ],
                              },
                            ],
                            t: -10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-25.117, -16.712],
                                  [-25.809, 17.275],
                                  [16.12, 8.334],
                                  [19.812, -21.992],
                                ],
                              },
                            ],
                            t: -8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [6.569, -5.843],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-23.582, -15.505],
                                  [-25.668, 16.734],
                                  [15.142, 8.542],
                                  [20.093, -23.074],
                                ],
                              },
                            ],
                            t: -6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-8.122, -7.2],
                                  [-5.666, 0.591],
                                  [8.081, 2.572],
                                ],
                                o: [
                                  [-3.56, 7.65],
                                  [15.952, -2.906],
                                  [4.792, -6.032],
                                  [-3.802, -1.21],
                                ],
                                v: [
                                  [-25.444, -19.454],
                                  [-26.136, 14.533],
                                  [14.674, 6.341],
                                  [18.231, -27.023],
                                ],
                              },
                            ],
                            t: -3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.58, -8.631],
                                  [-4.84, 3.889],
                                  [7.497, 4.925],
                                ],
                                o: [
                                  [-4.969, 6.82],
                                  [12.108, 12.707],
                                  [6.015, -3.024],
                                  [-3.335, -2.191],
                                ],
                                v: [
                                  [-17.56, -34.542],
                                  [-23.453, 0.335],
                                  [18.325, 3.806],
                                  [24.218, -26.905],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.289, -8.846],
                                  [-4.967, 3.725],
                                  [7.329, 5.172],
                                ],
                                o: [
                                  [-5.193, 6.65],
                                  [11.678, 13.104],
                                  [6.112, -2.821],
                                  [-3.26, -2.301],
                                ],
                                v: [
                                  [-17.009, -31.93],
                                  [-24.062, 2.732],
                                  [17.577, 7.594],
                                  [24.491, -22.904],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-6.979, -8.312],
                                  [-7.722, 6.879],
                                  [7.72, 4.567],
                                ],
                                o: [
                                  [-4.642, 7.046],
                                  [12.693, 12.123],
                                  [7.256, -5.255],
                                  [-3.434, -2.031],
                                ],
                                v: [
                                  [-19.14, -24.595],
                                  [-23.385, 10.522],
                                  [17.003, 13.359],
                                  [22.951, -18.933],
                                ],
                              },
                            ],
                            t: 2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-7.012, -8.284],
                                  [-7.694, 6.909],
                                  [7.739, 4.536],
                                ],
                                o: [
                                  [-4.614, 7.064],
                                  [12.741, 12.073],
                                  [7.235, -5.284],
                                  [-3.442, -2.018],
                                ],
                                v: [
                                  [-21.396, -19.887],
                                  [-23.311, 17.068],
                                  [17.088, 19.747],
                                  [22.908, -12.569],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-5.965, -9.067],
                                  [-8.469, 5.934],
                                  [7.137, 5.434],
                                ],
                                o: [
                                  [-5.43, 6.459],
                                  [11.197, 13.517],
                                  [7.818, -4.376],
                                  [-3.175, -2.417],
                                ],
                                v: [
                                  [-7.752, -17.829],
                                  [-16.052, 16.555],
                                  [23.733, 24.07],
                                  [33.395, -7.312],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [0, 0],
                                  [-5.63, -9.279],
                                  [-9.515, 4.351],
                                  [6.934, 5.691],
                                ],
                                o: [
                                  [-5.662, 6.256],
                                  [10.695, 13.917],
                                  [7.973, -4.087],
                                  [-3.084, -2.531],
                                ],
                                v: [
                                  [-2.636, -23.792],
                                  [-12.188, 10.266],
                                  [27.183, 20.255],
                                  [38.099, -11.777],
                                ],
                              },
                            ],
                            t: 8,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [881.203, 765.949], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 20,
              parent: 21,
            },
            {
              ty: 3,
              nm: 'left shoe',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [868, 856, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: {
                  s: true,
                  x: {
                    a: 1,
                    k: [
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.743, y: 0.839 }, s: [190.5], t: -12 },
                      { o: { x: 0.402, y: 0 }, i: { x: 0.734, y: 1 }, s: [-55.5], t: 0 },
                      { o: { x: 0.121, y: 0.124 }, i: { x: 0.853, y: 0.871 }, s: [190.5], t: 12 },
                      { s: [-55.5], t: 24 },
                    ],
                    ix: 3,
                  },
                  y: {
                    a: 1,
                    k: [
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [448.5], t: -12 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [454.5], t: -10 },
                      { o: { x: 0.472, y: 0 }, i: { x: 0.955, y: 0.742 }, s: [454.5], t: -3 },
                      { o: { x: 0.04, y: 0.52 }, i: { x: 0.562, y: 1 }, s: [419.5], t: 0 },
                      { o: { x: 0.586, y: 0 }, i: { x: 0.839, y: 1 }, s: [389], t: 6 },
                      { o: { x: 0.12, y: 0.018 }, i: { x: 0.667, y: 1 }, s: [448.5], t: 12 },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [454.5], t: 14 },
                      { o: { x: 0.472, y: 0 }, i: { x: 0.955, y: 0.742 }, s: [454.5], t: 21 },
                      { o: { x: 0.04, y: 0.52 }, i: { x: 0.562, y: 1 }, s: [419.5], t: 24 },
                      { o: { x: 0.586, y: 0 }, i: { x: 0.839, y: 1 }, s: [389], t: 30 },
                      { s: [448.5], t: 36 },
                    ],
                    ix: 4,
                  },
                  z: { a: 0, k: 0 },
                },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [-29], t: -12 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: -10 },
                    { o: { x: 0.625, y: 0 }, i: { x: 0.937, y: 0.87 }, s: [0], t: -3 },
                    { o: { x: 0.202, y: 0 }, i: { x: 0.811, y: 1 }, s: [23], t: 0 },
                    { o: { x: 0.081, y: 0.118 }, i: { x: 0.516, y: 1 }, s: [23], t: 6 },
                    { o: { x: 0.081, y: 0.059 }, i: { x: 0.667, y: 1 }, s: [-29], t: 12 },
                    { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 14 },
                    { o: { x: 0.625, y: 0 }, i: { x: 0.937, y: 0.87 }, s: [0], t: 21 },
                    { o: { x: 0.202, y: 0 }, i: { x: 0.811, y: 1 }, s: [23], t: 24 },
                    { s: [23], t: 30 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              ind: 21,
              parent: 5,
            },
            {
              ty: 4,
              nm: 'Shape Layer 12',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [72, 272, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [880.297, 818.795, 0], ix: 2 },
                r: {
                  a: 1,
                  k: [
                    { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [0.227], t: 0 },
                    { o: { x: 0.167, y: 0 }, i: { x: 0.833, y: 1 }, s: [0.227], t: 3 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0.227], t: 6 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [17.551], t: 7 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [19.146], t: 9 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.03], t: 12 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [-19.014], t: 14 },
                    { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [-0.779], t: 16 },
                    { s: [0.227], t: 23 },
                  ],
                  ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Shape 1',
                  ix: 1,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, 0],
                            [7, -12],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-7, 12],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [119, 205],
                            [73, 207],
                            [49.691, 286.829],
                            [84.955, 305.623],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [0, 0], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 22,
              parent: 21,
            },
            {
              ty: 4,
              nm: 'left back shoe ',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [865.111, 828.512, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [865.861, 825.512, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 10 },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.255, 3.049],
                                  [0, 0],
                                  [-3.048, 5.254],
                                  [0, 0],
                                  [-5.254, -3.048],
                                  [0, 0],
                                  [3.048, -5.254],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.254, -3.048],
                                  [0, 0],
                                  [3.048, -5.254],
                                  [0, 0],
                                  [5.255, 3.049],
                                  [0, 0],
                                  [-3.048, 5.254],
                                ],
                                v: [
                                  [-11.693, 21.231],
                                  [-14.458, 19.627],
                                  [-18.452, 4.593],
                                  [-12.924, -4.935],
                                  [2.11, -8.929],
                                  [4.875, -7.325],
                                  [8.869, 7.709],
                                  [3.341, 17.237],
                                ],
                              },
                            ],
                            t: -12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.975, 1.098],
                                  [0, 0],
                                  [-1.098, 5.974],
                                  [0, 0],
                                  [-5.974, -1.098],
                                  [0, 0],
                                  [1.098, -5.974],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.974, -1.098],
                                  [0, 0],
                                  [1.098, -5.974],
                                  [0, 0],
                                  [5.975, 1.098],
                                  [0, 0],
                                  [-1.098, 5.974],
                                ],
                                v: [
                                  [-3.945, 22.84],
                                  [-7.089, 22.262],
                                  [-15.919, 9.457],
                                  [-13.928, -1.378],
                                  [-1.123, -10.208],
                                  [2.022, -9.63],
                                  [10.852, 3.175],
                                  [8.861, 14.01],
                                ],
                              },
                            ],
                            t: -11,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-0.637, 20.318],
                                  [-3.834, 20.328],
                                  [-14.865, 9.361],
                                  [-14.897, -1.655],
                                  [-3.931, -12.686],
                                  [-0.734, -12.695],
                                  [10.298, -1.729],
                                  [10.33, 9.287],
                                ],
                              },
                            ],
                            t: -10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-1.637, 20.318],
                                  [-4.834, 20.328],
                                  [-15.865, 9.361],
                                  [-15.897, -1.655],
                                  [-4.931, -12.686],
                                  [-1.734, -12.695],
                                  [9.298, -1.729],
                                  [9.33, 9.287],
                                ],
                              },
                            ],
                            t: -9,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-3.137, 20.318],
                                  [-6.334, 20.328],
                                  [-17.365, 9.361],
                                  [-17.397, -1.655],
                                  [-6.431, -12.686],
                                  [-3.234, -12.695],
                                  [7.798, -1.729],
                                  [7.83, 9.287],
                                ],
                              },
                            ],
                            t: -8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-4.387, 20.068],
                                  [-7.584, 20.078],
                                  [-18.615, 9.111],
                                  [-18.647, -1.905],
                                  [-7.681, -12.936],
                                  [-4.484, -12.945],
                                  [6.548, -1.979],
                                  [6.58, 9.037],
                                ],
                              },
                            ],
                            t: -7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-2.637, 20.318],
                                  [-5.834, 20.328],
                                  [-16.865, 9.361],
                                  [-16.897, -1.655],
                                  [-5.931, -12.686],
                                  [-2.734, -12.695],
                                  [8.298, -1.729],
                                  [8.33, 9.287],
                                ],
                              },
                            ],
                            t: -6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-0.72, 20.318],
                                  [-3.917, 20.328],
                                  [-14.948, 9.361],
                                  [-14.981, -1.655],
                                  [-4.014, -12.686],
                                  [-0.817, -12.695],
                                  [10.214, -1.729],
                                  [10.247, 9.287],
                                ],
                              },
                            ],
                            t: -5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-2.303, 18.318],
                                  [-5.5, 18.328],
                                  [-16.531, 7.361],
                                  [-16.564, -3.655],
                                  [-5.597, -14.686],
                                  [-2.4, -14.695],
                                  [8.631, -3.729],
                                  [8.663, 7.287],
                                ],
                              },
                            ],
                            t: -4,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-2.887, 14.318],
                                  [-6.084, 14.328],
                                  [-17.115, 3.361],
                                  [-17.147, -7.655],
                                  [-6.181, -18.686],
                                  [-2.984, -18.695],
                                  [8.048, -7.729],
                                  [8.08, 3.287],
                                ],
                              },
                            ],
                            t: -3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.84, 0.05],
                                  [0, 0],
                                  [-0.05, 5.839],
                                  [0, 0],
                                  [-5.839, -0.05],
                                  [0, 0],
                                  [0.05, -5.839],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.839, -0.05],
                                  [0, 0],
                                  [0.05, -5.839],
                                  [0, 0],
                                  [5.84, 0.05],
                                  [0, 0],
                                  [-0.05, 5.839],
                                ],
                                v: [
                                  [1.063, 8.288],
                                  [-2.011, 8.262],
                                  [-12.494, -2.402],
                                  [-12.404, -12.992],
                                  [-1.74, -23.475],
                                  [1.333, -23.449],
                                  [11.817, -12.785],
                                  [11.726, -2.195],
                                ],
                              },
                            ],
                            t: -2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.6, -0.695],
                                  [0, 0],
                                  [0.695, 5.599],
                                  [0, 0],
                                  [-5.599, 0.695],
                                  [0, 0],
                                  [-0.695, -5.599],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.599, 0.695],
                                  [0, 0],
                                  [-0.695, -5.599],
                                  [0, 0],
                                  [5.6, -0.695],
                                  [0, 0],
                                  [0.695, 5.599],
                                ],
                                v: [
                                  [8.647, 2.446],
                                  [5.7, 2.812],
                                  [-5.697, -6.069],
                                  [-6.957, -16.224],
                                  [1.924, -27.621],
                                  [4.871, -27.987],
                                  [16.268, -19.106],
                                  [17.528, -8.951],
                                ],
                              },
                            ],
                            t: -1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.205, -2.371],
                                  [0, 0],
                                  [2.371, 5.204],
                                  [0, 0],
                                  [-5.204, 2.37],
                                  [0, 0],
                                  [-2.37, -5.204],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.204, 2.37],
                                  [0, 0],
                                  [-2.37, -5.204],
                                  [0, 0],
                                  [5.205, -2.371],
                                  [0, 0],
                                  [2.37, 5.204],
                                ],
                                v: [
                                  [14.137, -0.477],
                                  [11.398, 0.771],
                                  [-2.318, -4.36],
                                  [-6.617, -13.797],
                                  [-1.486, -27.513],
                                  [1.253, -28.761],
                                  [14.968, -23.63],
                                  [19.268, -14.193],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.914, -3.155],
                                  [0, 0],
                                  [3.155, 4.914],
                                  [0, 0],
                                  [-4.914, 3.155],
                                  [0, 0],
                                  [-3.155, -4.914],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.914, 3.155],
                                  [0, 0],
                                  [-3.155, -4.914],
                                  [0, 0],
                                  [4.914, -3.155],
                                  [0, 0],
                                  [3.155, 4.914],
                                ],
                                v: [
                                  [15.844, 0.663],
                                  [13.258, 2.324],
                                  [-1.353, -0.862],
                                  [-7.074, -9.773],
                                  [-3.889, -24.384],
                                  [-1.303, -26.045],
                                  [13.308, -22.859],
                                  [19.029, -13.947],
                                ],
                              },
                            ],
                            t: 1,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.624, -3.94],
                                  [0, 0],
                                  [3.939, 4.624],
                                  [0, 0],
                                  [-5.426, 4.475],
                                  [0, 0],
                                  [-3.939, -4.624],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.624, 3.939],
                                  [0, 0],
                                  [-0.89, -7.844],
                                  [0, 0],
                                  [4.624, -3.939],
                                  [0, 0],
                                  [3.939, 4.624],
                                ],
                                v: [
                                  [18.788, 4.272],
                                  [16.354, 6.345],
                                  [-6.056, 9.628],
                                  [-7.524, -2.421],
                                  [-5.055, -18.786],
                                  [-2.621, -20.86],
                                  [12.884, -19.62],
                                  [20.028, -11.234],
                                ],
                              },
                            ],
                            t: 2,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.815, -3.649],
                                  [0, 0],
                                  [3.648, 4.815],
                                  [0, 0],
                                  [-6.211, 4.745],
                                  [0, 0],
                                  [-3.648, -4.814],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.814, 3.648],
                                  [0, 0],
                                  [-1.438, -6.641],
                                  [0, 0],
                                  [4.815, -3.648],
                                  [0, 0],
                                  [3.648, 4.814],
                                ],
                                v: [
                                  [16.916, 3.928],
                                  [14.382, 5.848],
                                  [-6.362, 7.761],
                                  [-8.328, -4.139],
                                  [-5.446, -20.32],
                                  [-2.912, -22.24],
                                  [12.412, -20.128],
                                  [19.028, -11.396],
                                ],
                              },
                            ],
                            t: 3,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.197, -3.067],
                                  [0, 0],
                                  [3.066, 5.196],
                                  [0, 0],
                                  [-5.196, 3.066],
                                  [0, 0],
                                  [-3.066, -5.196],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.196, 3.066],
                                  [0, 0],
                                  [-3.066, -5.196],
                                  [0, 0],
                                  [5.197, -3.066],
                                  [0, 0],
                                  [3.066, 5.196],
                                ],
                                v: [
                                  [14.873, 7.555],
                                  [12.138, 9.169],
                                  [-2.823, 5.312],
                                  [-8.383, -4.112],
                                  [-4.526, -19.073],
                                  [-1.791, -20.687],
                                  [13.17, -16.83],
                                  [18.73, -7.406],
                                ],
                              },
                            ],
                            t: 5,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.503, -2.572],
                                  [0, 0],
                                  [2.572, 5.503],
                                  [0, 0],
                                  [-5.503, 2.572],
                                  [0, 0],
                                  [-2.572, -5.503],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.503, 2.572],
                                  [0, 0],
                                  [-2.572, -5.503],
                                  [0, 0],
                                  [5.503, -2.572],
                                  [0, 0],
                                  [2.572, 5.503],
                                ],
                                v: [
                                  [11.162, 8.778],
                                  [8.265, 10.132],
                                  [-6.356, 4.825],
                                  [-11.021, -5.155],
                                  [-5.714, -19.776],
                                  [-2.817, -21.13],
                                  [11.804, -15.823],
                                  [16.469, -5.843],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.037, -0.675],
                                  [0, 0],
                                  [0.675, 6.036],
                                  [0, 0],
                                  [-6.036, 0.675],
                                  [0, 0],
                                  [-0.675, -6.036],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.036, 0.675],
                                  [0, 0],
                                  [-0.675, -6.036],
                                  [0, 0],
                                  [6.037, -0.675],
                                  [0, 0],
                                  [0.675, 6.036],
                                ],
                                v: [
                                  [4.156, 14.312],
                                  [0.979, 14.668],
                                  [-11.175, 4.959],
                                  [-12.399, -5.989],
                                  [-2.691, -18.142],
                                  [0.487, -18.497],
                                  [12.64, -8.789],
                                  [13.864, 2.159],
                                ],
                              },
                            ],
                            t: 7,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.066, 0.331],
                                  [0, 0],
                                  [-0.331, 6.065],
                                  [0, 0],
                                  [-6.065, -0.331],
                                  [0, 0],
                                  [0.331, -6.065],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.065, -0.331],
                                  [0, 0],
                                  [0.331, -6.065],
                                  [0, 0],
                                  [6.066, 0.331],
                                  [0, 0],
                                  [-0.331, 6.065],
                                ],
                                v: [
                                  [3.872, 10.449],
                                  [0.68, 10.275],
                                  [-9.704, -1.307],
                                  [-9.104, -12.307],
                                  [2.478, -22.69],
                                  [5.671, -22.516],
                                  [16.054, -10.934],
                                  [15.454, 0.065],
                                ],
                              },
                            ],
                            t: 8,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.545, 2.481],
                                  [0, 0],
                                  [-2.481, 5.544],
                                  [0, 0],
                                  [-5.544, -2.48],
                                  [0, 0],
                                  [2.48, -5.544],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.544, -2.48],
                                  [0, 0],
                                  [2.481, -5.544],
                                  [0, 0],
                                  [5.545, 2.481],
                                  [0, 0],
                                  [-2.48, 5.544],
                                ],
                                v: [
                                  [-2.371, 12.87],
                                  [-5.289, 11.565],
                                  [-10.837, -2.967],
                                  [-6.339, -13.023],
                                  [8.193, -18.571],
                                  [11.112, -17.265],
                                  [16.66, -2.734],
                                  [12.161, 7.322],
                                ],
                              },
                            ],
                            t: 10,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.255, 3.049],
                                  [0, 0],
                                  [-3.048, 5.254],
                                  [0, 0],
                                  [-5.254, -3.048],
                                  [0, 0],
                                  [3.048, -5.254],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.254, -3.048],
                                  [0, 0],
                                  [3.048, -5.254],
                                  [0, 0],
                                  [5.255, 3.049],
                                  [0, 0],
                                  [-3.048, 5.254],
                                ],
                                v: [
                                  [-11.693, 21.231],
                                  [-14.458, 19.627],
                                  [-18.452, 4.593],
                                  [-12.924, -4.935],
                                  [2.11, -8.929],
                                  [4.875, -7.325],
                                  [8.869, 7.709],
                                  [3.341, 17.237],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.975, 1.098],
                                  [0, 0],
                                  [-1.098, 5.974],
                                  [0, 0],
                                  [-5.974, -1.098],
                                  [0, 0],
                                  [1.098, -5.974],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.974, -1.098],
                                  [0, 0],
                                  [1.098, -5.974],
                                  [0, 0],
                                  [5.975, 1.098],
                                  [0, 0],
                                  [-1.098, 5.974],
                                ],
                                v: [
                                  [-3.945, 22.84],
                                  [-7.089, 22.262],
                                  [-15.919, 9.457],
                                  [-13.928, -1.378],
                                  [-1.123, -10.208],
                                  [2.022, -9.63],
                                  [10.852, 3.175],
                                  [8.861, 14.01],
                                ],
                              },
                            ],
                            t: 13,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-0.637, 20.318],
                                  [-3.834, 20.328],
                                  [-14.865, 9.361],
                                  [-14.897, -1.655],
                                  [-3.931, -12.686],
                                  [-0.734, -12.695],
                                  [10.298, -1.729],
                                  [10.33, 9.287],
                                ],
                              },
                            ],
                            t: 14,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-1.637, 20.318],
                                  [-4.834, 20.328],
                                  [-15.865, 9.361],
                                  [-15.897, -1.655],
                                  [-4.931, -12.686],
                                  [-1.734, -12.695],
                                  [9.298, -1.729],
                                  [9.33, 9.287],
                                ],
                              },
                            ],
                            t: 15,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-3.137, 20.318],
                                  [-6.334, 20.328],
                                  [-17.365, 9.361],
                                  [-17.397, -1.655],
                                  [-6.431, -12.686],
                                  [-3.234, -12.695],
                                  [7.798, -1.729],
                                  [7.83, 9.287],
                                ],
                              },
                            ],
                            t: 16,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-4.387, 20.068],
                                  [-7.584, 20.078],
                                  [-18.615, 9.111],
                                  [-18.647, -1.905],
                                  [-7.681, -12.936],
                                  [-4.484, -12.945],
                                  [6.548, -1.979],
                                  [6.58, 9.037],
                                ],
                              },
                            ],
                            t: 17,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-2.637, 20.318],
                                  [-5.834, 20.328],
                                  [-16.865, 9.361],
                                  [-16.897, -1.655],
                                  [-5.931, -12.686],
                                  [-2.734, -12.695],
                                  [8.298, -1.729],
                                  [8.33, 9.287],
                                ],
                              },
                            ],
                            t: 18,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-0.72, 20.318],
                                  [-3.917, 20.328],
                                  [-14.948, 9.361],
                                  [-14.981, -1.655],
                                  [-4.014, -12.686],
                                  [-0.817, -12.695],
                                  [10.214, -1.729],
                                  [10.247, 9.287],
                                ],
                              },
                            ],
                            t: 19,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-2.303, 18.318],
                                  [-5.5, 18.328],
                                  [-16.531, 7.361],
                                  [-16.564, -3.655],
                                  [-5.597, -14.686],
                                  [-2.4, -14.695],
                                  [8.631, -3.729],
                                  [8.663, 7.287],
                                ],
                              },
                            ],
                            t: 20,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.074, 0.018],
                                  [0, 0],
                                  [-0.018, -6.074],
                                  [0, 0],
                                  [6.075, -0.018],
                                  [0, 0],
                                  [0.018, 6.074],
                                ],
                                v: [
                                  [-2.887, 14.318],
                                  [-6.084, 14.328],
                                  [-17.115, 3.361],
                                  [-17.147, -7.655],
                                  [-6.181, -18.686],
                                  [-2.984, -18.695],
                                  [8.048, -7.729],
                                  [8.08, 3.287],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.84, 0.05],
                                  [0, 0],
                                  [-0.05, 5.839],
                                  [0, 0],
                                  [-5.839, -0.05],
                                  [0, 0],
                                  [0.05, -5.839],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.839, -0.05],
                                  [0, 0],
                                  [0.05, -5.839],
                                  [0, 0],
                                  [5.84, 0.05],
                                  [0, 0],
                                  [-0.05, 5.839],
                                ],
                                v: [
                                  [1.063, 8.288],
                                  [-2.011, 8.262],
                                  [-12.494, -2.402],
                                  [-12.404, -12.992],
                                  [-1.74, -23.475],
                                  [1.333, -23.449],
                                  [11.817, -12.785],
                                  [11.726, -2.195],
                                ],
                              },
                            ],
                            t: 22,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.6, -0.695],
                                  [0, 0],
                                  [0.695, 5.599],
                                  [0, 0],
                                  [-5.599, 0.695],
                                  [0, 0],
                                  [-0.695, -5.599],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.599, 0.695],
                                  [0, 0],
                                  [-0.695, -5.599],
                                  [0, 0],
                                  [5.6, -0.695],
                                  [0, 0],
                                  [0.695, 5.599],
                                ],
                                v: [
                                  [8.647, 2.446],
                                  [5.7, 2.812],
                                  [-5.697, -6.069],
                                  [-6.957, -16.224],
                                  [1.924, -27.621],
                                  [4.871, -27.987],
                                  [16.268, -19.106],
                                  [17.528, -8.951],
                                ],
                              },
                            ],
                            t: 23,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.205, -2.371],
                                  [0, 0],
                                  [2.371, 5.204],
                                  [0, 0],
                                  [-5.204, 2.37],
                                  [0, 0],
                                  [-2.37, -5.204],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.204, 2.37],
                                  [0, 0],
                                  [-2.37, -5.204],
                                  [0, 0],
                                  [5.205, -2.371],
                                  [0, 0],
                                  [2.37, 5.204],
                                ],
                                v: [
                                  [14.137, -0.477],
                                  [11.398, 0.771],
                                  [-2.318, -4.36],
                                  [-6.617, -13.797],
                                  [-1.486, -27.513],
                                  [1.253, -28.761],
                                  [14.968, -23.63],
                                  [19.268, -14.193],
                                ],
                              },
                            ],
                            t: 24,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.914, -3.155],
                                  [0, 0],
                                  [3.155, 4.914],
                                  [0, 0],
                                  [-4.914, 3.155],
                                  [0, 0],
                                  [-3.155, -4.914],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.914, 3.155],
                                  [0, 0],
                                  [-3.155, -4.914],
                                  [0, 0],
                                  [4.914, -3.155],
                                  [0, 0],
                                  [3.155, 4.914],
                                ],
                                v: [
                                  [15.844, 0.663],
                                  [13.258, 2.324],
                                  [-1.353, -0.862],
                                  [-7.074, -9.773],
                                  [-3.889, -24.384],
                                  [-1.303, -26.045],
                                  [13.308, -22.859],
                                  [19.029, -13.947],
                                ],
                              },
                            ],
                            t: 25,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.624, -3.94],
                                  [0, 0],
                                  [3.939, 4.624],
                                  [0, 0],
                                  [-5.426, 4.475],
                                  [0, 0],
                                  [-3.939, -4.624],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.624, 3.939],
                                  [0, 0],
                                  [-0.89, -7.844],
                                  [0, 0],
                                  [4.624, -3.939],
                                  [0, 0],
                                  [3.939, 4.624],
                                ],
                                v: [
                                  [18.788, 4.272],
                                  [16.354, 6.345],
                                  [-6.056, 9.628],
                                  [-7.524, -2.421],
                                  [-5.055, -18.786],
                                  [-2.621, -20.86],
                                  [12.884, -19.62],
                                  [20.028, -11.234],
                                ],
                              },
                            ],
                            t: 26,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [4.815, -3.649],
                                  [0, 0],
                                  [3.648, 4.815],
                                  [0, 0],
                                  [-6.211, 4.745],
                                  [0, 0],
                                  [-3.648, -4.814],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-4.814, 3.648],
                                  [0, 0],
                                  [-1.438, -6.641],
                                  [0, 0],
                                  [4.815, -3.648],
                                  [0, 0],
                                  [3.648, 4.814],
                                ],
                                v: [
                                  [16.916, 3.928],
                                  [14.382, 5.848],
                                  [-6.362, 7.761],
                                  [-8.328, -4.139],
                                  [-5.446, -20.32],
                                  [-2.912, -22.24],
                                  [12.412, -20.128],
                                  [19.028, -11.396],
                                ],
                              },
                            ],
                            t: 27,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.197, -3.067],
                                  [0, 0],
                                  [3.066, 5.196],
                                  [0, 0],
                                  [-5.196, 3.066],
                                  [0, 0],
                                  [-3.066, -5.196],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.196, 3.066],
                                  [0, 0],
                                  [-3.066, -5.196],
                                  [0, 0],
                                  [5.197, -3.066],
                                  [0, 0],
                                  [3.066, 5.196],
                                ],
                                v: [
                                  [14.873, 7.555],
                                  [12.138, 9.169],
                                  [-2.823, 5.312],
                                  [-8.383, -4.112],
                                  [-4.526, -19.073],
                                  [-1.791, -20.687],
                                  [13.17, -16.83],
                                  [18.73, -7.406],
                                ],
                              },
                            ],
                            t: 29,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.503, -2.572],
                                  [0, 0],
                                  [2.572, 5.503],
                                  [0, 0],
                                  [-5.503, 2.572],
                                  [0, 0],
                                  [-2.572, -5.503],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.503, 2.572],
                                  [0, 0],
                                  [-2.572, -5.503],
                                  [0, 0],
                                  [5.503, -2.572],
                                  [0, 0],
                                  [2.572, 5.503],
                                ],
                                v: [
                                  [11.162, 8.778],
                                  [8.265, 10.132],
                                  [-6.356, 4.825],
                                  [-11.021, -5.155],
                                  [-5.714, -19.776],
                                  [-2.817, -21.13],
                                  [11.804, -15.823],
                                  [16.469, -5.843],
                                ],
                              },
                            ],
                            t: 30,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.037, -0.675],
                                  [0, 0],
                                  [0.675, 6.036],
                                  [0, 0],
                                  [-6.036, 0.675],
                                  [0, 0],
                                  [-0.675, -6.036],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.036, 0.675],
                                  [0, 0],
                                  [-0.675, -6.036],
                                  [0, 0],
                                  [6.037, -0.675],
                                  [0, 0],
                                  [0.675, 6.036],
                                ],
                                v: [
                                  [4.156, 14.312],
                                  [0.979, 14.668],
                                  [-11.175, 4.959],
                                  [-12.399, -5.989],
                                  [-2.691, -18.142],
                                  [0.487, -18.497],
                                  [12.64, -8.789],
                                  [13.864, 2.159],
                                ],
                              },
                            ],
                            t: 31,
                          },
                          {
                            o: { x: 0.167, y: 0 },
                            i: { x: 0.833, y: 1 },
                            s: [
                              {
                                c: true,
                                i: [
                                  [6.066, 0.331],
                                  [0, 0],
                                  [-0.331, 6.065],
                                  [0, 0],
                                  [-6.065, -0.331],
                                  [0, 0],
                                  [0.331, -6.065],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-6.065, -0.331],
                                  [0, 0],
                                  [0.331, -6.065],
                                  [0, 0],
                                  [6.066, 0.331],
                                  [0, 0],
                                  [-0.331, 6.065],
                                ],
                                v: [
                                  [3.872, 10.449],
                                  [0.68, 10.275],
                                  [-9.704, -1.307],
                                  [-9.104, -12.307],
                                  [2.478, -22.69],
                                  [5.671, -22.516],
                                  [16.054, -10.934],
                                  [15.454, 0.065],
                                ],
                              },
                            ],
                            t: 32,
                          },
                          {
                            s: [
                              {
                                c: true,
                                i: [
                                  [5.545, 2.481],
                                  [0, 0],
                                  [-2.481, 5.544],
                                  [0, 0],
                                  [-5.544, -2.48],
                                  [0, 0],
                                  [2.48, -5.544],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.544, -2.48],
                                  [0, 0],
                                  [2.481, -5.544],
                                  [0, 0],
                                  [5.545, 2.481],
                                  [0, 0],
                                  [-2.48, 5.544],
                                ],
                                v: [
                                  [-2.371, 12.87],
                                  [-5.289, 11.565],
                                  [-10.837, -2.967],
                                  [-6.339, -13.023],
                                  [8.193, -18.571],
                                  [11.112, -17.265],
                                  [16.66, -2.734],
                                  [12.161, 7.322],
                                ],
                              },
                            ],
                            t: 34,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 3, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'fl',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Fill',
                      nm: 'Fill 1',
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [865.111, 828.512], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 23,
              parent: 21,
            },
            {
              ty: 4,
              nm: 'shadow ',
              sr: 1,
              st: -24,
              op: 24,
              ip: 0,
              hd: false,
              ddd: 0,
              bm: 0,
              hasMask: false,
              ao: 0,
              ks: {
                a: { a: 0, k: [940, 896, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [292, 339, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 10 },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
              },
              ef: [],
              shapes: [
                {
                  ty: 'gr',
                  bm: 0,
                  hd: false,
                  mn: 'ADBE Vector Group',
                  nm: 'Group 1',
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: 'sh',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Shape - Group',
                      nm: 'Path 1',
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [764.195, 897.219],
                                  [1102.507, 897.219],
                                ],
                              },
                            ],
                            t: 0,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [784.195, 897.438],
                                  [1060.507, 897.438],
                                ],
                              },
                            ],
                            t: 3,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [803.195, 897.438],
                                  [1010.507, 897.438],
                                ],
                              },
                            ],
                            t: 6,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [731.195, 897.438],
                                  [1142.507, 897.438],
                                ],
                              },
                            ],
                            t: 12,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [726.195, 897.438],
                                  [1128.507, 897.438],
                                ],
                              },
                            ],
                            t: 14,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [807.195, 897.438],
                                  [1042.507, 897.438],
                                ],
                              },
                            ],
                            t: 18,
                          },
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0.667, y: 1 },
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [801.195, 897.438],
                                  [1052.507, 897.438],
                                ],
                              },
                            ],
                            t: 21,
                          },
                          {
                            s: [
                              {
                                c: false,
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [764.195, 897.219],
                                  [1102.507, 897.219],
                                ],
                              },
                            ],
                            t: 24,
                          },
                        ],
                        ix: 2,
                      },
                    },
                    {
                      ty: 'st',
                      bm: 0,
                      hd: false,
                      mn: 'ADBE Vector Graphic - Stroke',
                      nm: 'Stroke 1',
                      lc: 2,
                      lj: 2,
                      ml: 1,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 8, ix: 5 },
                      c: { a: 0, k: [0.8863, 0.7098, 0.1529], ix: 3 },
                    },
                    {
                      ty: 'tr',
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [0, 0], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
              ],
              ind: 24,
            },
          ],
          v: '5.8.1',
          fr: 24,
          op: 24,
          ip: 0,
          assets: [],
        }}
        loop={true}
      />
    </div>
  );
};

export default showCalculating;
