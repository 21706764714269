import { motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from 'utils/animation.js';

import imgUsp01 from 'assets/images/kam-pa-kam/usp01.svg';
import imgUsp02 from 'assets/images/kam-pa-kam/usp02.svg';
import imgUsp03 from 'assets/images/kam-pa-kam/usp03.svg';

const showIntro = ({ startQuiz, t }) => {
  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="wrapper quiz-intro h-100 w-100 d-flex justify-content-center  "
      >
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="inner-wrapper position-relative text-center mt-3 mt-sm-6 "
        >
          <motion.h1 variants={child} className="h1-welcome mt-4 mt-sm-0 mb-4 ">
            <span className="text-creme mb-3">{t('Pametni vodič')}</span>
            {t('Kam pa kam po Kranjski Gori')}
          </motion.h1>

          <div className="bottom-wrapper px-1  mx-sm-0">
            <div className="text-center usps mx-auto my-2 my-sm-4">
              <p
                className="px-sm-6"
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Nimaš ideje, kam na izlet? Reši interaktivni kviz in naš spletni vodič ti bo predlagal izlet, ki se v tem trenutku <strong>najbolj približa tvojim željam.</strong> Pa pot pod noge!'
                  ),
                }}
              ></p>
              <div className="row g-0">
                <motion.div variants={child} className="col-4">
                  <img className="icon" src={imgUsp01} />
                  <p className="pt-1">{t('Reši kviz in pridobi ideje za tvoj naslednji izlet.')}</p>
                </motion.div>
                <motion.div variants={child} className="col-4">
                  <img className="icon" src={imgUsp02} />
                  <p className="pt-1">{t('Načrtuj svoj podvig le v nekaj zabavnih minutah.')}</p>
                </motion.div>
                <motion.div variants={child} className="col-4">
                  <img className="icon" src={imgUsp03} />
                  <p className="pt-1">{t('Odkrij lokacije, ki so pisane na tvojo kožo.')}</p>
                </motion.div>
              </div>
            </div>
            <motion.button
              variants={child}
              className="btn btn-yellow rounded-pill text-white mt-1"
              onClick={startQuiz}
            >
              {t('Začni kviz')}
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default showIntro;
