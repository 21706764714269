import React, { useState, useEffect, useRef } from 'react';
import 'assets/styles/style.scss';
import { motion, AnimatePresence } from 'framer-motion';
import './i18n/config';
import { useTranslation } from 'react-i18next';

import quizApiSL from 'data/apiQuestions.json';
import quizProductsSL from 'data/apiProducts.json';
import quizApiEN from 'data/apiQuestionsEN.json';
import quizProductsEN from 'data/apiProductsEN.json';

import Intro from 'views/Intro';
import Questions from 'views/Questions';
import ShowResults from 'views/Results';

import imgLogoKrGora from 'assets/images/kam-pa-kam/logo-kr-gora.svg';
import imgLogoKEb from 'assets/images/kam-pa-kam/logo-eb.svg';
import imgLogoKrGoraCorner from 'assets/images/kam-pa-kam/logo-kr-corner.svg';

export default function App() {
  // Set locale
  const [quizApi, setQuizApi] = useState(quizApiSL);

  const [quizProducts, setQuizProducts] = useState(quizProductsSL);

  // When true intro will be displayed
  const [showIntro, setShowIntro] = useState(true);
  // When true results will be displayed
  const [showProducts, setShowProducts] = useState(false);
  // Recommended products showPreloader
  const [showPreloader, setShowshowPreloader] = useState(false);
  // Current question ID (we use id from API instead of index)
  const [currentQuestion, setCurrentQuestion] = useState(1);
  // Array of products that are determined by user answer choice
  const [chosenProducts, setchosenProducts] = useState([]);
  // Array of chosen answers
  const [chosenAnswer, setChosenAnswer] = useState([]);

  // Translations
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const windowUrl = window.location.search;

  const startQuiz = () => {
    setShowIntro(false);
    setShowProducts(false);
    setCurrentQuestion(1);
    setChosenAnswer([]);
    setchosenProducts([]);
    window.plausible('start');
  };

  const handleAnswerOptionClick = (path, productTags, answerText) => {
    window.scrollTo(0, 0);

    let nextQuestion;

    if (path === 'custom-dog') {
      if (chosenProducts.includes('pes-da')) {
        nextQuestion = 7;
      } else {
        nextQuestion = 8;
      }
    } else {
      nextQuestion = path;
    }

    // Add product to array based on chosen answer
    if (productTags !== null) {
      setchosenProducts((current) => [...current, productTags]);
    }

    // Check whether next question exists otherwise show results
    if (nextQuestion <= quizApi.content.length) {
      setTimeout(() => {
        setCurrentQuestion(nextQuestion);
      }, 500);

      /*       window.plausible(`${type}_${context}`, {
        props: {
          interaction_detail: detail,
        },
      }); */
    } else {
      //setShowshowPreloader(true);
      setTimeout(() => {
        console.log(chosenProducts);
        setShowIntro(true);
        setShowshowPreloader(false);
        setShowProducts(true);
        setTimeout(() => {
          window.plausible('result');
          setShowIntro(false);
        }, 6000 * (Math.random() + 0.5));
      }, 1000);
    }
    setChosenAnswer((current) => [...current, answerText]);
  };

  // Find object of current question
  const filterCurrentQuestion = quizApi.content.find((obj) => obj.id === currentQuestion);

  // Check if we have 100% productTags match
  const filterProductsEvery = quizProducts.filter((item) => {
    let results = chosenProducts.every((tag) => {
      return item.productTags.includes(tag);
    });

    return results;
  });

  // Check if we have more than 1 productTags match

  quizProducts.map((item) => {
    item.score = 0;
    chosenProducts.forEach((n) => {
      if (item.productTags.includes(n)) {
        item.score++;
      } else {
      }
    });

    return item;
  });

  const filterProductsSome = quizProducts.filter((item) => {
    var result = chosenProducts.filter((n) => {
      return item.productTags.includes(n);
    });

    if (result.length > 1) {
      return result;
    }
  });

  // If 100% match show that otherwise fallback to at least 1 match
  const filterProducts = () => {
    if (filterProductsEvery.length > 0) {
      return filterProductsEvery;
    } else {
      return filterProductsSome;
    }
  };

  // Set class based on first answer
  useEffect(() => {
    if (chosenAnswer.length === 4) {
      window.plausible(`q1_what`, {
        props: {
          detail: chosenAnswer[0],
        },
      });
      window.plausible(`q2_dog`, {
        props: {
          detail: chosenAnswer[1],
        },
      });
      window.plausible(`q3_instagram`, {
        props: {
          detail: chosenAnswer[2],
        },
      });
      window.plausible(`q4_food`, {
        props: {
          detail: chosenAnswer[3],
        },
      });
    }
  }, [chosenAnswer]);

  useEffect(() => {
    if (windowUrl === '?lang=en') {
      i18n.changeLanguage('en');
    } else if (windowUrl === '?lang=sl') {
      i18n.changeLanguage('sl');
    }
  }, []);

  useEffect(() => {
    if (currentLanguage === 'sl') {
      setQuizApi(quizApiSL);
      setQuizProducts(quizProductsSL);
    }

    if (currentLanguage === 'en') {
      setQuizApi(quizApiEN);
      setQuizProducts(quizProductsEN);
    }

    console.log(currentLanguage, windowUrl);
  }, [currentLanguage]);

  return (
    <AnimatePresence>
      <div className="quiz d-flex justify-content-center">
        {showProducts ? (
          <ShowResults
            setShowIntro={setShowIntro}
            showIntro={showIntro}
            filterProducts={filterProducts}
            startQuiz={startQuiz}
            quizProducts={quizProducts}
            t={t}
          />
        ) : showIntro ? (
          <Intro startQuiz={startQuiz} t={t} />
        ) : (
          <Questions
            showPreloader={showPreloader}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            filterCurrentQuestion={filterCurrentQuestion}
            handleAnswerOptionClick={handleAnswerOptionClick}
            t={t}
          />
        )}

        {/* for dev, temp*/}
        <div className="dev-filtered-products">
          <div>
            {chosenProducts.map((item, index) => {
              return <span key={index}>{item}</span>;
            })}
          </div>
          <div>
            {filterProducts().map((item, index) => {
              return <span key={index}>{item.productName}</span>;
            })}
          </div>
        </div>
        <div className="footer-logo footer-logo__left">
          <a
            href="https://escapebox.si/?utm_source=kviz&utm_medium=logo&utm_id=braun-priporocilni"
            target="_blank"
          >
            <img className="img-fluid" src={imgLogoKEb} alt="Escapebox" />
          </a>
        </div>
        <div className="footer-logo footer-logo__right">
          <a
            href="https://escapebox.si/?utm_source=kviz&utm_medium=logo&utm_id=braun-priporocilni"
            target="_blank"
          >
            <img className="img-fluid" src={imgLogoKrGora} alt="Escapebox" />
          </a>
        </div>

        <div className="header-logo">
          <a
            href="https://escapebox.si/?utm_source=kviz&utm_medium=logo&utm_id=braun-priporocilni"
            target="_blank"
          >
            <img className="img-fluid" src={imgLogoKrGoraCorner} alt="Escapebox" />
          </a>
        </div>
      </div>
    </AnimatePresence>
  );
}
